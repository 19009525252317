
export class SiteFieldsToUpdate {
    fieldAndValues: FieldAndValue[];
}

export class FieldAndValue {
    kendoKey: string;
    fieldId: any;
    currentValue: any;
    value: any;
    dataId?: any;
    gridItemId?: any;
    richTextValue?: string;
    richTextCurrentValue?: string;
}
