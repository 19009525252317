/* istanbul ignore file */

import { Field } from './field';

export class DynamicLogicServiceModel {
    activeStyles: any[] = new Array<any>();
    fieldsWithLogicSets = new Map<string, string>();
    siteData = {};
    fieldList = new Array<Field>();
    setFieldValueLogicSets = new Array<any>();
}
