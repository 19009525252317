import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { environment } from '../../../environments/environment';

@Injectable()
export class AppInsightsService {
    appInsights: ApplicationInsights;

    constructor(
        private _router: Router
    ) {
        var angularPlugin = new AngularPlugin();
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.appInsights.instrumentationKey,
                enableAutoRouteTracking: true,
                autoTrackPageVisitTime: true,
                enableRequestHeaderTracking: true,
                extensions: [angularPlugin],
                extensionConfig: {
                    [angularPlugin.identifier]: { router: this._router }
                }
            }
        });

        this.appInsights.loadAppInsights();
        this.appInsights.trackPageView();
    }

    logPageView(name?: string, url?: string): void {
        this.appInsights.trackPageView({
            name: name,
            uri: url
        });
    }

    logEvent(name: string, properties?: { [key: string]: any; }): void {
        this.appInsights.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any; }): void {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }

    logException(exception: Error, severityLevel?: number): void {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }

}
