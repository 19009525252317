import { Injectable } from '@angular/core';
import { FormElement } from '../logic-services/models';

@Injectable({
    providedIn: 'root'
})
export class ZebraStripeUtilityService {

    private _zebraStripeIds: Set<string>;
    private _contentSectionIds: Set<string>;

    constructor() { }

    init(): void {
        this._zebraStripeIds = new Set<string>();
        this._contentSectionIds = new Set<string>();
    }

    zebraStripes(elements: FormElement[], depth: number): void {
        if (depth <= 0)
            return;

        elements?.forEach((element: FormElement, index: number) => {
            if (element.id !== undefined && index % 2 === 0)
                this._zebraStripeIds.add(element.id);

            this.zebraStripes(element.elements, depth - 1);
        });
    }

    hasZebraStripe(element: FormElement): boolean {
        return this._zebraStripeIds?.has(element.id) ?? false;
    }

    contentSections(elements: FormElement[], depth: number): void {
        if (depth <= 0)
            return;

        elements?.forEach((element: FormElement) => {
            if (element.id !== undefined)
                this._contentSectionIds.add(element.id);

            this.contentSections(element.elements, depth - 1);
        });
    }

    isContentSection(element: FormElement): boolean {
        return this._contentSectionIds?.has(element.id) ?? false;
    }

}
