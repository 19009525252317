/* istanbul ignore file */
export enum ComparisonOperators {
    HasValue = "Has A Value",
    HasNoValue = "Has No Value",
    Equals = "Equals",
    NotEquals = "Not equals",
    LessThan = "Less than",
    LessThanEqualTo = "Less than/Equal to",
    GreaterThan = "Greater than",
    GreaterThanEqualTo = "Greater than/Equal to",
}
