/* istanbul ignore file */

import { FormGroup } from '@angular/forms';
import { MapFilterMenuOptionsModel } from '../../../../models';

export class MapFilterMenuServiceModel {
    mapFilterMenuOptions: MapFilterMenuOptionsModel;
    expandFilterMenu = false;
    characterCap = 30;
    cutOffString = "...";
    form: FormGroup;
    seeMoreStatus: { [key: string]: boolean; } = {};
}
