import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LogoutTimerService {
    private _counter = 0;
    private _stopTimer = false;

    resetTimerBehavior: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    displayTime: string;
    timeout: NodeJS.Timeout;
    logoutCount = TimerValues.TWENTYFIVEMINUTES;
    warningMessageCount = TimerValues.FIVEMINUTES;

    constructor() { }

    timer(): void {
        this.timeout = setInterval(() => {
            if (this._stopTimer) {
                this.stopTimer();
                return;
            }

            this._counter = this._counter - 1;
            this.formatMinutesSeconds(this._counter);

            if (this._counter <= 0)
                sessionStorage.timeExpired = true;
        }, 1000);
    }

    startTimer(): void {
        this._counter = this.logoutCount;

        clearInterval(this.timeout);

        this.timeout = setInterval(() => {
            if (this._stopTimer) {
                this.stopTimer();
                return;
            }

            this._counter = this._counter - 1;
            this.formatMinutesSeconds(this._counter);

            if (this._counter <= 0) {
                sessionStorage.timeExpired = true;
            }
        }, 1000);
    }

    formatMinutesSeconds(seconds: number): void {
        this.displayTime = `${(seconds - (seconds %= 60)) / 60}${(9 < seconds ? ':' : ':0')}${seconds}`;

        if (this.displayTime.includes("-")) {
            sessionStorage.timeExpired = true;
            this.stopTimer();
        }

        if (sessionStorage.timeExpired == true || sessionStorage.timeExpired === 'true' || sessionStorage.timeExpired === undefined) {
            this.stopTimer();
        }
    }

    stopTimer(): void {
        clearInterval(this.timeout);
        this._counter = this.logoutCount;
        this._stopTimer = true;
    }

    resetTimer(): void {
        sessionStorage.timeExpired = false;
        this._counter = this.logoutCount;
        this._stopTimer = false;
    }

}

enum TimerValues {
    FIVEMINUTES = 300,
    TWENTYFIVEMINUTES = 1500
}
