/* istanbul ignore file */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FormGroup } from '@angular/forms';
import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';
import { App, Project } from '../../models';
import { MapFilterMenuOptionsModel } from '../../models/map-filter-menu-options-model';
import { MapFilterModel } from '../../models/map-filter-model';
import { Field } from '../logic-services/models';

@Injectable({
    providedIn: 'root'
})

export class MapFiltersDataService extends HttpBaseService {
    private route = environment.api + 'map-filters/';

    constructor(
        private _httpClient: HttpClient
    ) {
        super(_httpClient);
    }

    saveMapFilters(app: App, project: Project, mapFilter: FormGroup): Observable<MapFilterModel> {
        const url = `${this.route}${app.id}/proj/${project.id}/save-filters`;
        return this.httpPost(url, mapFilter);
    }

    getMapFilterByProjectId(app: App, project: Project): Observable<MapFilterModel> {
        const url = `${this.route}${app.id}/proj/${project.id}/get-filter`;
        return this.httpGet(url);
    }

    getMapFilterValuesByProjectId(app: App, project: Project, allFields: Field[]): Observable<MapFilterMenuOptionsModel> {
        const url = `${this.route}${app.id}/proj/${project.id}/get-filter-values`;
        const dataToSend = {
            fields: allFields
        };
        return this.httpPost(url, dataToSend);
    }

    getSitesByFilterValues(app: App, project: Project, mapFilterMenuOptions: MapFilterMenuOptionsModel): Observable<string[]> {
        const url = `${this.route}${app.id}/proj/${project.id}/get-filtered-sites`;
        return this.httpPost(url, mapFilterMenuOptions);
    }

    getMapFilterMenuByProjectId(app: App, project: Project): Observable<any> {
        const url = `${this.route}${app.id}/proj/${project.id}/get-filter-menu`;
        return this.httpGet(url);
    }
}
