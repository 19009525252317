import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import jwt_decode from 'jwt-decode';
import { Subscription } from 'rxjs';

import { App } from 'src/app/models/app';
import { User } from 'src/app/models/user';
import { ErrorHandlerService } from 'src/app/services/tools/error-handler.service';
import { environment } from 'src/environments/environment';
import { AppsService, AuthService, LogoutTimerService, SitesService, TokenService, UsersService } from '../../../services';
import { HelpFeedbackServiceModel } from '../../../services/help-feedback-service-model';
import { HelpFeedbackLogicService } from '../../../services/logic-services/help-feedback-logic.service';

@Component({
    selector: 'kh-primary-menu',
    templateUrl: './primary-menu.component.html',
    styleUrls: ['./primary-menu.component.scss'],
})
export class PrimaryMenuComponent implements OnInit, OnDestroy {

    private _usersSubscription: Subscription = new Subscription();
    private _appsSubscription: Subscription = new Subscription();
    private _tokenSubscription: Subscription = new Subscription();

    smartsLogo: string = environment.logos.primaryLogo;
    user: User = new User();
    apps: App[] = [];
    app: App;
    accessText: string;
    help = 'help';
    releaseNotes = 'release-notes';

    get model(): HelpFeedbackServiceModel {
        return this._helpFeedbackService.model;
    }

    constructor(
        private _usersService: UsersService,
        public appsService: AppsService,
        public dialog: MatDialog,
        public _helpFeedbackService: HelpFeedbackLogicService,
        private _errorService: ErrorHandlerService,
        private _router: Router,
        private _tokenService: TokenService,
        private _sitesService: SitesService,
        private _authService: AuthService,
        private _logoutTimerService: LogoutTimerService
    ) { }

    ngOnInit(): void {
        this._usersService.reloadUser();
        this._usersSubscription = this._usersService.user.subscribe(user => {
            this.user = user;
        });

        this._appsSubscription = this.appsService.app.subscribe(app => {
            if (app) {
                this.app = app;
            }
        });

        this._tokenSubscription = this._tokenService.token.subscribe(token => {
            if (token) {
                const t = jwt_decode<any>(token);
                if (t.isPlatformAdmin) {
                    this.accessText = 'Platform Admin';
                }
                else if (t.isAppAdmin && !t.isPlatformAdmin) {
                    this.accessText = 'App Admin';
                }
                else if (t.isProjectAdmin && !t.isAppAdmin && !t.isPlatformAdmin) {
                    this.accessText = 'Project Admin';
                }
                else if (t.isPlainUser) {
                    this.accessText = 'Plain User';
                }
            }
        });

        this._helpFeedbackService.initModel();
    }

    ngOnDestroy(): void {
        this._usersSubscription.unsubscribe();
    }

    openRoute(route: string): void {
        if (route === this.help) {
            window.open(environment.blobStorage + 'public/SmartsHelpFile.pdf', '_blank');
            return;
        }

        if (route === this.releaseNotes) {
            window.open(environment.blobStorage + 'public/SmartsReleaseNotes.pdf', '_blank');
            return;
        }

        this._router.navigate([route]);
    }

    openFeedbackForm(): void {
        this.model.showForm = true;
    }

    logout(): void {
        this.stopSessionTimer();
        this._authService.logout();
    }

    stopSessionTimer(): void {
        this._logoutTimerService.resetTimer();
        this._logoutTimerService.stopTimer();
    }
}
