/* istanbul ignore file */
export class DialogModel {
    constructor(
        public title: string,
        public message: string,
        public appId: string,
        public projId: string,
        public projectName: string,
        public dataRow: any = null,
        public customValue: any = null,
        public id?: string,
        public confirmationActionTitle?: string
    ) { }
}

export class UserDialogSubModel {
    userId: string;
    status: boolean;
}
