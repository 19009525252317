/* istanbul ignore file */

export enum ObjectProperties {
    Columns = 'Columns',
    ColumnType = 'column',
    Control = 'Control',
    EnableDuplication = 'enableDuplication',
    Fields = 'Fields',
    FieldType = 'FieldType',
    Grid = 'grid',
    Group = 'Group',
    GroupTitle = 'GroupTitle',
    GroupType = 'group',
    HorizontalLayout = 'HorizontalLayout',
    Id = 'id',
    Label = 'label',
    Name = 'Name',
    Object = 'object',
    Photos = 'photos',
    ScopeProperties = '#/properties/',
    Section = 'Section',
    Sections = 'Sections',
    SectionTitle = 'SectionTitle',
    Upload = 'upload',
    VerticalLayout = 'VerticalLayout',
}
