import { Injectable } from '@angular/core';

import { UUID } from 'angular2-uuid';
import { Subject } from 'rxjs';
import { Md5 } from 'ts-md5';

import { App, Project, RTSForm, RTSFormServiceModel, Site } from '../../models';
import { RtsFormDataService } from '../data-services';
import { FormElement, Section, SmartsForm } from './models';

@Injectable({
    providedIn: 'root'
})
export class RtsFormLogicService {

    private updateForm = new Subject<boolean>();
    updateForm$ = this.updateForm.asObservable();

    model: RTSFormServiceModel = new RTSFormServiceModel();

    constructor(
        private _rtsFormData: RtsFormDataService,
    ) { }

    findRTSForm(project: Project, site: Site): RTSForm {
        let rtsForm = this.model.rtsForms.get(project.id + site.id);

        if (rtsForm === undefined) {
            rtsForm = new RTSForm();
            rtsForm.id = UUID.UUID();
            rtsForm.projectId = project.id;
            rtsForm.siteId = site.id;
            rtsForm.RTSForms = new SmartsForm();

            this.model.rtsForms.set(project.id + site.id, rtsForm);
        }

        return rtsForm;
    }

    deleteRtsFormSection(uischema: FormElement): void {
        this.model.rtsForms.forEach((rtsForm: RTSForm) => {
            rtsForm.RTSForms.Sections.forEach((section: Section) => {
                section.Sections = section.Sections.filter((target: Section) => target.id !== uischema.id);
            });
        });
    }

    getRtsFormSubtitle(uischema: FormElement): string {
        if (this.model.sectionSubtitles.has(uischema.id))
            return this.model.sectionSubtitles.get(uischema.id);

        this.model.rtsForms.forEach((rtsForm: RTSForm) => {
            rtsForm.RTSForms.Sections.forEach((containerSection: Section) => {
                containerSection.Sections.forEach((contentSection: Section) => {
                    this.model.sectionSubtitles.set(contentSection.id, contentSection.sectionSubtitle);
                });
            });
        });

        return this.model.sectionSubtitles.get(uischema.id);
    }

    updateRtsFormSubtitle(uischema: FormElement, newSubtitle: string): void {
        this.model.rtsForms.forEach((rtsForm: RTSForm) => {
            rtsForm.RTSForms.Sections.forEach((containerSection: Section) => {
                const target = containerSection.Sections.find((contentSection: Section) => contentSection.id === uischema.id);
                if (target !== undefined) {
                    target.sectionSubtitle = newSubtitle;
                    this.model.sectionSubtitles.set(uischema.id, target.sectionSubtitle);
                }
            });
        });
    }

    rtsFormHasChanges(project: Project, site: Site): boolean {
        const rtsForm = this.model.rtsForms.get(project.id + site.id);

        const originalHash = this.model.rtsFormsHash.get(project.id + site.id);

        const newHash = Md5.hashStr(JSON.stringify(rtsForm));

        if (originalHash !== newHash) {
            this.model.rtsFormsHash.set(project.id + site.id, newHash);
            return true;
        }

        return false;
    }

    saveChanges(app: App, project: Project, site: Site): void {
        const rtsForm = this.model.rtsForms.get(project.id + site.id);

        if (rtsForm) {
            rtsForm.RTSForms.Sections = rtsForm.RTSForms.Sections.filter((rtsSection: Section) => rtsSection.enableDuplication);

            this._rtsFormData.saveChanges(app, rtsForm)
                .subscribe({
                    next: (result: RTSForm) => {
                        this.model.rtsFormsHash.set(project.id + site.id, Md5.hashStr(JSON.stringify(rtsForm)));

                        this.model.rtsForms.forEach((target: RTSForm) => {
                            if (target.id === result.id) {
                                target = result;
                            }
                        });
                    }
                });
        }
    }

    getRtsForms(app: App, project: Project, site: Site): void {
        if (!this.model.fetchingRtsForms) {
            this.model.fetchingRtsForms = true;
            this._rtsFormData.getRtsForm(app, project, site)
                .subscribe({
                    next: (result: RTSForm) => {
                        if (result) {
                            this.model.rtsForms.set(project.id + site.id, result);
                            this.model.rtsFormsHash.set(project.id + site.id, Md5.hashStr(JSON.stringify(result)));
                        }
                    }
                })
                .add(() => {
                    this.model.fetchingRtsForms = false;
                });
        }
    }

    updateRtsForm(): void {
        this.updateForm.next(true);
    }
}
