import { Component } from '@angular/core';

@Component({
    selector: 'kh-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {

    constructor() { }

}
