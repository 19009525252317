/* istanbul ignore file */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { takeWhile } from 'rxjs/operators';

import { ExcelBulkSiteUploadLogicService } from '../../../services/logic-services';

@Component({
    selector: 'loader-dialog',
    templateUrl: './loader-dialog.component.html',
    styleUrls: ['./loader-dialog.component.scss']
})
export class LoaderDialogComponent implements OnInit {

    alive = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: string,
        private _excelBulkSiteUploadLogicService: ExcelBulkSiteUploadLogicService,
        private dilogRef: MatDialogRef<LoaderDialogComponent>,
    ) { }

    ngOnInit(): void {
        this._excelBulkSiteUploadLogicService.startTimer$
            .pipe(takeWhile(() => this.alive))
            .subscribe((result: boolean) => {
                if (!result)
                    this.dilogRef.close();
            });
    }
}
