/* istanbul ignore file */

export enum RuleProperties {
    Id = 'id',
    Rule = 'rule',
    Condition = 'condition',
    Schema = 'schema',
    Not = 'not',
    Show = 'SHOW',
    Enable = 'ENABLE',
    Rules = 'rules',

    Photos = 'photos',
    ScopeProperties = '#/properties/',
    Sections = 'Sections',
    Section = 'Section',
    SectionTitle = 'SectionTitle',
    Upload = 'upload',
    VerticalLayout = 'VerticalLayout',
}
