import { App } from "./app";
import { Project } from "./project";
import { Role } from "./role";

/* istanbul ignore file */
export class Notification {
    active = false;
    apps?: App[];
    createdBy?: string;
    dateCreated?: string;
    dateRequested?: string = null;
    emailBody: string;
    emailHeader: string;
    emailReceiver: string;
    emailReceiverId: string;
    fieldId: string;
    fieldName: string;
    fieldToMonitor: string;
    id: string;
    isLoading?: boolean = false;
    notificationName: string;
    otherEmail: string;
    projectId: string;
    projects?: Project[];
    role?: Role;
    roles?: string[];
    token?: string;
    type: string;
}
