import { Injectable } from '@angular/core';

import { App, Project, Site } from '../../models';
import { DifferencesObject } from './models';

@Injectable({
    providedIn: 'root'
})
export class DifferenceLogicService {

    differenceObject: DifferencesObject;

    constructor() { }

    init(app: App, project: Project, site: Site): void {
        this.differenceObject = new DifferencesObject();
        this.differenceObject.appId = app.id;
        this.differenceObject.projectId = project.id;
        this.differenceObject.siteId = site.id;
    }

    findObjectDifferences(newObject: object, oldObject: object): void {
        for (const key in newObject) {
            if (this.valuesAreTheSame(newObject[key], oldObject[key]))
                continue;

            if (this.differenceObject.fieldId) {
                this.differenceObject.objectFieldId = key;
                this.differenceObject.value = newObject[key];
                return;
            }

            if (this.differenceObject.fieldId === undefined)
                this.differenceObject.fieldId = key;

            this.differenceObject.value = newObject[key];

            if (Array.isArray(newObject[key]))
                this.findArrayDifferences(newObject[key], oldObject[key]);
        }
    }

    findArrayDifferences(newArray: any[], oldArray: any[]): void {
        if (newArray?.length !== oldArray?.length)
            return;

        newArray.forEach((value, index) => {
            if (this.valuesAreTheSame(value, oldArray[index]))
                return;

            if (this.differenceObject.fieldId)
                this.differenceObject.objectId = value['id'];

            this.findObjectDifferences(value, oldArray[index]);
        });
    }

    valuesAreTheSame(value1: any, value2: any): boolean {
        return (JSON.stringify(value1) === JSON.stringify(value2));
    }

}
