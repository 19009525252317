import { Injectable } from '@angular/core';

import { FileGalleryInitData, ZoomData } from '../../models/file-gallery-model';

@Injectable({
    providedIn: 'root'
})
export class FileGalleryService {

    private fullscreenFileGalleryInitData: FileGalleryInitData = null;

    constructor() { }

    incrementZoomData(zoomData: ZoomData, zoomIncreased: boolean): void {
        const zoomDir = zoomIncreased ? 1 : -1;
        zoomData.curZoom += zoomData.increment * zoomDir;
        if (zoomData.curZoom < zoomData.minZoom)
            zoomData.curZoom = zoomData.minZoom;
        if (zoomData.curZoom > zoomData.maxZoom)
            zoomData.curZoom = zoomData.maxZoom;
    }

    setFullscreenData(newData: FileGalleryInitData): void {
        const shouldFullscreenDialogBeOpen = newData !== null;
        if (shouldFullscreenDialogBeOpen)
            this.changeScrollingBlocked(true);
        else
            this.changeScrollingBlocked(false);
        this.fullscreenFileGalleryInitData = newData;
    }

    getFullscreenData(): FileGalleryInitData {
        return this.fullscreenFileGalleryInitData;
    }

    convertPxToRem(pixels: number): number {
        return pixels / 16;
    }

    changeScrollingBlocked(isScrollingNowBlocked: boolean): void {
        if (isScrollingNowBlocked)
            document.body.classList.add("block-scrolling");
        else
            document.body.classList.remove("block-scrolling");
    }

    getRemWidthOfInputForCharCount(charCount: number): string {
        const basePixels = 11;
        const additionalPixelsPerChar = 10;
        const pixels = basePixels + (additionalPixelsPerChar * charCount);
        const rem = this.convertPxToRem(pixels);
        return `${rem}rem`;
    }
}
