import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivationEnd, Router } from '@angular/router';

import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { takeWhile } from 'rxjs/operators';

import { AuthService, LogoutTimerService, RefreshTokenService } from '../../../services';
import { TimeoutDialogComponent } from '../../dialogs/timeout-dialog/timeout-dialog.component';

@Component({
    selector: 'kh-timeout-tool',
    template: '<countdown style="display: none" #cdTimer [config]="config" (event)="countDownEvent($event)"></countdown>',
    styles: []
})
export class TimeoutToolComponent implements OnDestroy, AfterViewInit {

    @ViewChild('cdTimer', { static: false }) private _countdown: CountdownComponent;

    private _alive = true;

    config: CountdownConfig = {
        demand: false,
        leftTime: this._timeoutservice.logoutCount,
        format: 'mm:ss',
        notify: [this._timeoutservice.warningMessageCount]
    };

    constructor(
        private _router: Router,
        private _timeoutservice: LogoutTimerService,
        private _refreshTokenService: RefreshTokenService,
        private _authService: AuthService,
        public dialog: MatDialog
    ) { }

    ngOnDestroy(): void {
        this._alive = false;
    }

    ngAfterViewInit(): void {
        this._countdown.begin();

        this._router.events
            .pipe(takeWhile(() => this._alive))
            .subscribe((value) => {
                if (value instanceof ActivationEnd) {
                    const route = this._router.routerState.snapshot.url.split('/')[1];

                    if (['home', 'login'].includes(route)) {
                        this._countdown.restart();
                        this._countdown.pause();
                    }
                    else {
                        this._countdown.resume();
                    }
                }
            });

        this._timeoutservice.resetTimerBehavior
            .pipe(takeWhile(() => this._alive))
            .subscribe((result: boolean) => {
                if (result) {
                    this._countdown.restart();
                    this._countdown.resume();
                }
            });
    }

    countDownEvent(event: CountdownEvent): void {
        if (event.action === 'notify') {
            const dialogRef = this.dialog.open(TimeoutDialogComponent,
                {
                    maxWidth: "430px",
                    data: { time: this._countdown.i.value / 1000 },
                    disableClose: true
                }
            );

            dialogRef.afterClosed()
                .pipe(takeWhile(() => this._alive))
                .subscribe((result: boolean) => {
                    if (result) {
                        this._refreshTokenService.getRefreshToken();
                        this._countdown.restart();
                        this._countdown.resume();
                    }
                    else {
                        this._authService.logout();
                    }
                });
        }
        else if (event.action === 'done') {
            this._authService.logout();
        }
    }

}
