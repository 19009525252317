/* istanbul ignore file */

export enum SelectOptions {
    String = 'string',
    Bool = 'boolean',
    Photos = 'photos',
    Options = 'options',
    Upload = 'upload',
    Type = 'type',
    Enum = 'enum',
    Grid = 'grid',
    Number = 'number',
    URLHyperlink = 'urlHyperlink',
    EmailHyperlink = 'emailHyperlink'
}
