/* istanbul ignore file */
export class Smartsheet {
    public accessToken: string;
    public clearPermission?: boolean = false;
    public dateModified: Date;
    public executedBy?: string;
    public extendedPrimaryKey?: any;
    public filter: string;
    public filterColumns?: any[] = [];
    public id: string;
    public insertPermission?: boolean = false;
    public isExtended?: boolean = false;
    public isSaving?: boolean = false;
    public modifiedBy?: string;
    public name: string;
    public projectId: string;
    public pullColumns?: any[];
    public pullStatus: string;
    public pullStatusMessage: string;
    public pullStatusTimestamp: Date;
    public pushColumns?: any[];
    public pushStatus: string;
    public pushStatusMessage: string;
    public pushStatusTimestamp: Date;
    public smartsheet?: any;
    public smartsheetPrimaryKey?: any;
    public token?: string;
}
