/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileInfo } from '@progress/kendo-angular-upload';
import { AppAliasService } from '..';
import { GeoJsonFeature, Site, WizardStep } from '../../../models';
import { BasicDropdownValue } from '../../../models/basic-dropdown-value';
import { SiteTypeNames, SiteTypes } from '../../../shared/enums';

/* istanbul ignore file */
export class SiteFormLogicServiceModel {
    siteForm: FormGroup = new FormGroup({
        siteType: new FormControl(SiteTypes.Point, [Validators.required]),
        address: new FormControl(null),
        city: new FormControl(null),
        state: new FormControl(null),
        zipCode: new FormControl(null),
        latitude: new FormControl(null),
        longitude: new FormControl(null),
        siteName: new FormControl(null),
        siteNo: new FormControl(null),
        chargeNumber: new FormControl(null),
        active: new FormControl(false)
    });
    geoJsonFiles: FileInfo[] = [];
    geoJson: GeoJsonFeature | undefined = undefined;
    geoJsonFileName: string | undefined = undefined;
    geoJsonErrorText: string | undefined = '';
    changedInputControl: string = '';
    initialSiteNumber: string = '';
    isNewSite: boolean = true;
    updatedSite: Site = new Site();
    searchType: SearchType = SearchType.ADDRESS;
    wizardSteps: WizardStep[] = [
        new WizardStep(`Location`, 'location_on'),
        new WizardStep(`Details`, 'description'),
        new WizardStep('Review & Submit', 'done')
    ];
    currentWizardStep: number = 0;
    locationSummary = new Map<string, string>();
    detailsSummary = new Map<string, string>();
    wizardStepErrorMap = new Map<number, boolean>([[0, true], [1, true]]);
    keyWordMap: Map<string, string> = new Map<string, string>([
        ['siteType', `${this.siteAlias} Type`],
        ['zipCode', `Zip Code`],
        ['siteName', `${this.siteAlias} Name`],
        ['siteNo', `${this.siteAlias} Number`],
        ['active', `Activate ${this.siteAlias} on Creation`],
        ['chargeNumber', 'Charge Number'],
    ]);
    valueWordMap: Map<any, string> = new Map<any, string>([
        [true, `Yes`],
        [false, `No`]
    ]);
    firstWizardStepHeight: number;
    siteNumberExists: boolean = false;
    isActivateRadioDisabled: boolean = true;
    siteLocationDefinitionTypes: BasicDropdownValue[] = [
        { name: SiteTypeNames.Point, id: SiteTypes.Point },
        { name: SiteTypeNames.LineArea, id: SiteTypes.LineArea }
    ];

    isAppEnterprise: boolean = false;

    get siteAlias(): string { return this._appAliasService.siteAlias; }

    constructor(private _appAliasService: AppAliasService | undefined) { }
}

export enum SiteFormInputControl {
    ADDRESS = 'address',
    CITY = 'city',
    STATE = 'state',
    SITE_TYPE = 'siteType',
    SEARCH_TYPE = 'searchType',
    ZIP_CODE = 'zipCode',
}

export enum SearchType {
    ADDRESS = 'address',
    LAT_LONG = 'lat/long',
}

export interface SiteFormValues {
    siteName: string | null;
    siteNo: string | null;
    address: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
    active: boolean | null;
    latitude: number | null;
    longitude: number | null;
    chargeNumber: string | null;
}
