/* eslint-disable @typescript-eslint/no-inferrable-types */
import { MapIcon, MapPin } from "src/app/models/map-pin-model";

/* istanbul ignore file */
export class MapSettingsPreviewModel {
    mapLoading = true;
    map: google.maps.Map;
    mapProperties: google.maps.MapOptions = {
        center: { lat: 40.550053, lng: -98.5795 },
        zoom: 4,
        mapTypeId: 'roadmap',
        mapTypeControl: false,
        zoomControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        draggable: false,
    };
    mapPins: MapPin[] = [];
    marker: google.maps.Marker;
    mapIconDataUrl: string;
    mapIcon: MapIcon;
    currentCoordinates: google.maps.LatLng;
    scaledSize: google.maps.Size;
    svg: any = "assets/icons/Pin.svg";
    svgDocument: Document = null;
}
