import { Injectable } from '@angular/core';

import { intersection } from 'lodash';

import { NotificationDataService } from '../data-services';
import { DifferencesObject, NotificationObject, RequestInfo } from './models';

@Injectable({
    providedIn: 'root'
})
export class NotificationLogicService {

    activeNotificationFieldIds: string[];
    notificationObjects = new Array<NotificationObject>();

    constructor(
        private _notificationDataService: NotificationDataService
    ) { }

    getListOfActiveNotificationFieldIds(requestInfo: RequestInfo): void {
        this._notificationDataService.getListOfActiveNotificationFieldIds(requestInfo)
            .subscribe({
                next: (result: string[]) => {
                    this.activeNotificationFieldIds = result;
                }
            });
    }

    createNotification(diffs: any): NotificationObject {
        let notificationObject = new NotificationObject();

        for (const key in diffs) {
            if (this.activeNotificationFieldIds.includes(key)) {
                notificationObject.field = key;
                notificationObject.sendToQueue = this.activeNotificationFieldIds.includes(key);
                notificationObject.value = diffs[key];

                if (Array.isArray(notificationObject.value)) {
                    notificationObject.value = `The value for this field has changed.`;
                }

                return notificationObject;
            }

            if (Array.isArray(diffs[key])) {
                (diffs[key] as any[]).forEach((diff) => {
                    notificationObject = this.createNotification(diff);
                });
            }
        }

        return notificationObject;
    }

    newAzureQueue(differencesObject: DifferencesObject): void {
        const activeNotificationMatch: string[] = intersection(this.activeNotificationFieldIds, [differencesObject.fieldId, differencesObject.objectFieldId, differencesObject.objectId]);

        if (activeNotificationMatch.length)
            this._notificationDataService.newAzureQueue(differencesObject)
                .subscribe({
                    next: () => { },
                    error: () => { },
                    complete: () => { },
                })
                .add(() => { });
    }

}

