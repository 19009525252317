import { Color } from "@angular-material-components/color-picker";

import { AppPropertiesAliasMap, LicenseTier } from '.';
import { File } from "./file";
import { User } from "./user";

/* istanbul ignore file */
export class App {
    activeLicenseTier: LicenseTier;
    activeSitesCount: number;
    aliases? = new AppPropertiesAliasMap();
    chargeNumber = "";
    clientName: string;
    createdBy?: string;
    database: string;
    dateCreated: string;
    dateRequested: Date;
    fileContainers?: FileContainers;
    hasDefaultLayout: boolean;
    id: string;
    isActive = false;
    isApproved = false;
    licenseExpirationDate: Date;
    mapPin: File;
    mapPinColor?: Color;
    mapPinHeight: number;
    mapPinStyle?: number;
    mapPinWidth: number;
    name: string;
    pmName: string;
    primaryColor: Color;
    primaryContacts: PrimaryContact[];
    primaryLogo: File;
    primaryTextColor: PrimaryTextColor;
    processOnUpcomingInvoice = true;
    projects: any[];
    roadThreatProgram = false;
    secondaryColor: Color;
    secondaryLogo: File;
    secondaryTextColor: Color;
    showDataImport = true;
    showDocumentTemplate = true;
    showDocumentTemplateOnSitePage = true;
    showInvoices = true;
    showMapLogic = true;
    showNotifications = true;
    showProjectNotes = true;
    showReports = true;
    showSiteRequests = true;
    showSmartsheets = true;
    url: string;
    userId: string;
    users: User[];
}

export class PrimaryContact {
    email: string;
    id: string;
    name: string;
}

export class FileContainers {
    private: Private;
    public: Public;
}

export class Public {
    name: string;
    uri: string;
}

export class Private {
    name: string;
    uri: string;
}

export class PrimaryTextColor {
    r: number;
    g: number;
    b: number;
    a: number;
    hex: string;
    rgba: string;
    roundA: number;
}
