import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NgxEditorService {

    constructor() { }

    handlePasteEvent(event: ClipboardEvent, value: string): string {
        event.preventDefault();
        return this.stripOutUnsupportedStyling(value);
    }

    stripOutUnsupportedStyling(value: string): string {
        let valueWithoutStyles: string = value.replace(/style="[^"]*"/g, '');
        let valueWithoutStylesAndImages: string = valueWithoutStyles.replace(/<img[^>]*>/g, '');
        let valueWithoutStylesAndImagesAndLinks: string = valueWithoutStylesAndImages.replace(/<a[^>]*>/g, '');
        return valueWithoutStylesAndImagesAndLinks;
    }

}
