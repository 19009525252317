import { HttpErrorResponse } from '@angular/common/http';

import { Observable, Observer, throwError } from 'rxjs';


/* istanbul ignore file */
export class ErrorHandler {
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    public static handle(error: HttpErrorResponse): Observable<unknown> {
        // if (error.status === 401) {
        //     window.location.href = environment.url + '/unauthorized?ReturnUrl=' + window.location.href;
        //     return throwError(() => new Error('Unauthorized'));
        // }

        return new Observable((observer: Observer<unknown>) => {
            observer.error(error);
            observer.complete();
        });
    }
}

export class ErrorHandlerNoRedirect {
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    public static handle(error: HttpErrorResponse): Observable<boolean> {
        if (error.status === 401)
            return throwError(() => new Error('Unauthorized'));

        return new Observable((observer: Observer<boolean>) => {
            observer.error(true);
            observer.complete();
        });
    }
}
