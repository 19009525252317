/* istanbul ignore file */

import { FormGroup } from '@angular/forms';

import { MarkerClusterer } from '@google/markerclustererplus/dist/markerclusterer';

import { KHMapMarker } from '../../../../models';
import { MapFilterMenuOptionsModel } from '../../../../models/map-filter-menu-options-model';
import { MapPin } from '../../../../models/map-pin-model';
import { Field } from '../field';

export class SiteMapLogicServiceModel {
    dataLayersMap: Map<string, google.maps.Data> = new Map<string, google.maps.Data>();
    mapFilterVisible = true;
    mapFilterForm: FormGroup;
    mapFilterPreviousSelectedFields: Field[];
    mapFilterMenuOptions: MapFilterMenuOptionsModel = new MapFilterMenuOptionsModel();
    mapLoading = true;
    map: google.maps.Map;
    mapProperties: google.maps.MapOptions = {
        mapTypeControl: false,
        center: { lat: 39.550053, lng: -98.5795 },
        zoom: 4,
        mapTypeId: 'roadmap',
        fullscreenControl: true,
        fullscreenControlOptions: {
            position: google.maps.ControlPosition?.RIGHT_BOTTOM ?? 9
        },
        styles: [
            {
                featureType: "poi",
                elementType: "labels",
                stylers: [
                    { visibility: "off" }
                ]
            },
            {
                featureType: "road",
                elementType: "labels",
                stylers: [
                    { visibility: "on" }
                ]
            }
        ]
    };
    latitudeLongitudeBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    markerCluster: MarkerClusterer;
    markerClusterImagePath = "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m";
    allMapPins: MapPin[] = [];
    filteredMapPins: MapPin[] = [];
    markers: KHMapMarker[] = [];
    infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();
    marker: KHMapMarker;
    mapPin: MapPin;
    expandFilterMenu = false;
    mapLayersForm: FormGroup;
    currentInfoWindowContent: HTMLElement;
}
