<div
    class="scroll-to-top"
    [class.show-scrollTop]="windowScrolled"
    *ngIf="windowScrolled"
>
    <button
        mat-raised-button
        style="height: 50px"
        color="primary"
        type="button"
        class="btn active scroll-to-top-button"
        data-toggle="button"
        aria-pressed="true"
        (click)="scrollToTop()"
    >
        <mat-icon>arrow_upward</mat-icon>
    </button>
</div>
