import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { App, Site } from '../../models';
import { SitesDataService } from '../data-services';
import { GridColumnProperties } from './models';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SiteColumnSettings } from '../../models/site-column-settings';
import { SitesService } from '../sites.service';
import { GridRendererFormattingData } from './models/siteGrid/grid-renderer-formatting-data';
import { GridRendererServiceModel } from './models/siteGrid/grid-renderer-logic-service-model';

@Injectable({
    providedIn: 'root',
})

export class GridRendererLogicService {
    private localSessionStorageKey = 'siteGridsFormattingMap';

    model: GridRendererServiceModel = new GridRendererServiceModel();

    get currentSite(): Site { return this._sitesService.model.currentSite; }

    constructor(
        private _sitesService: SitesService,
        private _sitesDataService: SitesDataService,
        private _snackBar: MatSnackBar
    ) { }

    setSiteGridsFormattingMap(gridId: string): void {
        let currentGridFormattingData = this.currentSite.columnSettings?.find((x) => x.hasOwnProperty(gridId));
        if (!currentGridFormattingData) {
            this.loadModelFromSesion(this.currentSite.id);

            if (!this.model.siteGridsFormattingMap.has(this.currentSite.id)) {
                this.model.siteGridsFormattingMap.set(this.currentSite.id, new Map<string, GridRendererFormattingData>());
            }

            if (!this.model.siteGridsFormattingMap.get(this.currentSite.id).has(gridId))
                this.model.siteGridsFormattingMap.get(this.currentSite.id).set(gridId, new GridRendererFormattingData());

            return;
        }

        const gridRendererFormattingData = new GridRendererFormattingData();
        gridRendererFormattingData.columnSettings = currentGridFormattingData[gridId];

        if (this.model.siteGridsFormattingMap.has(this.currentSite.id)) {
            this.model.siteGridsFormattingMap.get(this.currentSite.id).set(gridId, gridRendererFormattingData);
        }
        else {
            this.model.siteGridsFormattingMap.set(this.currentSite.id, new Map<string, GridRendererFormattingData>());
            this.model.siteGridsFormattingMap.get(this.currentSite.id).set(gridId, gridRendererFormattingData);
        }

        this.loadModelFromSesion(this.currentSite.id);
    }

    loadModelFromSesion(siteId: string): void {
        if (sessionStorage.getItem(this.localSessionStorageKey) == null)
            return;

        const mapInSession = (JSON.parse(sessionStorage.getItem(this.localSessionStorageKey)));
        const map = new Map<string, Map<string, GridRendererFormattingData>>();
        for (const key in mapInSession) {
            let innerMap = new Map();
            for (let innerKey in mapInSession[key]) {
                innerMap.set(innerKey, mapInSession[key][innerKey]);
            }
            map.set(key, innerMap);
        }

        if (!map.has(siteId) && !map.get(siteId))
            return;

        this.model.siteGridsFormattingMap.set(siteId, map.get(siteId));
    }

    updateReportFormattingDataInSession(currentSite: Site, siteGridId: string): void {
        const currentGridFormattingData = this.model.siteGridsFormattingMap.get(currentSite.id).get(siteGridId);
        if (currentGridFormattingData.columnSettings.length || currentGridFormattingData.filters.length || currentGridFormattingData.numLockedColumns > 0) {
            this.model.siteGridsFormattingMap.get(currentSite.id).set(siteGridId, currentGridFormattingData);
            sessionStorage.setItem(this.localSessionStorageKey, JSON.stringify(this.convertToObject()));
        }
    }

    convertToObject(): any {
        const result: Map<string, Map<string, GridRendererFormattingData>> = new Map<string, Map<string, GridRendererFormattingData>>();

        this.model.siteGridsFormattingMap.forEach((innerMap, siteId) => {
            const siteObject: Map<string, GridRendererFormattingData> = new Map<string, GridRendererFormattingData>();
            innerMap.forEach((value, secondId) => {
                siteObject[secondId] = value;
            });
            result[siteId] = siteObject;
        });

        return result;
    }

    updateGridColumnWidth(currentApp: App, currentSite: Site, gridId: string, columnChanged: GridColumnProperties): void {
        this._sitesDataService.updateSiteGridColumnWidth(currentApp, currentSite, gridId, columnChanged)
            .subscribe({
                next: (result: SiteColumnSettings[]) => {
                    this.currentSite.columnSettings = result;
                },
                error: () => {
                    this._snackBar.open('Error updating site column width', 'Close', { duration: 2000 });
                },
                complete: () => { },
            })
            .add(() => {
            });
    }

    isDraggedUpDropBelow(previousIndex: number, currentIndex: number, isDraggingToRowBelow: boolean): boolean {
        if (previousIndex > currentIndex && isDraggingToRowBelow)
            return true;
        return false;
    }

    isDraggedDownDropAbove(previousIndex: number, currentIndex: number, isDraggingToRowAbove: boolean): boolean {
        if (previousIndex < currentIndex && isDraggingToRowAbove)
            return true;
        return false;
    }

    reorderBasedOnFilter(dataInView: any[], gridData: any, event: CdkDragDrop<any[]>, isDraggingToRowAbove: boolean, isDraggingToRowBelow: boolean): void {
        let filteredRowMoved = dataInView[event.previousIndex];
        let actualRowMovedpreviousIndex = gridData.findIndex(x => x.id === filteredRowMoved.id);

        if (event.currentIndex === 0) {
            let filteredRowBeingMovedDown = dataInView[event.currentIndex];
            let actualIndexRowBelow = gridData.findIndex(x => x.id === filteredRowBeingMovedDown.id);

            this.reorderData(gridData, actualRowMovedpreviousIndex, (actualIndexRowBelow), isDraggingToRowAbove, isDraggingToRowBelow);
            return;
        }
        if (event.currentIndex === dataInView.length - 1) {
            let filteredRowAbove = dataInView[event.currentIndex];
            let actualIndexRowAbove = gridData.findIndex(x => x.id === filteredRowAbove.id);

            this.reorderData(gridData, actualRowMovedpreviousIndex, (actualIndexRowAbove), isDraggingToRowAbove, isDraggingToRowBelow);
            return;
        }

        let filteredRowAbove = dataInView[event.currentIndex - 1];
        let actualIndexRowAbove = gridData.findIndex(x => x.id === filteredRowAbove.id);
        this.reorderData(gridData, actualRowMovedpreviousIndex, (actualIndexRowAbove as number + 1), isDraggingToRowAbove, isDraggingToRowBelow);
    }

    reorderData(data: any, previousIndex: number, currentIndex: number, isDraggingToRowAbove: boolean, isDraggingToRowBelow: boolean): void {
        if (this.isDraggedUpDropBelow(previousIndex, currentIndex, isDraggingToRowBelow))
            moveItemInArray(data, previousIndex, currentIndex + 1);

        else if (this.isDraggedDownDropAbove(previousIndex, currentIndex, isDraggingToRowAbove))
            moveItemInArray(data, previousIndex, currentIndex - 1);

        else
            moveItemInArray(data, previousIndex, currentIndex);
    }
}
