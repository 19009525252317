import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { Site } from '../../models';
import { SiteDragDropSelectionModel } from './models/site-drag-drop-selection-model';

@Injectable({
    providedIn: 'root'
})

export class SiteDragDropSelectionLogicService {
    model: SiteDragDropSelectionModel;

    get availableItems(): Site[] {
        return this.model.availableItems;
    }

    set availableItems(incomingItems: Site[]) {
        this.model.availableItems = incomingItems;
    }

    get selectedItems(): Site[] {
        return this.model.selectedItems;
    }

    set selectedItems(incomingItems: Site[]) {
        this.model.selectedItems = incomingItems;
    }

    get allItems(): Site[] {
        return this.model.allItems;
    }

    set allItems(allIncomingItems: Site[]) {
        this.model.allItems = [...allIncomingItems];
        this.model.availableItems = [...allIncomingItems];
        this.selectedItems = _.intersectionBy(
            this.selectedItems,
            this.availableItems,
            'id'
        );
    }

    loadModel(incomingAvailableItems: Site[]): void {
        this.model = new SiteDragDropSelectionModel();
        this.allItems = [...incomingAvailableItems];
    }

    resetModel(): void {
        this.model = new SiteDragDropSelectionModel();
    }

    dropAvailable(event: CdkDragDrop<string[]>): void {
        this.handleMovingItemInArray(event);

        this.availableItems = this.returnGridFieldsToAvailableFields(this.selectedItems, this.allItems);
        this.availableItems = this.removeDuplicateFields(this.selectedItems, this.allItems);
        this.availableItems = this.removeGridFieldsFromAvailableFields(this.selectedItems, this.availableItems);
    }

    dropSelected(event: CdkDragDrop<string[]>): void {
        this.handleMovingItemInArray(event);

        this.availableItems = this.removeDuplicateFields(this.selectedItems, this.allItems);
        this.availableItems = this.removeGridFieldsFromAvailableFields(this.selectedItems, this.availableItems);
    }

    handleMovingItemInArray(event: CdkDragDrop<string[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );

            return;
        }

        transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex
        );

    }

    fieldFilterChange(filterString: string): void {
        this.resetAvailableFields();
        this.availableItems = this.availableItems.filter((field) => {
            return field.siteName.toLowerCase().includes(filterString.toLowerCase());
        });
    }

    resetAvailableFields(): void {
        if (this.selectedItems?.length) {
            if (this.allItems?.length) {
                const allFieldsByIsSelected = _.chain(this.allItems)
                    .groupBy((f) => _.some(this.selectedItems, (sf) => sf.siteName === f.siteName))
                    .value();
                this.availableItems = allFieldsByIsSelected[false] || [];
            }
            else {
                this.availableItems = [];
            }
        }
        else {
            this.availableItems = _.chain(this.allItems).orderBy('siteName').value();
        }
    }

    unselectAll(): void {
        this.availableItems = [...this.allItems];
        this.selectedItems = [];

    }

    selectAll(): void {
        this.selectedItems = this.selectedItems.concat([...this.availableItems]);
        this.availableItems = [];
    }

    removeDuplicateFields(selectedFields: Site[], availableFields: Site[]): Site[] {
        let fields: Site[] = _.differenceBy(availableFields, selectedFields, 'id');
        return fields;
    }

    returnGridFieldsToAvailableFields(selectedFields: Site[], allItems: Site[]): Site[] {
        if (selectedFields.length === 0)
            return allItems;

        const differenceFields: Site[] = _.difference(allItems, selectedFields, 'id');

        let selectedFieldsDataId: string;
        selectedFields.forEach((field: Site) => {
            if (field.id)
                selectedFieldsDataId = field.id;
        });

        if (selectedFieldsDataId === undefined)
            return differenceFields;

        const availableFields = new Array<Site>();
        differenceFields.forEach((field: Site) => {
            if (field.id === selectedFieldsDataId || field.id === undefined)
                availableFields.push(field);
        });

        return availableFields;
    }

    removeGridFieldsFromAvailableFields(selectedFields: Site[], availableFields: Site[]): Site[] {
        selectedFields.forEach((field: Site) => {
            if (field.id) {
                const id = field.id;
                const tempAvailableFields = new Array<Site>();
                availableFields.forEach((field: Site) => {
                    if (!field.id || field.id !== id)
                        tempAvailableFields.push(field);

                    availableFields = tempAvailableFields;
                });
            }
        });

        return availableFields;
    }

}

