import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'close-dialog-button',
    templateUrl: './close-dialog-button.component.html',
    styleUrls: ['./close-dialog-button.component.scss']
})
export class CloseDialogButtonComponent implements OnInit {
    @Output() click: EventEmitter<any> = new EventEmitter();

    constructor(
    ) { }

    ngOnInit(): void {

    }
}
