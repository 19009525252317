import { Project, Site } from '.';

/* istanbul ignore file */
export class SitesLogicServiceModel {
    currentAppId = '';
    currentSite = new Site();
    exportingLayout = false;
    isSaving = false;
    sitesLoading = false;
    siteLoading = false;
    sites = new Array<Site>();
    adminSites = new Array<Site>();
    adminProjectsForSitesLogic = new Array<Project>();
}
