/* istanbul ignore file */
export class DifferencesObject {
    appId: string;
    projectId: string;
    siteId: string;
    objectFieldId: string;
    fieldId: string;
    objectId: string;
    value: any;
}
