import { KHFileInfo } from './';

/* istanbul ignore file */
export class FileGalleryData {
    fileIds: string[];
    curIndex: number;
    pageDataByFileId: PageDataByFileId;
    constructor(initData: FileGalleryInitData) {
        this.fileIds = [];
        this.curIndex = 1;
        this.pageDataByFileId = new PageDataByFileId();
        initData.galleryFiles.forEach(file => {
            this.fileIds.push(file.uri);
            this.pageDataByFileId[file.uri] = new PageData();
            if (file.uri === initData.activeFile.uri)
                this.curIndex = this.fileIds.length - 1;
        });
    }
}

export class PageDataByFileId {
    [fileId: string]: PageData;
}

export class PageData {
    curPage: 1;
    totalPages: 1;
}

export class FileGalleryInitData {
    activeFile: KHFileInfo;
    galleryFiles: KHFileInfo[];
    constructor(data: FileGalleryInitData) {
        this.activeFile = data.activeFile;
        this.galleryFiles = data.galleryFiles ? data.galleryFiles : [this.activeFile];
    }
}

export class ZoomData {
    defaultZoom = .5;
    curZoom?: number;
    minZoom = .1;
    maxZoom = 1;
    increment = .1;
    constructor(data: ZoomData) {
        this.minZoom = data.minZoom;
        this.maxZoom = data.maxZoom;
        this.defaultZoom = data.defaultZoom;
        this.increment = data.increment;
        this.curZoom = this.defaultZoom;
    }
}

export enum ExtensionGroups {
    IMG = 'img',
    PDF = 'pdf',
    NOTVIEWABLE = 'notViewable'
}
