/* istanbul ignore file */

export enum KeyCodes {
    SemiColon = 186,
    EqualSign = 187,
    Comma = 188,
    Period = 190,
    ForwardSlash = 191,
    GraveAccent = 192,
    OpenBracket = 219,
    BackSlash = 220,
    SingleQuote = 222,
}
