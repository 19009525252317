import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TokenInterceptorService } from './../core/interceptors/token-interceptor.service';

import { MsalModule, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { CountdownModule } from 'ngx-countdown';

import { MaterialModule } from '../app/modules/material/material.module';
import { AppInsightsService } from '../app/services/utility-services';
import { HeaderInterceptorService } from '../core';
import { environment } from '../environments/environment';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';

const isIE = window.navigator.userAgent.includes('MSIE ') || window.navigator.userAgent.includes('Trident/');

@NgModule({
    declarations: [
        MainComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MaterialModule,
        MainRoutingModule,
        RouterModule,
        CountdownModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: environment.azure.clientId,
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: isIE,
            }
        }), {
            interactionType: InteractionType.Popup,
            authRequest: {
                scopes: [environment.azure.scopes]
            }
        }, {
            interactionType: InteractionType.Popup,
            protectedResourceMap: new Map([
                ['https://graph.microsoft.com/v1.0/me', ['user.read']]
            ])
        }),
    ],
    providers: [
        MsalService,
        AppInsightsService,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptorService, multi: true },
    ],
    bootstrap: [MainComponent],
})
export class MainModule { }
