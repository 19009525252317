<div
    class="kh-form-header-text"
    mat-dialog-title
>
    Potential data overwrite
</div>

<div
    mat-dialog-content
    class="kh-dialog-content"
>
    <p class="kh-text">
        The following {{ overriddenSites.length }} {{ siteVsSites }}
        from your shapefile already exist in your SMARTS app.
        Uploading this data will overwrite existing details for {{ theseVsThis }} {{ siteVsSites }},
        including {{ siteAliasLowerCase }} name, geojson, and charge number.
    </p>
    <p class="kh-text kh-bold-text">
        How would you like to proceed?
    </p>

    <kendo-grid
        [data]="dataSource"
        [height]="200"
        class="kh-grid-header-left-align kh-duplicate-sites-grid"
    >
        <kendo-grid-column
            field="siteNo"
            [title]="siteNumberTitle"
        ></kendo-grid-column>
    </kendo-grid>
</div>

<div
    mat-dialog-actions
    class="kh-dialog-controls-container"
>
    <div class="kh-dialog-controls">
        <app-secondary-button
            (onClick)="closeDialog()"
            mat-dialog-close
        >
            Cancel
        </app-secondary-button>
        <span class="kh-float-right">
            <app-flat-button
                (onClick)="onConfirm()"
                class="flat-button"
            >
            Continue upload
        </app-flat-button>
        </span>

    </div>
</div>
