import { Injectable } from '@angular/core';

import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { Site } from '../../models';
import { SitesComboboxModel } from './models';

@Injectable({
    providedIn: 'root'
})

export class SitesComboboxService {

    model: SitesComboboxModel = new SitesComboboxModel();

    constructor() { }

    openDropdown(siteCombobox: ComboBoxComponent): void {
        if (siteCombobox.isOpen)
            return;
        siteCombobox.toggle(true);
    }

    setSelectedSite(site: Site | undefined): void {
        if (!site?.id) {
            this.model.selectedSite = null;
            return;
        }

        if (this.model.selectedSite?.id === site.id)
            return;

        this.model.selectedSite = site;
    }
}
