/* istanbul ignore file */

import { GeoJsonFeatureType } from '../shared/enums';
import StyleOptions = google.maps.Data.StyleOptions;
import Icon = google.maps.Icon;
import MarkerShape = google.maps.MarkerShape;
export class GeoJsonFeature {
    type = "Feature";
    properties: GeoJsonProperties;
    geometry: LineString | MultiLineString | MultiPoint | MultiPolygon | Point | Polygon;

    constructor(geometry: LineString | MultiLineString | MultiPoint | MultiPolygon | Point | Polygon, siteId?: string) {
        this.geometry = geometry;

        if (!siteId) {
            this.properties = {};
            return;
        }

        this.properties = {
            siteId
        };
    }
}

export class GeoJsonProperties implements StyleOptions {
    siteId?: string;
    clickable?: boolean;
    cursor?: string;
    draggable?: boolean;
    editable?: boolean;
    fill?: string;
    fillColor?: string;
    fillOpacity?: number;
    'fill-opacity'?: number;
    icon?: google.maps.Symbol | Icon | string;
    shape?: MarkerShape;
    stroke?: string;
    strokeColor?: string;
    strokeOpacity?: number;
    'stroke-opacity'?: number;
    strokeWeight?: number;
    'stroke-width'?: number;
    title?: string;
    visible?: boolean;
    zIndex?: number;
    layer?: number;
    [name: string]: any;
}

export type Position = [number, number, number] | [number, number];

export interface Point {
    coordinates: Position;
    type: GeoJsonFeatureType.Point;
}

interface MultiPoint {
    coordinates: Position[];
    type: GeoJsonFeatureType.MultiPoint;
}

interface LineString {
    coordinates: Position[];
    type: GeoJsonFeatureType.LineString;
}

interface MultiLineString {
    coordinates: Position[][];
    type: GeoJsonFeatureType.MultiLineString;
}

export class Polygon {
    coordinates: Position[][];
    type: GeoJsonFeatureType.Polygon;
}

interface MultiPolygon {
    coordinates: Position[][][];
    type: GeoJsonFeatureType.MultiPolygon;
}

export class Coordinate {
    lat: number;
    lng: number;

    constructor(coordinates: Position) {
        //[lng, lat]
        this.lat = coordinates[1];
        this.lng = coordinates[0];
    }
}
