/* istanbul ignore file */
export enum FieldType {
    TextBox = '1',
    TextArea = '2',
    DateSelect = '3',
    CheckBox = '4',
    DropDown = '5',
    PhotoGallery = '6',
    Uploader = '7',
    Grid = '8',
    Number = '9',
    ReadOnlyText = '10',
    ReadOnlyTextArea = '11',
    URLHyperlink = '12',
    EmailHyperlink = '13'
}

export enum FieldTypeName {
    TextBox = 'Text Box',
    TextArea = 'Text Area',
    DateSelect = 'Date Select',
    CheckBox = 'Checkbox',
    DropDown = 'Drop Down List',
    PhotoGallery = 'Photo Gallery',
    Uploader = 'Uploader',
    UploaderOld = 'File Upload',
    Grid = 'Grid',
    Number = 'Number',
    ReadOnlyText = 'Read Only Text',
    ReadOnlyTextArea = 'Read Only Text Area',
    URLHyperlink = 'Web Link',
    EmailHyperlink = 'Email'
}
