import { Color } from '@angular-material-components/color-picker';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FieldType, FieldTypeName, FieldTypePrimitive } from '../../shared/enums';
import { ObjectToolService } from '../tools/object-tool.service';

@Injectable({
    providedIn: 'root',
})
export class LayoutEditorService {

    //The sections of the current instance
    private _sections = [];

    private fieldTypeOptions: any[] = [
        {
            id: FieldType.TextBox,
            text: FieldTypeName.TextBox,
            type: FieldTypePrimitive.String,
        },
        {
            id: FieldType.TextArea,
            text: FieldTypeName.TextArea,
            type: FieldTypePrimitive.String,
            options: {
                multi: true,
            },
        },
        {
            id: FieldType.DateSelect,
            text: FieldTypeName.DateSelect,
            type: FieldTypePrimitive.String,
            options: {
                format: 'date',
                dateFormat: 'MM/DD/YYYY',
                dateSaveFormat: 'YYYY-MM-DD',
            },
        },
        {
            id: FieldType.CheckBox,
            text: FieldTypeName.CheckBox,
            type: FieldTypePrimitive.Boolean,
            options: {
                toggle: true,
            },
        },
        {
            id: FieldType.DropDown,
            text: FieldTypeName.DropDown,
            type: FieldTypePrimitive.String,
            enum: [],
        },
        {
            id: FieldType.PhotoGallery,
            text: FieldTypeName.PhotoGallery,
            photos: true,
            options: {
                height: "500px",
                arrows: true,
                endless: true,
            },
        },
        {
            id: FieldType.Uploader,
            text: FieldTypeName.Uploader,
            upload: true,
            options: {
                allowedExtensions: [''],
                maxFileSize: 100000000,
            },
        },
        {
            id: FieldType.Grid,
            text: FieldTypeName.Grid,
            grid: true,
            options: {
                columns: [],
            },
        },
        {
            id: FieldType.Number,
            text: FieldTypeName.Number,
            type: 'number',
        },
        {
            id: FieldType.ReadOnlyText,
            text: FieldTypeName.ReadOnlyText,
            menuDisplayText: 'Text',
            type: FieldTypePrimitive.String,
            options: {
                readOnly: true,
                displayText: '',
                displayColor: new Color(66, 69, 83, 1),
                bold: true,
                size: '1.6rem',
                alignment: 'Center',
                italic: false,
            },
        },
        {
            id: FieldType.ReadOnlyTextArea,
            text: FieldTypeName.ReadOnlyTextArea,
            menuDisplayText: 'Text Area',
            type: FieldTypePrimitive.String,
            options: {
                readOnly: true,
                displayText: '',
                multi: true,
            },
        },
        {
            id: FieldType.URLHyperlink,
            text: FieldTypeName.URLHyperlink,
            menuDisplayText: 'Web Link'
        },
        {
            id: FieldType.EmailHyperlink,
            text: FieldTypeName.EmailHyperlink,
            menuDisplayText: 'Email',
            type: FieldTypePrimitive.String,
        },
    ];

    sections: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
    comboboxEnum: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
    repositoryField: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    showLayoutPreview: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    disableProjectWhenPreview: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    previewStash: any;

    constructor(private _objectTool: ObjectToolService) { }

    //Return the current array of all sections
    getSections(): any[] {
        this.sections.next(this._sections);
        return this._sections;
    }

    //Set the sections array to the passed array value
    setSections(s: any): void {
        this.sections.next(s);
        this._sections = s;
    }

    //Get the field type options
    getFieldTypeOptions(): any[] {
        return this.fieldTypeOptions;
    }

    //used for switch cases
    getFieldTypeValue(key): any {
        return FieldType[key];
    }

    //used for if statements
    compareFieldTypeToKey(fieldId: string, key: string): boolean {
        return fieldId === FieldType[key] ? true : false;
    }

    isFieldTypeTextBox(fieldId): boolean {
        return this.compareFieldTypeToKey(fieldId, 'TextBox');
    }

    isFieldTypeTextArea(fieldId): boolean {
        return this.compareFieldTypeToKey(fieldId, 'TextArea');
    }

    isFieldTypeDateSelect(fieldId): boolean {
        return this.compareFieldTypeToKey(fieldId, 'DateSelect');
    }

    isFieldTypeCheckBox(fieldId): boolean {
        return this.compareFieldTypeToKey(fieldId, 'CheckBox');
    }

    isFieldTypeDropDown(fieldId): boolean {
        return this.compareFieldTypeToKey(fieldId, 'DropDown');
    }

    isFieldTypePhotoGallary(fieldId): boolean {
        return this.compareFieldTypeToKey(fieldId, 'PhotoGallary');
    }

    isFieldTypeUploader(fieldId): boolean {
        return this.compareFieldTypeToKey(fieldId, 'Uploader');
    }

    isFieldTypeGrid(fieldId): boolean {
        return this.compareFieldTypeToKey(fieldId, 'Grid');
    }

    isFieldTypeNumber(fieldId): boolean {
        return this.compareFieldTypeToKey(fieldId, 'Number');
    }

    isFieldTypeReadOnlyText(fieldId): boolean {
        return this.compareFieldTypeToKey(fieldId, 'ReadOnlyText');
    }

    isFieldTypeReadOnlyTextArea(fieldId): boolean {
        return this.compareFieldTypeToKey(fieldId, 'ReadOnlyTextArea');
    }

    isFieldTypeAReadOnlyField(fieldId): boolean {
        return this.isFieldTypeReadOnlyText(fieldId) || this.isFieldTypeReadOnlyTextArea(fieldId);
    }
}
