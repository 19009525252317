import { Column } from '.';

/* istanbul ignore file */
export class Section {
    Columns?: Column[];
    enableDuplication?: boolean;
    id?: string;
    parentId?: string;
    Sections?: Section[];
    SectionTitle?: string;
    sectionSubtitle?: string;
    type?: string;
}
