import { FormGroup } from '@angular/forms';
import { File as FileInfo } from "src/app/models/file";
import { MapSettingsPreviewModel } from '../../../../models/map-settings-preview-model';

/* istanbul ignore file */
export class MapSettingsFormLogicServiceModel {
    mapSettingsForm: FormGroup;
    rawFile: File;
    mapPins: FileInfo[] = [];
    mapPin: FileInfo;
    mapPinPreviewSettings: MapSettingsPreviewModel;
    loadingData = false;
}
