
/* istanbul ignore file */
export class WizardStep {
    label = '';
    icon = '';

    constructor(label: string, icon: string) {
        this.label = label;
        this.icon = icon;
    }
}
