import { Injectable } from '@angular/core';

import { ExcelExportEvent } from '@progress/kendo-angular-grid';

@Injectable({
    providedIn: 'root'
})
export class ExcelExportService {

    emailPattern = /^[^@\s]+@[^@\s\.]+(\.[A-Za-z]+)+$/;

    constructor() { }

    onExcelExport(e: ExcelExportEvent): void {
        const rows = e.workbook.sheets[0].rows;
        // set alternating row color
        let altIdx = 0;
        rows.forEach((row) => {
            if (row.type === "data") {
                row.cells.forEach((cell) => {
                    if (cell.value?.includes("label") && cell.value?.includes("urlLink"))
                        this.createURLHyperlink(cell);

                    if (this.emailPattern.test(cell.value))
                        this.createEmailHyperlink(cell);
                });
                if (altIdx % 2 !== 0) {
                    row.cells.forEach((cell) => {
                        cell.background = "#F7F7F7";
                    });
                }
                altIdx++;
            }
        });
    }

    createURLHyperlink(cell: any): void {
        const label = this.retrieveURLLabel(cell.value);
        const link = this.retrieveURLLink(cell.value);
        cell.value = label;
        cell.formula = `=HYPERLINK("${link}","${label}")`;
        cell.color = '#0070e0';
        cell.underline = true;
    }

    createEmailHyperlink(cell: any): void {
        cell.formula = `=HYPERLINK("mailto:${cell.value as string}","${cell.value as string}")`;
        cell.color = '#0070e0';
        cell.underline = true;
    }

    retrieveURLLabel(stringifiedObject: string): string {
        if (!stringifiedObject || stringifiedObject === "") return;
        const urlObject = JSON.parse(stringifiedObject);
        return urlObject.label;
    }

    retrieveURLLink(stringifiedObject: string): string {
        if (!stringifiedObject || stringifiedObject === "") return;
        const urlObject = JSON.parse(stringifiedObject);

        if (!urlObject.urlLink.includes('http'))
            urlObject.urlLink = `http://${urlObject.urlLink as string}`;

        return urlObject.urlLink;
    }
}
