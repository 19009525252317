/* istanbul ignore file */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';
import { App, Project, Site } from '../../models';
import { BulkSiteImportResultData } from '../../models/bulkSiteImportResultData';
import { ExcelSiteProperties } from '../logic-services/models/excelSiteProperties';

@Injectable({
    providedIn: 'root'
})
export class SitesImportDataService extends HttpBaseService {

    route = environment.api + 'sitesimport';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    bulkSitesImport(appId: string, projId: string, excelSiteProperties: ExcelSiteProperties[]): Observable<boolean> {
        return this.httpPost(`${this.route}/app/${appId}/project/${projId}/bulk-sites-import`, excelSiteProperties);
    }

    importSitesFromShapefile(app: App, project: Project, siteImport: Site[]): Observable<void> {
        return this.httpPost(`${this.route}/app/${app.id}/project/${project.id}/import-shapefile`, siteImport);
    }

    checkBulkSitesProcessing(appId: string): Observable<BulkSiteImportResultData> {
        return this.httpGet(`${this.route}/app/${appId}/bulk-sites-processing`);
    }

}
