import { Component, HostBinding, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { KHThemeService } from './services/tools/theme.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    @HostBinding('class') className = '';

    title = 'Smarts Platform';
    height: number = 0; //minus header & footer height

    constructor(
        private _router: Router,
        private _themeService: KHThemeService,
    ) { }

    ngOnInit(): void {
        this._router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this._themeService.calculateContentHeight();
            }
        });

        window.onresize = (event) => {
            this._themeService.calculateContentHeight();
        };

        this._themeService.contentHeight.subscribe((height) => {
            this.height = height;
        });
    }

}
