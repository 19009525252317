import { Injectable } from '@angular/core';

import jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';

import { LogoutTimerService } from '.';
import { DecodedToken, User } from '../models';
import { Claims, Token } from '../shared/enums';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    private _currentToken: string;

    token: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    get currentToken(): string {
        return this._currentToken;
    }

    constructor(
        private _logoutTimerService: LogoutTimerService
    ) {
        let sessionToken = sessionStorage.getItem("token");

        if (sessionToken)
            this.setToken(sessionToken);
    }

    setToken(token: string): void {
        if (token === null || token === undefined)
            return;

        this._currentToken = token;

        this.token.next(token);

        sessionStorage.setItem('token', token);

        this._logoutTimerService.resetTimerBehavior.next(true);
    }

    getTokenProp(claimKey: string, appId: string = null): boolean {
        let result = false;

        const token = sessionStorage.getItem('token');

        if ((token === null) || (token === undefined))
            return result;

        const decodedToken = jwt_decode<DecodedToken>(token);

        if ((claimKey !== Claims.isAppAdmin) && (claimKey !== Claims.isProjectAdmin))
            return decodedToken[claimKey];

        if (decodedToken[Token.appPermissions]) {
            const appPermissions: User[] = JSON.parse(decodedToken[Token.appPermissions]);

            appPermissions.forEach((app: User) => {
                if (app.id === appId)
                    result = app[claimKey];
            });
        }

        return result;
    }

    tokenIsStillValid(): boolean {
        let tokenIsStillValid = false;

        const token = sessionStorage.getItem('token');

        if ((token === null) || (token === undefined))
            return tokenIsStillValid;

        const decodedToken = jwt_decode<DecodedToken>(token);

        if (decodedToken.exp > (Date.now() / 1000))
            tokenIsStillValid = true;

        return tokenIsStillValid;
    }
}
