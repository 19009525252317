import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class ObjectToolService {

    constructor() { }

    //Find the first object that contains the given field value f
    findInObject(o, f, cloneDeep = false) {
        let found;
        for (let i = 0; i < Object.keys(o).length; i++) {
            if (o[Object.keys(o)[i]] === f) {
                if (cloneDeep) {
                    return _.cloneDeep(o);
                } else {
                    return o;
                }
            }
            if (Array.isArray(o[Object.keys(o)[i]]) || typeof o[Object.keys(o)[i]] === 'object' && o[Object.keys(o)[i]] !== null) {
                found = this.findInObject(o[Object.keys(o)[i]], f, cloneDeep);
                if (found) {
                    if (cloneDeep) {
                        return _.cloneDeep(found);
                    } else {
                        return found;
                    }
                }
            }
        }
    }

    //Find all objects that contains the given field value f
    findAllInObject(o, f) {
        let arr = [];
        function startMapping(o, f) {
            const keys = Object.keys(o);
            let temp;
            for (let i = 0; i < keys.length; i++) {
                if (o[keys[i]] === f) {
                    arr.push(o);
                }
                if (Array.isArray(o[keys[i]]) || typeof o[keys[i]] === 'object' && o[keys[i]] !== null) {
                    temp = startMapping(o[keys[i]], f);
                    if (temp) {
                        arr.push(temp);
                    }
                }
            }
        }
        startMapping(o, f);
        return arr;
    }

    //Find all objects that has the property f
    findAllPropertiesInObject(o, p) {
        let arr = [];
        function startMapping(o, p) {
            const keys = Object.keys(o);
            let temp;
            for (let i = 0; i < keys.length; i++) {
                if (keys[i] === p) {
                    arr.push(o);
                }
                if (Array.isArray(o[keys[i]]) || typeof o[keys[i]] === 'object' && o[keys[i]] !== null) {
                    temp = startMapping(o[keys[i]], p);
                    if (temp) {
                        arr.push(temp);
                    }
                }
            }
        }
        startMapping(o, p);
        return arr;
    }

    findInObjectAndChange(object, fieldValue, identifier, changeValue, cloneDeep = false) {
        return Object.keys(object).map(function (a) {
            if (Array.isArray(object[a]) || typeof object[a] === 'object' && object[a] !== null) {
                return this.findInObjectAndChange(object[a], fieldValue, identifier, changeValue, cloneDeep);
            }
            if (object[a] === fieldValue) {
                if (cloneDeep) {
                    object[identifier] = _.cloneDeep(changeValue);
                } else {
                    object[identifier] = changeValue;
                }
                return true;
            } else {
                return null;
            }
        }.bind(this));
    }

    //Find object based on fieldValue and deletes the object
    findInObjectAndDelete(object, fieldValue, identifier) {
        return Object.keys(object).map(function (a) {
            if (Array.isArray(object[a]) || typeof object[a] === 'object' && object[a] !== null) {
                return this.findInObjectAndChange(object[a], fieldValue, identifier);
            }
            if (object[a] === fieldValue) {
                delete object[identifier];
                return true;
            } else {
                return null;
            }
        }.bind(this));
    }

    //Find object based on fieldValue and removes from the object
    findInObjectAndRemove(object, fieldValue, outerObject?) {
        let found;
        for (let i = 0; i < Object.keys(object).length; i++) {
            if (object[Object.keys(object)[i]] === fieldValue) {
                outerObject.splice(outerObject.indexOf(object), 1);
            }
            if (Array.isArray(object[Object.keys(object)[i]]) || typeof object[Object.keys(object)[i]] === 'object' && object[Object.keys(object)[i]] !== null) {
                found = this.findInObjectAndRemove(object[Object.keys(object)[i]], fieldValue, object);
                if (found) {
                    outerObject.splice(outerObject.indexOf(object), 1);
                }
            }
        }
    }

    //Find differences between two objects and return object of differences
    findDifferences(origObj, newObj) {
        function changes(newObj, origObj) {
            let arrayIndexCounter = 0;
            return _.transform(newObj, function (result, value, key) {
                if (!_.isEqual(value, origObj[key])) {
                    let resultKey = _.isArray(origObj) ? arrayIndexCounter++ : key;
                    result[resultKey] = (_.isObject(value) && _.isObject(origObj[key])) ? changes(value, origObj[key]) : value;
                }
            });
        }
        return changes(newObj, origObj);
    }

    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    getFormErrors(form: AbstractControl, formName: string = 'Sections') {
        const errors = [];
        function getErrors(form: AbstractControl, formName: string = 'Sections') {
            if (form instanceof FormControl) {
                // Return FormControl errors or null
                return form.errors ?? null;
            }
            if (form instanceof FormGroup || form instanceof FormArray) {
                const groupErrors = form.errors;
                Object.keys(form.controls).forEach(key => {
                    // Recursive call of the FormGroup fields
                    const error = getErrors(form.get(key), key);
                    if (error !== null) {
                        Object.keys(error).forEach(l => {
                            // Only add error if not null
                            errors.push(errorType(key, error[l], l));
                        });
                    }
                });
                // Return FormGroup errors or null
                return groupErrors ? groupErrors : null;
            }

            function errorType(key, error, errorName) {
                switch (key) {
                    case 'SectionTitle': key = 'Section Title';
                    case 'enum': key = 'Combobox options';
                }
                switch (errorName) {
                    case 'required': return `${key} is required!`; case 'pattern': return `${key} has wrong pattern!`; case 'email': return `${key} has wrong email format!`; case 'minlength': return `${key} has wrong length! Required length: ${error.requiredLength}`; case 'areEqual': return `${key} must be equal!`; default: return `${key}: ${errorName}: ${error}`;
                }
            }
        }
        getErrors(form);
        return errors;
    }

}

