import { Editor } from 'ngx-editor';

/* istanbul ignore file */
export class RichTextGridColumnDictionary {
    [key: string]: RichTextGridFieldEditor;
}

export class RichTextGridFieldEditor {
    inEditMode = false;
    editor: Editor = new Editor();
}
