import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { TokenService } from '../../app/services';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

    constructor(
        private _tokenService: TokenService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes(environment.api) && this._tokenService.tokenIsStillValid())
            req = req.clone({ setHeaders: { Authorization: `Bearer ${this._tokenService.currentToken}` } });

        return next.handle(req)
            .pipe(
                filter(event => event instanceof HttpResponse),
                tap((response: HttpResponse<any>) => {
                    this._tokenService.setToken(response.headers.get('authorization'));
                }),
            );
    }

}
