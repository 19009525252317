import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';
import { App, Site, User } from '../../models';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class SiteRequestDataService extends HttpBaseService {

    private route = environment.api + 'siteRequest/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    createSiteRequest(app: App, siteRequestData: unknown): Observable<any> {
        const url = `${this.route}app/${app.id}/container/${app.fileContainers.private.name}/siteRequest`;
        return this.httpPost(url, siteRequestData);
    }

    getSiteRequests(app: App, user: User): Observable<any> {
        const url = `${this.route}app/${app.id}/userId/${user.id}/requestedSites`;
        return this.httpGet(url);
    }

    deleteSiteRequest(app: App, site: Site): Observable<any> {
        const url = `${this.route}app/${app.id}/site/${site.id}/container/${app.fileContainers.private.name}/deleteSiteRequest`;
        return this.httpDelete(url);
    }

    updateSiteRequest(app: App, siteRequestId: string, siteRequestData: any): Observable<any> {
        const url = `${this.route}app/${app.id}/siteRequestId/${siteRequestId}/container/${app.fileContainers.private.name}/updateSiteRequest`;
        return this.httpPost(url, siteRequestData);
    }

}
