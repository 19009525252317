import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { HttpBaseService } from '../../../core';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class MapSettingsDataService extends HttpBaseService {

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    loadSVG(): Promise<Document> {
        return this._httpClient.get('assets/icons/Pin.svg', { responseType: 'text' })
            .toPromise()
            .then(svgData => {
                const parser = new DOMParser();
                return parser.parseFromString(svgData, 'image/svg+xml');
            });
    }

}
