/* istanbul ignore file */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TermsOfServiceDataService extends HttpBaseService {

    private route = environment.api + 'tos/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    updateTermsOfService(userId: string, updatedUser: any): Observable<any> {
        const url = `${this.route}user/${userId}`;
        return this.httpPost(url, updatedUser);
    }

}
