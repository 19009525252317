import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { App, Project } from '../models';
import { SiteDataImportDataService } from './data-services';

@Injectable({
    providedIn: 'root'
})
export class SiteDataImportService {

    constructor(
        private _siteDataImportData: SiteDataImportDataService
    ) { }

    importData(app: App, project: Project, dataImport: any): Observable<any> {
        const result$ = new Subject<any>();

        this._siteDataImportData.importData(app, project, dataImport)
            .subscribe({
                next: (response: any) => {
                    result$.next(response);
                    result$.complete();
                }
            });

        return result$.asObservable();
    }

}
