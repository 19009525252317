import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { App, Project, User } from '../../models';
import { UserProjectLists } from '../logic-services/models';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class ProjectsDataService extends HttpBaseService {

    private route = environment.api + 'projects/app/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    getAllUserProjects(app: App): Observable<UserProjectLists> {
        const url = `${this.route}${app.id}/projects`;
        return this.httpGet(url);
    }

    getProjectByAppIdAndProjectId(app: App, project: Project): Observable<Project> {
        const url = `${this.route}${app.id}/project/${project.id}`;
        return this.httpGet(url);
    }

    saveDraft(app: App, project: Project): Observable<Project> {
        const url = `${this.route}${app.id}/layoutDraft`;
        return this.httpPost(url, project);
    }

    publishProject(app: App, project: Project): Observable<Project> {
        const url = `${this.route}${app.id}/JSONForms,layoutDraft`;
        return this.httpPost(url, project);
    }

    updateProject(app: App, project: Project): Observable<Project> {
        const url = `${this.route}${app.id}`;
        return this.httpPost(url, project);
    }

    updateProjectWithFilter(app: App, project: Project, filter: string[]): Observable<Project> {
        const url = `${this.route}${app.id}/${filter}`;
        return this.httpPost(url, project);
    }

    getProjectAdminsByProjectId(app: App, project: Project): Observable<User[]> {
        const url = `${this.route}${app.id}/project/${project.id}/admins`;
        return this.httpGet(url);
    }

    createItem(app: App, project: Project): Observable<Project> {
        const url = `${this.route}${app.id}`;
        return this.httpPost(url, project);
    }

    exportLayout(app: App, project: Project): Observable<any> {
        const url = `${this.route}${app.id}/project/${project.id}/layout`;
        return this.httpDownload(url);
    }

    uploadLayout(app: App, project: Project, asdf: FormData): Observable<any> {
        const url = `${this.route}${app.id}/project/${project.id}/layout`;
        return this.httpPost(url, asdf);
    }

    addProjectAdmin(app: App, project: Project, userId: string): Observable<any> {
        const url = `${this.route}${app.id}/project/${project.id}/user/${userId}/true`;
        return this.httpGet(url);
    }

}
