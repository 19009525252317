import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { BreakpointLogicServiceModel } from './models/breakpoint-logic-service-model';

@Injectable({
    providedIn: 'root'
})
export class BreakpointLogicService {

    private breakpointChanged: Subject<boolean> = new Subject<boolean>();

    model: BreakpointLogicServiceModel = new BreakpointLogicServiceModel();
    breakpointChanged$ = this.breakpointChanged.asObservable();

    readonly breakpoint$ = this.breakpointObserver.observe([
        Breakpoints.Tablet,
        Breakpoints.Web
    ]);

    constructor(
        private breakpointObserver: BreakpointObserver
    ) {
        this.initSubscriptions();
    }

    initSubscriptions(): void {
        this.breakpoint$.subscribe(() => {
            this.breakpointChange();
        });
    }

    breakpointChange(): void {
        this.model.oldBreakpoint = this.model.currentBreakpoint;

        if (this.breakpointObserver.isMatched(Breakpoints.Web) && window.innerWidth > 1024) {
            this.model.currentBreakpoint = Breakpoints.Web;
        }
        else {
            this.model.currentBreakpoint = Breakpoints.Tablet;
        }

        if (this.model.oldBreakpoint !== this.model.currentBreakpoint) {
            this.breakpointChanged.next(true);
        }
    }


}
