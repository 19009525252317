import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { App, Project, User } from '../../models';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class UsersDataService extends HttpBaseService {

    private route = environment.api + 'users/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    setAsAppAdmin(app: App, user: User): Observable<User> {
        const url = `${this.route}app/${app.id}/user/${user.id}/admin`;
        return this.httpPost(url, user.isAppAdmin);
    }

    setAsProjectAdmin(app: App, project: Project, user: User): Observable<User> {
        const url = `${this.route}app/${app.id}/project/${project.id}/user/${user.id}`;
        return this.httpPost(url, user.isProjectAdmin);
    }

    removeUserFromApp(user: User): Observable<any> {
        const url = `${this.route}remove-user-from-app`;
        return this.httpPost(url, user);
    }

    updatePlatformUser(user: User): Observable<User> {
        const url = `${this.route}${user.id}/platform/isPlatformAdmin`;
        return this.httpPost(url, user);
    }

    isAccountApprover(user: User): Observable<User> {
        const url = `${this.route}user/isAccountApprover`;
        return this.httpPost(url, user);
    }

    changeActive(user: User): Observable<any> {
        const url = `${this.route}user/id,active`;
        return this.httpPost(url, user);
    }

    openEditUser(user: User): Observable<any> {
        const url = `${this.route}user/firstName,lastName,email,cellPhone`;
        return this.httpPost(url, user);
    }

    getUser(id: string): Observable<User> {
        const url = `${this.route}${id}`;
        return this.httpGet(url);
    }

    loadUsersByApplicationAndProject(app: App, project: Project): Observable<User[]> {
        const url = `${this.route}app/${app.id}/project/${project.id}`;
        return this.httpGet(url);
    }

    getUsersByApp(app: App): Observable<User[]> {
        const url = `${this.route}app/${app.id}/users`;
        return this.httpGet(url);
    }

    getPlatformUsers(): Observable<any> {
        const url = `${this.route}platformUsers/`;
        return this.httpGet(url);
    }

    createPlatformUser(user: User): Observable<any> {
        const url = `${this.route}add-user-to-platform`;
        return this.httpPost(url, user);
    }

    createPlatformUserAndAddToApp(user: User): Observable<any> {
        const url = `${this.route}add-user-to-platform-and-app`;
        return this.httpPost(url, user);
    }

    addUserToApp(user: User): Observable<HttpErrorResponse | User> {
        const url = `${this.route}add-user-to-app`;
        return this.httpPost(url, user);
    }

    setProjectUserStatus(user: User): Observable<User> {
        const url = `${this.route}set-project-user-status`;
        return this.httpPost(url, user);
    }

    appsAndProjectsByUserId(user: User): Observable<User> {
        const url = `${this.route}apps-and-projects-by-user-id`;
        return this.httpPost(url, user);
    }

    requestAccess(user: User): Observable<any> {
        const url = `${this.route}request`;
        return this.httpPost(url, user);
    }

    getAppUser(app: App, user: User): Observable<User> {
        const url = `${this.route}app/${app.id}/user/${user.id}`;
        return this.httpGet(url);
    }

    changeUserRole(app: App, user: User): Observable<User> {
        const url = `${this.route}app/${app.id}/user/projects`;
        return this.httpPost(url, user);
    }

    loadApps(user: User): Observable<App[]> {
        const url = `${this.route}${user.id}/apps`;
        return this.httpGet(url);
    }

    updateUserAccountSettings(id: string, item: any): Observable<User> {
        const url = `${this.route}user/id/${id}`;
        return this.httpPost(url, item);
    }

    updateUserSitesAccess(user: User, app: App): Observable<User> {
        const url = `${this.route}app/${app.id}/user/update-sites-access`;
        return this.httpPost(url, user);
    }

    updatePlatformUserData(userId: string, propertiesToUpdate: User): Observable<User> {
        const url = `${this.route}user/id/${userId}/update-platform-user-data`;
        return this.httpPost(url, propertiesToUpdate);
    }
}
