/* istanbul ignore file */
export enum AdminTabs {
    Map = 'Map',
    MapLogic = 'Map Logic',
    Smartsheets = 'Smartsheets',
    Users = 'Users',
    Sites = 'Sites',
    FormBuilder = 'Form Builder',
    Customization = 'Customization',
    Projects = 'Projects',
    ProjectNotes = 'Project Notes',
    Roles = 'Roles',
    ReportBuilder = 'Report Builder',
    Notifications = 'Notifications',
    DataImport = 'Data Import',
    DocumentTemplate = 'Documents',
    Invoices = 'Invoices'
}
