import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ReleaseSummary, User } from '../../models';
import { ReleaseSummaryDataService, UsersDataService } from '../data-services';
import { DateConversionService } from '../utility-services';

@Injectable({
    providedIn: 'root'
})
export class ReleaseSummaryLogicService {

    private _showReleaseSummary = new Subject<boolean>();
    showReleaseSummary$ = this._showReleaseSummary.asObservable();

    user?: User;
    releaseSummary?: ReleaseSummary;

    showingReleaseSummary = false;

    constructor(
        private _releaseSummaryDataService: ReleaseSummaryDataService,
        private _dateConversionUtilityService: DateConversionService,
        private _usersDataService: UsersDataService
    ) { }

    requestAndCheckShowReleaseSummary(user: User): void {
        this.user = user;
        if (!this.releaseSummary) {
            this._releaseSummaryDataService.getReleaseSummary()
                .subscribe((releaseSummary: ReleaseSummary) => {
                    this.releaseSummary = releaseSummary;
                    this.checkShowReleaseSummary(this._dateConversionUtilityService.convertDateToString(new Date()));
                });
        }
    }

    private checkShowReleaseSummary(currentDateString: string): void {
        if (this.showingReleaseSummary)
            return;

        if (this.shouldUserSeeReleaseSummary(currentDateString)) {
            this.showingReleaseSummary = true;
            this._showReleaseSummary.next(true);
        }
        else
            this._showReleaseSummary.next(false);
    }

    shouldUserSeeReleaseSummary(currentDateString: string): boolean {
        if (!this.releaseSummary.showPopup)
            return false;

        const releaseTimestamp: number = Date.parse(this.releaseSummary.releaseDate);
        const currentDateTimestamp: number = Date.parse(currentDateString);

        if (this.getDaysElaspedBetweenTimestamps(releaseTimestamp, currentDateTimestamp) > this.releaseSummary.maxDaysAfterReleaseDateToShowPopup)
            return false;

        if (!this.user.lastReleaseSummaryClosedDate)
            return true;

        const userLastClosedTimestamp: number = Date.parse(this.user.lastReleaseSummaryClosedDate);
        if (userLastClosedTimestamp < releaseTimestamp)
            return true;

        return false;
    }

    getDaysElaspedBetweenTimestamps(earlierTimestamp: number, laterTimestamp: number): number {
        const millisecondsPassed = laterTimestamp - earlierTimestamp;
        return this.convertMillisecondsToDays(millisecondsPassed);
    }

    convertMillisecondsToDays(milliseconds: number): number {
        return milliseconds / (1000 * 60 * 60 * 24);
    }

    releaseSummaryClosed(): void {
        this.showingReleaseSummary = false;
        this.user.lastReleaseSummaryClosedDate = this._dateConversionUtilityService.convertDateToString(new Date());
        const propertiesToUpdate: User = { id: this.user.id, lastReleaseSummaryClosedDate: this.user.lastReleaseSummaryClosedDate };
        this._usersDataService.updatePlatformUserData(this.user.id, propertiesToUpdate).subscribe({
            next: (updatedUser: User) => {
                this.user = updatedUser;
            }
        });
    }
}
