import { Field } from '.';

/* istanbul ignore file */
export class Column {
    enum?: string[];
    Fields?: Field[];
    GroupTitle?: string;
    header?: string;
    id: string;
    parentId?: string;
    order?: number;
    type: string;
}
