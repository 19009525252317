import { Injectable } from '@angular/core';

import { Coordinate, GeoJsonFeature, Point, Polygon, Position, Site } from '../../models';
import { GeoJsonFeatureType } from '../../shared/enums';

@Injectable({
    providedIn: 'root'
})
export class GeoJsonUtilityService {

    constructor() { }

    setSiteCenterPoint(site: Site): void {
        let geometry = site?.geoJson?.geometry;
        const geoType = site?.geoJson?.geometry?.type;
        if (!geoType) return;


        if (geoType === GeoJsonFeatureType.Point) {
            geometry = geometry as Point;
            let coordinate = new Coordinate(geometry.coordinates);
            this.setCenterLatLongForGeoJson(site, coordinate);
            return;
        }

        if (geoType === GeoJsonFeatureType.Polygon) {
            let centerPoint = this.calculateCenterPointForPolygon(site.geoJson);
            this.setCenterLatLongForGeoJson(site, centerPoint);
            return;
        }

        if (geoType === GeoJsonFeatureType.MultiPoint) {
            let centerPoint = this.calculateCenterPointForLineStringAndMultiPoint(site.geoJson);
            this.movePinToClosestPointOnMultiPoint(site, centerPoint);
            return;
        }

        if (geoType === GeoJsonFeatureType.LineString) {
            let centerPoint = this.calculateCenterPointForLineStringAndMultiPoint(site.geoJson);
            this.movePinToClosestPointOnLine(site, centerPoint);
            return;
        }

        if (geoType === GeoJsonFeatureType.MultiLineString) {
            let centerPoint = this.calculateCenterPointForMultiLineString(site.geoJson);
            this.movePinToClosestPointOnClosestLine(site, centerPoint);
            return;
        }

        if (geoType === GeoJsonFeatureType.MultiPolygon) {
            let centerPoint = this.calculateCenterPointForMultiPolygon(site.geoJson);
            this.movePinToClosestPolygon(site, centerPoint);
            return;
        }
    }

    calculateCenterPointForLineStringAndMultiPoint(geoJson: GeoJsonFeature): Coordinate {
        let latSum = 0;
        let longSum = 0;
        let count = 0;
        geoJson.geometry.coordinates.forEach((coordinate) => {
            latSum += coordinate[1];
            longSum += coordinate[0];
            count++;
        });
        return new Coordinate([longSum / count, latSum / count]);
    }

    calculateCenterPointForMultiLineString(geoJson: GeoJsonFeature): Coordinate {
        let latSum = 0;
        let longSum = 0;
        let count = 0;
        geoJson.geometry.coordinates.forEach((line) => {
            line.forEach((coordinate) => {
                latSum += coordinate[1];
                longSum += coordinate[0];
                count++;
            });
        });
        return new Coordinate([longSum / count, latSum / count]);
    }

    calculateCenterPointForPolygon(geoJson: GeoJsonFeature): Coordinate {
        let latSum = 0;
        let longSum = 0;
        let count = 0;
        geoJson.geometry.coordinates.forEach((coordinate) => {
            for (let i = 0; i < coordinate.length - 1; i++) {
                latSum += coordinate[i][1];
                longSum += coordinate[i][0];
                count++;
            }
        });

        return new Coordinate([longSum / count, latSum / count]);
    }

    calculateCenterPointForMultiPolygon(geoJson: GeoJsonFeature): Coordinate {
        let latSum = 0;
        let longSum = 0;
        let count = 0;
        geoJson.geometry.coordinates.forEach((polygon) => {
            polygon.forEach((coordinate) => {
                for (let i = 0; i < coordinate.length - 1; i++) {
                    latSum += coordinate[i][1];
                    longSum += coordinate[i][0];
                    count++;
                }
            });
        });

        return new Coordinate([longSum / count, latSum / count]);
    }

    movePinToClosestPointOnMultiPoint(site: Site, centerPoint: Coordinate): void {
        let closestPoint = new Coordinate([centerPoint.lng, centerPoint.lat]);
        let minDistanceFound = Infinity;

        site.geoJson.geometry.coordinates.forEach(point => {
            const pointCoordinate = new Coordinate([point[1], point[0]]);
            const distanceToClostestPoint = this.calculateDistanceBetweenPoints(pointCoordinate, centerPoint);
            minDistanceFound = this.setClosestPointAndReturnMinDistance(minDistanceFound, closestPoint, distanceToClostestPoint, pointCoordinate);
        });

        this.setCenterLatLongForGeoJson(site, closestPoint);
    }

    movePinToClosestPointOnLine(site: Site, centerPoint: Coordinate): void {
        if (site.geoJson.geometry.type !== GeoJsonFeatureType.LineString) return;

        let closestPoint = new Coordinate([centerPoint.lng, centerPoint.lat]);
        let minDistanceFound = Infinity;

        for (let i = 0; i < site.geoJson.geometry.coordinates.length - 1; i++) {
            let closestPointOnLineSegment = new Coordinate([0, 0]);
            const point1 = new Coordinate(site.geoJson.geometry.coordinates[i]);
            const point2 = new Coordinate(site.geoJson.geometry.coordinates[i + 1]);

            let closestPointOnLineSegmentDistance = this.findClostestPointOnLineSegmentAndDistance(point1, point2, centerPoint, closestPointOnLineSegment);
            minDistanceFound = this.setClosestPointAndReturnMinDistance(minDistanceFound, closestPoint, closestPointOnLineSegmentDistance, closestPointOnLineSegment);
        }

        this.setCenterLatLongForGeoJson(site, closestPoint);
    }

    movePinToClosestPointOnClosestLine(site: Site, centerPoint: Coordinate): void {
        let closestPoint = new Coordinate([centerPoint.lng, centerPoint.lat]);
        let minDistanceFound = Infinity;

        site.geoJson.geometry.coordinates.forEach(line => {
            for (let i = 0; i < line.length - 1; i++) {
                let closestPointOnLineSegment = new Coordinate([0, 0]);
                const point1 = new Coordinate(line[i]);
                const point2 = new Coordinate(line[i + 1]);

                let closestPointOnLineSegmentDistance = this.findClostestPointOnLineSegmentAndDistance(point1, point2, centerPoint, closestPointOnLineSegment);
                minDistanceFound = this.setClosestPointAndReturnMinDistance(minDistanceFound, closestPoint, closestPointOnLineSegmentDistance, closestPointOnLineSegment);
            }
        });

        this.setCenterLatLongForGeoJson(site, closestPoint);
    }

    movePinToClosestPolygon(site: Site, centerPoint: Coordinate): void {
        let closestPoint = new Coordinate([centerPoint.lng, centerPoint.lat]);
        let minDistanceFound = Infinity;

        site.geoJson.geometry.coordinates.forEach((polygon) => {
            polygon = this.makeGeoJsonPolygonFeatureFromCoordinates(polygon);
            let polygonCenterPoint = this.calculateCenterPointForPolygon(polygon);
            const distanceToCurrentPolygon = this.calculateDistanceBetweenPoints(polygonCenterPoint, centerPoint);
            minDistanceFound = this.setClosestPointAndReturnMinDistance(minDistanceFound, closestPoint, distanceToCurrentPolygon, polygonCenterPoint);
        });

        this.setCenterLatLongForGeoJson(site, closestPoint);
    }

    findClostestPointOnLineSegmentAndDistance(point1: Coordinate, point2: Coordinate, centerPoint: Coordinate, closestSegmentPoint: Coordinate): number {
        const lineSegmentLongitudeDistance = point2.lng - point1.lng;
        const lineSegmentLatitudeDistance = point2.lat - point1.lat;
        const lineSegmentSquareDistance = lineSegmentLongitudeDistance * lineSegmentLongitudeDistance + lineSegmentLatitudeDistance * lineSegmentLatitudeDistance;
        const lambda = ((centerPoint.lng - point1.lng) * lineSegmentLongitudeDistance + (centerPoint.lat - point1.lat) * lineSegmentLatitudeDistance) / lineSegmentSquareDistance;

        if (this.isTheClosestPointBeforeLineSegment(lambda)) {
            closestSegmentPoint.lat = point1.lat;
            closestSegmentPoint.lng = point1.lng;
            return this.calculateDistanceBetweenPoints(centerPoint, closestSegmentPoint);
        }

        if (this.isTheClosestPointAfterLineSegment(lambda)) {
            closestSegmentPoint.lat = point2.lat;
            closestSegmentPoint.lng = point2.lng;
            return this.calculateDistanceBetweenPoints(centerPoint, closestSegmentPoint);
        }

        closestSegmentPoint.lng = point1.lng + lambda * lineSegmentLongitudeDistance;
        closestSegmentPoint.lat = point1.lat + lambda * lineSegmentLatitudeDistance;
        return this.calculateDistanceBetweenPoints(centerPoint, closestSegmentPoint);
    }

    setClosestPointAndReturnMinDistance(minDistanceFound: number, closestPoint: Coordinate, segmentDistance: number, closestSegmentPoint: Coordinate): number {
        if (segmentDistance >= minDistanceFound) return minDistanceFound;
        closestPoint.lat = closestSegmentPoint.lat;
        closestPoint.lng = closestSegmentPoint.lng;
        return segmentDistance;
    }

    calculateDistanceBetweenPoints(point1: Coordinate, point2: Coordinate): number {
        let radiusOfEarth = 6371e3;
        const lat1Radians = this.convertToRadians(point1.lat);
        const lat2Radians = this.convertToRadians(point2.lat);
        const latDifferenceRadians = this.convertToRadians(point2.lat - point1.lat);
        const longDifferenceRadians = this.convertToRadians(point2.lng - point1.lng);

        //Haversine formula for calculating distance between two points on a sphere
        //a = sin²(Δφ/2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ/2)
        //c = 2 ⋅ atan2( √a, √(1−a) )
        //d = R ⋅ c // where R is the radius of the Earth and d is the distance between the two points
        const a = Math.sin(latDifferenceRadians / 2) * Math.sin(latDifferenceRadians / 2) +
            Math.cos(lat1Radians) * Math.cos(lat2Radians) *
            Math.sin(longDifferenceRadians / 2) * Math.sin(longDifferenceRadians / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return radiusOfEarth * c;
    }

    isTheClosestPointBeforeLineSegment(lambda: number): boolean {
        return lambda < 0;
    }

    isTheClosestPointAfterLineSegment(lambda: number): boolean {
        return lambda > 1;
    }

    convertToRadians(degrees: number): number {
        return degrees * Math.PI / 180;
    }

    setCenterLatLongForGeoJson(site: Site, centerPoint: Coordinate): void {
        site.latitude = centerPoint.lat;
        site.longitude = centerPoint.lng;
    }

    makeGeoJsonPolygonFeatureFromCoordinates(coordinates: Position[][]): GeoJsonFeature {
        let feature = new GeoJsonFeature(new Polygon());
        feature.type = GeoJsonFeatureType.Polygon;
        feature.geometry.type = GeoJsonFeatureType.Polygon;
        feature.geometry.coordinates = coordinates;
        return feature;
    }

}
