import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { App, Project, Site } from '../../models';
import { AssociateSitesDataService } from '../data-services';
import { ErrorHandlerService } from '../tools/error-handler.service';

@Injectable({
    providedIn: 'root'
})
export class AssociateSitesLogicService {
    siteAssociated$: Subject<boolean> = new Subject<boolean>();
    sitesAssociated$: Subject<boolean> = new Subject<boolean>();

    associatedSite: Site = new Site();

    constructor(
        private _errorHandlerService: ErrorHandlerService,
        private _associateSitesDataService: AssociateSitesDataService
    ) { }

    associateAllSites(app: App, project: Project, sitesAlias: string): void {
        this._associateSitesDataService.associateAllSites(app, project)
            .subscribe({
                next: () => {
                    this.sitesAssociated$.next(true);
                },
                error: (error: Error) => {
                    this._errorHandlerService.showMessage(error, `There was an error associating all the ${sitesAlias.toLocaleLowerCase()} to the project.`);
                }
            });
    }

    associateSite(app: App, project: Project, site: Site, associate: boolean): void {
        this._associateSitesDataService.associateSite(app, project, site, associate)
            .subscribe({
                next: (updatedSite: Site) => {
                    this.setAssociatedSite(updatedSite);
                    this.siteAssociated$.next(true);
                },
                error: (error: Error) => {
                    this._errorHandlerService.showMessage(error, `There was an error associating or de-associating the record`);
                }
            });
    }

    setAssociatedSite(site: Site): void {
        this.associatedSite = site;
    }
}
