import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { App, Project, Site } from '../../models';
import { SiteColumnSettings } from '../../models/site-column-settings';
import { GridColumnProperties } from '../logic-services/models';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class SitesDataService extends HttpBaseService {

    private route = environment.api + 'sites/app/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    activateAllSites(currentApp: App): Observable<Site[]> {
        const url = `${this.route}${currentApp.id}/activate-all-sites`;
        return this.httpPatch(url, currentApp);
    }

    activateSite(currentApp: App, site: Site): Observable<Site> {
        const url = `${this.route}${currentApp.id}/activate`;
        return this.httpPatch(url, site);
    }

    getSingleSite(app: App, siteId: string): Observable<Site> {
        const url = `${this.route}${app.id}/site/${siteId}`;
        return this.httpGet(url);
    }

    getVisibleSites(app: App): Observable<Site[]> {
        const url = `${this.route}${app.id}/get-visible-sites`;
        return this.httpGet(url);
    }

    getSitesByApp(app: App): Observable<Site[]> {
        const url = `${this.route}${app.id}`;
        return this.httpGet(url);
    }

    createSite(app: App, project: Project, site: Site): Observable<Site> {
        const url = `${this.route}${app.id}/proj/${project.id}/create`;
        return this.httpPost(url, site);
    }

    updateSite(app: App, project: Project, site: Site): Observable<Site> {
        const url = `${this.route}${app.id}/${project.id}/update`;
        return this.httpPut(url, site);
    }

    deleteSite(app: App, site: Site): Observable<null> {
        const url = `${this.route}${app.id}/site/${site.id}/container/${app.fileContainers.private.name}/remove`;
        return this.httpDelete(url);
    }

    getLatLongByAddress(site: Site): Observable<any> {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(`${site.address},${site.city},${site.state},${site.zipCode}`)}&key=AIzaSyAwJWdBYQ_KHDMF1C_hmyVR5MBLbMRQMs4`;
        return this.httpGet(url);
    }

    updateSiteGridColumnWidth(currentApp: App, currentSite: Site, gridId: string, columnChanged: GridColumnProperties): Observable<SiteColumnSettings[]> {
        const url = `${this.route}${currentApp.id}/site/${currentSite.id}/${gridId}/update-column-width`;
        return this.httpPost(url, columnChanged);
    }

    getActiveSitesCountByAppId(id: string): Observable<number> {
        const url = `${this.route}${id}/active-sites-count`;
        return this.httpGet(url);
    }
}
