/* istanbul ignore file */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';
import { App, Project, Site } from '../../models';
import { RTSForm } from './../../models/rts-form';

@Injectable({
    providedIn: 'root'
})
export class RtsFormDataService extends HttpBaseService {

    private route = environment.api + 'rts-forms/app/';

    constructor(
        private _httpClient: HttpClient
    ) {
        super(_httpClient);
    }

    saveChanges(app: App, rtsForm: RTSForm): Observable<RTSForm> {
        const url = `${this.route}${app.id}`;
        return this.httpPost(url, rtsForm);
    }

    getRtsForm(app: App, project: Project, site: Site): Observable<RTSForm> {
        const url = `${this.route}${app.id}/project/${project.id}/site/${site.id}`;
        return this.httpGet(url);
    }

}
