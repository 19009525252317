import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { App, LicenseTier, Tiers } from '../../models';
import { LicenseTiers } from '../../shared/enums';
import { LicenseTiersDataService } from '../data-services';
import { LicenseTiersServiceModel } from './models';

@Injectable({
    providedIn: 'root'
})

export class LicenseTiersService {

    model: LicenseTiersServiceModel = new LicenseTiersServiceModel();

    constructor(
        private _licenseTierDataService: LicenseTiersDataService,
        private _snackBar: MatSnackBar
    ) { }

    getLicenseTiers(): void {
        this._licenseTierDataService.getLicenseTiers()
            .subscribe((data: Tiers) => {
                this.model.availableTiers = data.licenseTiers;
            });
    }

    licenseTierUpgradeRequest(upgradeRequest: FormData): void {
        this.model.isRequestPending = true;
        this._licenseTierDataService.licenseTierUpgradeRequest(upgradeRequest)
            .subscribe({
                next: () => {
                    this.model.isRequestPending = false;
                    this.model.isRequestSuccessful = true;
                },
                error: () => {
                    this._snackBar.open('An error occurred while processing your request. Please try again.', 'Close', { duration: 5000 });
                    this.model.isRequestPending = false;
                    this.model.isRequestSuccessful = false;
                }
            });
    }

    populateLicenseFromType(app: App): void {
        const selectedLicense = this.model.availableTiers.filter((tier: LicenseTier) => tier.id === app.activeLicenseTier.id)[0];
        if (app.activeLicenseTier.id === LicenseTiers.Other) {
            app.activeLicenseTier.pricePerSite = 0;
            app.activeLicenseTier.name = selectedLicense.name;

            return;
        }

        app.activeLicenseTier = selectedLicense;
    }

    setIsRequestPending(value: boolean): void {
        this.model.isRequestPending = value;
    }

    setIsRequestSuccessful(value: boolean): void {
        this.model.isRequestSuccessful = value;
    }
}
