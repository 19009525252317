import { Injectable } from '@angular/core';
import MarkerClusterer from '@google/markerclustererplus';
import { Subject } from 'rxjs';
import { GeoJsonFeature, Point, Position } from '../../models';
import { GeoJsonFeatureType } from '../../shared/enums';
import { LatLongMapDataService } from '../data-services/lat-long-map-data.service';
import { AddressComponentTypes, AddressComponentValues, AddressComponents, GoogleAddressResponse, LatLongMapLogServiceModel, SuggestedAddress } from './models';

@Injectable({
    providedIn: 'root'
})
export class LatLongMapLogicService {
    private _maxZoom = 15;

    private _obtainedCoordinatesFromAddress: Subject<boolean> = new Subject<boolean>();
    private _obtainedAddressFromCoordinates: Subject<boolean> = new Subject<boolean>();
    private _mapPinDragged: Subject<boolean> = new Subject<boolean>();

    obtainedCoordinatesFromAddress$ = this._obtainedCoordinatesFromAddress.asObservable();
    obtainedAddressFromCoordinates$ = this._obtainedAddressFromCoordinates.asObservable();
    mapPinDragged$ = this._mapPinDragged.asObservable();

    model: LatLongMapLogServiceModel;

    constructor(
        private _latLongMapDataService: LatLongMapDataService,
    ) {
        this.initModel();
    }

    private handleError(error: Error, methodName: string): void {
        const errorMessage = error.message ? error.message : JSON.stringify(error);
        throw new Error(`Error in ${methodName}: ${errorMessage}`);
    }

    initModel(): void {
        if (this.model === undefined) this.model = new LatLongMapLogServiceModel();
    }

    resetModel(): void {
        this.model = new LatLongMapLogServiceModel();
    }

    triggerUpdate(subject: Subject<boolean>): void {
        subject.next(true);
    }

    createMap(mapElement: HTMLElement): void {
        this.model.map = new google.maps.Map(mapElement, this.model.mapProperties);
        this.createMarkerCluster();
        this.setMapLoading(false);
        this.addMapResizeListener();
    }

    addMapResizeListener(): void {
        google.maps.event.addListener(this.model.map, 'resize', () => {
            this.model.map.fitBounds(this.model.latitudeLongitudeBounds);
        });
    }

    createMarkerCluster(): void {
        const map = this.model.map;
        const imagePath = this.model.markerClusterImagePath;
        this.model.markerCluster = new MarkerClusterer(map, [], { imagePath: imagePath });
    }

    setMapLoading(isLoading: boolean): void {
        this.model.mapLoading = isLoading;
    }

    placeMarkerOnMap(): void {
        this.clearMarkers();
        this.createMarker();
        this.addMarkerToMarkerCluster();
    }

    getCoordinatesFromAddress(address: string): void {
        this._latLongMapDataService.getCoordinatesFromAddress(address).subscribe({
            next: (response: GoogleAddressResponse) => {
                this.handleGetCoordinatesFromAddressResponse(response);
                this.triggerUpdate(this._obtainedCoordinatesFromAddress);
            },
            error: (error: Error) => {
                this.handleError(error, 'getCoordinatesFromAddress');
            },
        });
    }

    getAddressFromCoordinates(coordinates: google.maps.LatLng): void {
        this.setCurrentCoordinates(coordinates);
        this._latLongMapDataService.getAddressFromCoordinates(coordinates.lat(), coordinates.lng()).subscribe({
            next: (response: GoogleAddressResponse) => {
                this.handleGetAddressFromCoordinatesResponse(response);
                this.triggerUpdate(this._obtainedAddressFromCoordinates);
            },
            error: (error: Error) => {
                this.handleError(error, 'getAddressFromCoordinates');
            }
        });
    }

    handleGetAddressFromCoordinatesResponse(response: GoogleAddressResponse): void {
        if (!response?.results) return;

        if (response?.results[0]?.formatted_address)
            this.setSuggestedAddress(response.results[0].formatted_address);

        if (response?.results[0]?.address_components)
            this.getAndSetCityStateZipFromCoordinatesResponse(response.results[0].address_components);
    }

    handleGetCoordinatesFromAddressResponse(response: GoogleAddressResponse): void {
        if (!response?.results || !response?.results[0]?.geometry) {
            this.clearMarkers();
            return;
        }
        const location = response.results[0].geometry.location;
        const coordinates: google.maps.LatLng = this.createGoogleLatLong(location.lat, location.lng);
        this.setCurrentCoordinates(coordinates);

        this.handleGetAddressFromCoordinatesResponse(response);
    }

    setSuggestedAddress(address: string): void {
        const firstCommaIndex = address.indexOf(',');
        address = address.substring(0, firstCommaIndex);
        if (address.includes('+')) {
            const firstWordIndex = address.indexOf(' ') + 1;
            address = address.substring(firstWordIndex);
        }
        this.model.suggestedAddress.address = address;
    }

    getAndSetCityStateZipFromCoordinatesResponse(addressComponents: AddressComponents[]): void {
        const country = this.getAddressComponent(addressComponents, AddressComponentTypes.COUNTRY);
        const city = this.getAddressComponent(addressComponents, AddressComponentTypes.CITY);
        const state = (country === AddressComponentValues.UNITED_STATES) ? this.getAddressComponentShorthand(addressComponents, AddressComponentTypes.STATE) : this.getAddressComponent(addressComponents, AddressComponentTypes.STATE);
        const zip = this.getAddressComponent(addressComponents, AddressComponentTypes.ZIP_CODE);
        this.setSuggestedCityStateZipCountry(city, state, zip, country);
    }

    setSuggestedCityStateZipCountry(city: string, state: string, zipCode: string, country: string): void {
        this.model.suggestedAddress.city = city;
        this.model.suggestedAddress.state = state;
        this.model.suggestedAddress.zipCode = zipCode;
        this.model.suggestedAddress.country = country;
    }

    getAddressComponent(components: AddressComponents[], type: string): string {
        const component = components.find(c => c.types.includes(type));
        return component ? component.long_name : '';
    }

    getAddressComponentShorthand(components: AddressComponents[], type: string): string {
        const component = components.find(c => c.types.includes(type));
        return component ? component.short_name : '';
    }

    createGoogleLatLong(latitude: number, longitude: number): google.maps.LatLng {
        return new google.maps.LatLng(latitude, longitude);
    }

    setCurrentCoordinates(coordinates: google.maps.LatLng): void {
        this.model.currentCoordinates = coordinates;
    }

    createMarker(): void {
        if (!this.model.currentCoordinates) return;
        this.model.marker = new google.maps.Marker({
            position: this.model.currentCoordinates,
            map: this.model.map,
            draggable: true,
        });
        this.addMouseDragListener();
    }

    setMapZoomAndCenterForPin(): void {
        this.setMapCenter();
        this.setMapZoomForPin();
    }

    setMapZoomForPin(): void {
        this.model.map.setZoom(this._maxZoom);
    }

    setMapCenter(): void {
        this.model.map.setCenter(this.model.currentCoordinates);
    }

    addMarkerToMarkerCluster(): void {
        if (!this.model.marker) return;
        this.model.markerCluster.addMarker(this.model.marker);
    }

    clearMarkers(): void {
        if (this.model.markerCluster) this.model.markerCluster.clearMarkers();
        this.model.marker = undefined;
    }

    isValidBounds(latlng: google.maps.LatLng): boolean {
        return latlng && !isNaN(latlng.lat()) && !isNaN(latlng.lng());
    }

    addMouseDragListener(): void {
        google.maps.event.addListener(this.model.marker, 'dragend', () => {
            this.setCurrentCoordinates(this.model.marker.getPosition());
            this.triggerUpdate(this._mapPinDragged);
        });
    }

    loadGeoJsonFileOnLatLongMap(geoJsonFeature: GeoJsonFeature): void {
        this.model.map?.data.addGeoJson(geoJsonFeature);

        this.model.map?.data.setStyle(function (feature) {
            return {
                fillColor: feature.getProperty('fill') ?? feature.getProperty('fillColor'),
                fillOpacity: feature.getProperty('fill-opacity') ?? feature.getProperty('fillOpacity'),
                strokeColor: feature.getProperty('stroke') ?? feature.getProperty('strokeColor'),
                strokeOpacity: feature.getProperty('stroke-opacity') ?? feature.getProperty('strokeOpacity'),
                strokeWeight: feature.getProperty('stroke-width') ?? feature.getProperty('strokeWeight'),
                zIndex: feature.getProperty('zIndex') ?? feature.getProperty('layer'),
            };
        });

        this.createLatLongBounds(geoJsonFeature);
    }

    clearGeoJsonFileFromMap(): void {
        this.clearMarkers();
        this.clearSuggestedAddress();
        this.model.map?.data.forEach((feature) => {
            this.model.map.data.remove(feature);
        });
    }

    extendLatLongBounds(coordinate: Position): void {
        const latLng = this.createGoogleLatLong(coordinate[1], coordinate[0]);
        if (this.isValidBounds(latLng)) this.model.latitudeLongitudeBounds.extend(latLng);
    }

    createLatLongBounds(geoJsonFeature: GeoJsonFeature): void {
        this.model.latitudeLongitudeBounds = new google.maps.LatLngBounds();
        const geoType = geoJsonFeature.geometry.type;
        switch (geoType) {
            case GeoJsonFeatureType.Point:
                let geometry = geoJsonFeature.geometry as Point;
                this.extendLatLongBounds(geometry.coordinates);
                break;
            case GeoJsonFeatureType.MultiPoint:
            case GeoJsonFeatureType.LineString:
                geoJsonFeature.geometry.coordinates.forEach((coordinate) => {
                    this.extendLatLongBounds(coordinate);
                });
                break;
            case GeoJsonFeatureType.MultiLineString:
            case GeoJsonFeatureType.Polygon:
                geoJsonFeature.geometry.coordinates.forEach((coordinate) => {
                    coordinate.forEach((c) => {
                        this.extendLatLongBounds(c);
                    });
                });
                break;
            case GeoJsonFeatureType.MultiPolygon:
                geoJsonFeature.geometry.coordinates.forEach((coordinate) => {
                    coordinate.forEach((c) => {
                        c.forEach((cc) => {
                            this.extendLatLongBounds(cc);
                        });
                    });
                });
                break;
            default:
                break;
        }

        this.model.map.fitBounds(this.model.latitudeLongitudeBounds);
    }

    clearSuggestedAddress(): void {
        this.model.suggestedAddress = new SuggestedAddress();
    }
}
