import { MatDialogRef } from '@angular/material/dialog';

import { DropDownFilterSettings, VirtualizationSettings } from '@progress/kendo-angular-dropdowns';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { FileRestrictions } from '@progress/kendo-angular-upload';

import { BulkSiteUploadShapefileSaveSitesDialogComponent } from '../../../../components/dialogs/bulk-site-upload-dialog/shapefile/bulk-site-upload-shapefile-save-sites-loader-dialog/bulk-site-upload-shapefile-save-sites-dialog.component';
import { App, GeoJsonFile, KHFileInfo, Project, Site, WizardStep } from '../../../../models';
import { BulkSiteUploadShapefileActivateSitesStep, FileTypes } from '../../../../shared/enums';

/* istanbul ignore file */
export class BulkSiteUploadShapefileModel {
    stepper = new BulkSiteUploadStepperModel();
    sites: Site[];
    virtualizationSettings: VirtualizationSettings = { itemHeight: 32, pageSize: 25 };
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: "contains",
    };
    geoJsonFile: GeoJsonFile;
    chargeNumberValidities: { [key: string]: boolean; } = {};
    fileUploadStep: ShapefileInfoModel = new ShapefileInfoModel();
    matchingAttributesStep: MatchingAttributesModel = new MatchingAttributesModel();
    activateSitesStep: ActivateSitesModel = new ActivateSitesModel();
    reviewStep: ReviewStepModel = new ReviewStepModel();
    overrideSitesStep: OverrideSitesModel = new OverrideSitesModel();
    saveSitesStep: SaveSitesModel = new SaveSitesModel();
}

class BulkSiteUploadStepperModel {
    wizardSteps: WizardStep[] = [
        new WizardStep(`Upload File`, 'upload_2'),
        new WizardStep(`Match Attributes`, 'sync_alt'),
        new WizardStep(`Add details`, 'list_alt'),
        new WizardStep('Review & Submit', 'check')
    ];
    activateSitesStepId = 2;
    enterPriseActivateSitesStep = new WizardStep(`Activate Sites`, 'bolt');
}

export class ShapefileInfoModel {
    selectedShapefiles: KHFileInfo[] = [];
    shapefile: KHFileInfo;
    uploadRestrictions: FileRestrictions = {
        allowedExtensions: [FileTypes.Zip]
    };
    acceptedFileTypes: string[] = ['.' + FileTypes.Zip];
    isFetchingGeoJsonFile = false;
    showErrorMessage = false;
    guideMessage = 'Accepts: ZIP file containing at least the .shp, .shx, .dbf, and .prj file components of the shapefile.';
    errorMessage = 'Accepts: ZIP file containing at least the .shp, .shx, .dbf, and .prj file components of the shapefile.';
}

export class MatchingAttributesModel {
    siteNoProperty: string;
    siteNameProperty: string;
    siteChargeNoProperty: string;
    properties: string[] = [];
    commonProperties: string[] = [];
    commonPropertiesUniqueValues: string[] = [];
    firstSiteNoValue: string | undefined;
    firstSiteNameValue: string | undefined;
    firstSiteChargeNoValue: string | undefined;
    keysUniqueValue = new Map<string, Set<string>>();
    showErrorMessage = false;
}

export class ActivateSitesModel {
    isFetchingChargeNumberValiditites = false;
    activateSitesSubStep = BulkSiteUploadShapefileActivateSitesStep.ActivateSites;
    showErrorMessage = false;
    //used to determine which site was just selected, kept empty for the dropdown
    modeledSites: Site[] = [];
    selectedSites: Site[] = [];
    filteredSitesThatCanBeActivated: Site[] = [];
    sitesThatCanBeActivated: Site[] = [];
    selectAllSite: Site = new Site();
    selectAllSiteNo = '-1';
    selectAllMessage = `Select all`;
    activateSites: boolean = undefined;
    showCustomPlaceholder = true;
    sitesMissingChargeNumberAfterMatchingAttributes: Site[] = [];
    associateChargeNumberSubStep = new AssociateChargeNumberStep();
    isAssociateChargeNumbersStepApartOfTheUploadFlow = false;
}

export class ReviewStepModel {
    isEditing = false;
    siteNoBeingEdited: string | undefined = undefined;
    editingChargeNumberOriginalValue = '';
    intialSitesToFix: Site[] = [];
    sitesLeftToFix: Site[] = [];
    dataSource: GridDataResult;
    state: DataStateChangeEvent = {
        skip: 0,
        take: 100,
        filter: {
            logic: 'and',
            filters: []
        }
    };
}

class OverrideSitesModel {
    overriddenSites: Site[] = [];
    dataSource: GridDataResult;
    state: DataStateChangeEvent = {
        skip: 0,
        take: 100,
        filter: {
            logic: 'and',
            filters: []
        }
    };
}

class SaveSitesModel {
    percentComplete = 0;
    sentSites: Site[] = [];
    sitesBeingSent: Site[] = [];
    sitesToSend: Site[] = [];
    totalSitesToSend: number;
    currentApp: App;
    currentProject: Project;
    saveSitesDialogRef: MatDialogRef<BulkSiteUploadShapefileSaveSitesDialogComponent>;
    numberOfSitesToSaveAtOnce = 50;
    msBetweenCheckingSaves = 3500;
    areSitesBeingSaved = false;
}

export class AssociateChargeNumberStep {
    sitesStillMissingChargeNumbers: Site[] = [];
    associateChargeNumbers: AssociateChargeNumber[] = [];
}

export class AssociateChargeNumber {
    chargeNumber = '';
    isEditingChargeNumber = false;
    chargeNumberValid = false;
    chargeNumberValidating = false;
    selectAllSite: Site = new Site();
    //used to determine which site was just selected, kept empty for the dropdown
    modeledSites: Site[] = [];
    selectedSites: Site[] = [];
    filteredSites: Site[] = [];
    potentialSites: Site[] = [];
    showCustomPlaceholder = true;
}
