import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class RequestAccessDataService extends HttpBaseService {

    private route = environment.api + '';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    requestAccess(requestForm: unknown): Observable<any> {
        const url = `${this.route}requestExternal`;
        return this.httpPost(url, requestForm);
    }

}
