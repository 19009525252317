<h2
    mat-dialog-title
    class="mat-dialog-content"
>
    Potential data overwrite
</h2>
<mat-dialog-content>
    <p>
        The following ({{ data.length }}) {{ sitesAliasLowerCase }} listed in the Excel spreadsheet already exist in
        your SMARTS app. Uploading this data will overwrite existing details for these {{ sitesAliasLowerCase }},
        including {{ siteAliasLowerCase }} name, address, latitude, longitude, and charge number.
    </p>
    <p><strong>How would you like to proceed?</strong></p>

    <kendo-grid
        [data]="data"
        [height]="240"
        class="kh-grid-header-left-align"
    >
        <kendo-grid-column
            field="siteNo"
            [title]="siteNumberTitle"
        ></kendo-grid-column>
    </kendo-grid>
</mat-dialog-content>
<mat-dialog-actions
    align="end"
    class="mat-dialog-content"
>
    <app-secondary-button
        mat-dialog-close
        (onClick)="closeDialog()"
        >Cancel</app-secondary-button
    >
    <app-flat-button
        class="flat-button"
        (onClick)="onConfirm()"
        >Continue upload</app-flat-button
    >
</mat-dialog-actions>
