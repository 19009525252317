import { Color } from '@angular-material-components/color-picker';
import { Column } from '.';

/* istanbul ignore file */
export class Options {
    alignment?: string;
    allowedExtensions?: string[];
    arrows?: boolean;
    bold?: boolean;
    columns?: Column[];
    dateFormat?: string;
    dateSaveFormat?: string;
    displayColor?: Color;
    displayText?: string;
    endless?: boolean;
    format?: string;
    grid?: boolean;
    height?: string;
    italic?: boolean;
    maxFileSize?: number;
    multi?: boolean;
    options?: any;
    readOnly?: boolean;
    size?: string;
    toggle?: boolean;
}
