import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { App, User } from '../../models';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class AppsDataService extends HttpBaseService {
    private route = environment.api + 'apps/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    updateApp(app: App, formData: FormData): Observable<App> {
        const url = `${this.route}${app.id}/update`;
        return this.httpPost(url, formData);
    }

    createApp(app: App): Observable<App> {
        const url = `${this.route}createApp`;
        return this.httpPost(url, app);
    }

    getAppAdminsByAppId(app: App): Observable<User[]> {
        const url = `${this.route}${app.id}/admins`;
        return this.httpGet(url);
    }

    getRefreshToken(): Observable<any> {
        const url = `${this.route}user`;
        return this.httpGet(url);
    }

    loadPlatformApps(): Observable<App[]> {
        const url = `${this.route}user`;
        return this.httpGet(url);
    }

    loadApp(appUrl: string): Observable<App> {
        const url = `${this.route}url/${appUrl}`;
        return this.httpGet(url);
    }

    changeActive(app: App): Observable<App> {
        const url = `${this.route}${app.id}`;
        return this.httpPost(url, app);
    }

    removeHandler(app: App, user: User): Observable<User> {
        const url = `${this.route}${app.id}/user/${user.id}`;
        return this.httpDelete(url);
    }

    addAppAdmin(app: App, user: User): Observable<User> {
        const url = `${this.route}${app.id}/user/${user.id} `;
        return this.httpGet(url);
    }

    updateAppInformation(app: App): Observable<App> {
        const url = `${this.route}${app.id}/update-information`;
        return this.httpPatch(url, app);
    }

}
