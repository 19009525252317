import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private _snackBar: MatSnackBar) { }

  showMessage(error: any, message: string) {

    this._snackBar.open(message, 'Close', {
      duration: 5000,
    });
  }
}
