import { Component } from '@angular/core';

@Component({
    selector: 'kh-main-root',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent {

    constructor() { }
}
