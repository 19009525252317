/* istanbul ignore file */

import { Site } from './site';

export class MapPin {
    icon: MapIcon;
    latitude: number;
    longitude: number;
    label: string;
    isVisible: boolean;
    active: boolean;
    siteNo: string;
    id: string;

    constructor(icon: MapIcon, site: Site) {
        this.id = site.id;
        this.icon = icon;
        this.latitude = site.latitude;
        this.longitude = site.longitude;
        this.label = site.siteName;
        this.isVisible = site.showSite;
        this.active = site.active;
        this.siteNo = site.siteNo;
    }
}
export class MapIcon {
    url: string;
    scaledSize: google.maps.Size;

    constructor(url: string, scaledSize: google.maps.Size) {
        this.url = url;
        this.scaledSize = scaledSize;
    }
}
