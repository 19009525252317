import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { Field } from './logic-services/models';

@Injectable({
    providedIn: 'root'
})
export class FieldsService {
    private _fields: Field[] = [];
    private _repoFields: Field[] = [];
    fields: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
    repoFields: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
    fieldsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {
        let _fields = JSON.parse(sessionStorage.getItem('fields'));
        if (_fields) {
            this.setFields(_fields);
        }

        let _repoFields = JSON.parse(sessionStorage.getItem('repoFields'));
        if (_repoFields) {
            this.setFields(_repoFields);
        }
    }

    connect(appId = '') {
        if (appId) {
            this.getAppFields(appId);
        }
        else {
            this.getRepoFields();
        }
    }

    disconnect() {
        sessionStorage.removeItem('repoFields');
        this.repoFields.next(null);
    }

    setFields(fields: any[]) {
        sessionStorage.setItem('fields', JSON.stringify(fields));
        this._fields = fields;
        this.fields.next(fields);
    }

    setRepoFields(fields: any[]) {
        sessionStorage.setItem('repoFields', JSON.stringify(fields));
        this._repoFields = fields;
        this.repoFields.next(fields);
    }

    getFields() {
        this.fields.next(this._fields);
    }

    getRepoFields() {
        this.repoFields.next(this._repoFields);
    }

    addUpdateRepoFields(field: any) {
        if (this._repoFields.find(x => x.id === field.id)) {
            this._repoFields[this._repoFields.findIndex(x => x.id === field.id)] = field;
        }
        else {
            this._repoFields.push(field);
        }
        this.setRepoFields(this._repoFields);
    }

    getAppFields(appId: string) {
        return null;
    }

    getFieldList(): Field[] {
        return this._fields;
    }

    getField(id: string): Field {
        if (id) {
            return this._fields.find((field) => field.id === id);
        }
    }

    getRepoField(id: string) {
        if (id) {
            return this._repoFields.find(x => x.id === id);
        }
    }

    postField(appId: string, field: any) {
        return null;
    }

    deleteField(appId: string, fieldId: string) {
        return null;
    }
}
