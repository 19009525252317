/* istanbul ignore file */
export enum AppFormInputs {
    Name = 'name',
    Url = 'url',
    UserId = 'userId',
    ProjectsAlias = 'projectsAlias',
    ProjectAlias = 'projectAlias',
    SitesAlias = 'sitesAlias',
    SiteAlias = 'siteAlias',
    IsEnterprise = 'isEnterprise',
    LicenseTier = 'licenseTier',
    ChargeNumber = 'chargeNumber',
    OtherNumberOfSites = 'otherNumberOfSites',
    OtherAnnualLicenseFee = 'otherAnnualLicenseFee',
}
