import { Injectable } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Subject } from 'rxjs';
import { AdminTabs } from './enums/admin-tabs';

@Injectable({
    providedIn: 'root',
})
export class MatTabLogicService {
    adminTabIsClickedWithSubtab: Subject<boolean> = new Subject<boolean>();

    adminTabChange(event: MatTabChangeEvent): void {
        if (event.tab.textLabel === AdminTabs.Map || event.tab.textLabel === AdminTabs.DocumentTemplate) {
            this.adminTabIsClickedWithSubtab.next(true);
        }
    }
}
