import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { App, Project, Site } from '../../models';
import { DifferencesObject, Field } from '../logic-services/models';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class SiteDataDataService extends HttpBaseService {

    private route = environment.api + 'sitedata/app/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    updateSiteData(app: App, project: Project, site: Site, data: any): Observable<Site> {
        const url = `${this.route}${app.id}/project/${project.id}/site/${site.id}`;
        return this.httpPost(url, data);
    }

    updateSiteField(app: App, project: Project, site: Site, field: Field, data: any): Observable<any> {
        const url = `${this.route}${app.id}/project/${project.id}/site/${site.id}/field/${field.id}/update`;
        return this.httpPost(url, data);
    }

    updateSiteFields(app: App, project: Project, siteId: string, data: any): Observable<any> {
        const url = `${this.route}${app.id}/project/${project.id}/site/${siteId}/fields`;
        return this.httpPost(url, data);
    }

    patchField(app: App, project: Project, siteId: string, patch: DifferencesObject): Observable<Site> {
        const url = `${this.route}${app.id}/proj/${project.id}/site/${siteId}/patch-field`;
        return this.httpPatch(url, patch);
    }

    removeField(app: App, project: Project, siteId: string, patch: DifferencesObject): Observable<Site> {
        const url = `${this.route}${app.id}/proj/${project.id}/site/${siteId}/remove-field`;
        return this.httpPatch(url, patch);
    }

}
