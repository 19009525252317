import { FieldProperty } from '.';
import { FieldType, ReportFilterType } from '../../../shared/enums';

/* istanbul ignore file */
export class Field {
    Alias?: string;
    breadcrumb?: string; // alpha > bravo
    conditionalLogicIds?: string[];
    dataId?: string;
    enable?: boolean;
    FieldType?: FieldProperty;
    gridItemId?: string;
    hasConditionalLogic?: boolean;
    smartsheetField?: boolean;
    id: string;
    parentId?: string;
    isEditable?: boolean;
    isGroupBy?: boolean;
    Name: string;
    parent?: string; // ... > bravo
    parentGroup?: string;
    Path?: string;
    projectId?: string;
    repoId?: string;
    reportFilterType?: ReportFilterType;
    SelectOption?: FieldType;
    serializedId?: string;
    show?: boolean;
    showPermissions?: boolean;
    type?: string;
    value?: string;
}
