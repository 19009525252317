import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PhotoDialogService {

  constructor() { }

  public open: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public files: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public fileIndex: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private _open: boolean;
  private _files: any[];
  private _fileIndex: number;

  public setOpen(o: boolean) {
    this.open.next(o);
    this._open = o;
  }

  public getOpen() {
    return this._open;
  }

  public setFiles(f: any[]) {
    this.files.next(f);
    this._files = f;
  }

  public getFiles() {
    return this._files;
  }

  public setFileIndex(i: number) {
    this.fileIndex.next(i);
    this._fileIndex = i;
  }

  public getFileIndex() {
    return this._fileIndex;
  }

  public disconnect() {
    this.open.next(null);
    this.files.next(null);
    this.fileIndex.next(null);
    this._open = null;
    this._files = null;
    this._fileIndex = null;
  }
}
