import { FormGroup } from '@angular/forms';
import { Field } from './field';

export class DynamicLogicFormModel {

    logicSetForm: FormGroup;
    logicSetLogicSetTargetFieldOptions: Field[];
    fieldList: Array<Field>;
    fieldListSubsetOptions: Map<string, Array<Field>>;
    calculationOperatorsOptionsForEachLogicField: Array<string>;
    calculationFieldsOptionsForEachLogicField: Array<string>;
}
