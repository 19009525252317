/* istanbul ignore file */
export class ProjectNote {
    description: string;
    files: file[];
    id: string;
    name: string;
    projectId: string;
    text: string;
    type: string;
}

export class file {
    description: string;
    id: string;
    name: string;
    size: number;
    url: string;
}
