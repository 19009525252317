import { Project, RTSForm, User } from '../../../models';

/* istanbul ignore file */
export class ProjectsServiceModel {
    currentProject: Project;
    exportingLayout: boolean;
    isSaving: boolean;
    project: Project;
    projectAdmins: User[];
    projectLoading: boolean;
    projectPreview: Project;
    projects: Project[];
    adminProjects: Project[];
    savingOrPublishing: boolean;
    uploadingLayout: boolean;
    rtsForm: RTSForm;
}
