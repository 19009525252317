import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

import { FieldTypes } from '.';
import { DynamicLogicSet, LogicField } from '../../models';
import { ComparisonConjunctions, ComparisonOperators, DynamicLogicTypes, LogicFieldTargetTypes } from './enums/dynamic-logic';
import { CalculationOperators } from './enums/dynamic-logic/logic-field-calculation-operators';
import { DynamicLogicServiceModel, EvaluatedLogicSet, Field } from './models';

@Injectable({
    providedIn: 'root'
})
export class DynamicLogicService {
    logicSet: BehaviorSubject<DynamicLogicSet> = new BehaviorSubject<DynamicLogicSet>(null);
    setStyle: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    disableSetFields: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    setFieldValueLogicSetsReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    model: DynamicLogicServiceModel = new DynamicLogicServiceModel();

    constructor(
    ) { }

    setLogicSets(logicSets: DynamicLogicSet[], siteData: {}, fieldList: Field[]) {
        this.model.siteData = siteData;
        this.model.activeStyles = [];
        this.model.fieldList = fieldList;
        this.model.setFieldValueLogicSets = new Array<any>();
        this.model.fieldsWithLogicSets.forEach((value: string, key: string) => {
            this.model.fieldsWithLogicSets.set(key, "");
        });

        logicSets?.map((logicSet: DynamicLogicSet) => {
            if (logicSet.active) {
                this.parseLogic(logicSet);
                this.logicSet.next(logicSet);
            }
        });

        this.setFieldValueLogicSetsReady.next(true);
    }

    disconnectLogic(): void {
        if (this.model) {
            this.model.siteData = null;
        }
        this.logicSet.next(null);
        this.setStyle.next(null);
        this.setFieldValueLogicSetsReady.next(false);
    }

    isFieldAnActiveControlledField(fieldId: string): boolean {
        if (!this.model.fieldsWithLogicSets.has(fieldId)) {
            return false;
        }

        if (this.model.fieldsWithLogicSets.get(fieldId) === "") {
            return false;
        }

        return true;
    }

    isFieldAControlledField(fieldId: string): boolean {
        return this.model.fieldsWithLogicSets.has(fieldId);
    }

    private parseLogic(logicSet: DynamicLogicSet): void {
        if (logicSet.fields.length === 0) {
            return;
        }

        if (logicSet.option === DynamicLogicTypes.StyleField) {
            let nextStyle = this.parseConditionalLogic(logicSet);
            if (this.doesStyleLogicHaveConflict(nextStyle))
                return;
            if (nextStyle.status)
                this.model.activeStyles.push(nextStyle);
            this.setStyle.next(this.parseConditionalLogic(logicSet));
            return;
        }

        if (logicSet.option === DynamicLogicTypes.SetFieldValue) {
            this.model.setFieldValueLogicSets.push(this.parseConditionalLogic(logicSet));
        }
        else if (logicSet.option === DynamicLogicTypes.CalculateFieldValue) {
            this.model.setFieldValueLogicSets.push(this.parseCalculateValueLogic(logicSet));
        }
    }

    private doesStyleLogicHaveConflict(nextStyle): boolean {
        for (let i = 0; i < this.model.activeStyles.length; i++) {
            if (this.model.activeStyles[i].fieldId === nextStyle.fieldId && this.model.activeStyles[i].property === nextStyle.property) {
                return true;
            }
        }
        return false;
    }

    private parseConditionalLogic(logicSet: DynamicLogicSet): EvaluatedLogicSet {
        let logicStatus = false;
        let fieldStatus = [];

        logicSet.fields.forEach((field: LogicField) => {
            let status = this.getConditionalLogicStatus(field);

            fieldStatus.push({
                fieldId: field.id,
                status: status,
                conjunction: field.conjunction
            });
        });

        let evaluation = new String;

        for (let i = 0; i < fieldStatus.length; i++) {
            //Check if the current field is being conjoined from the previous field
            switch (fieldStatus[i].conjunction) {
                case ComparisonConjunctions.And:
                    evaluation += " && ";
                    break;
                case ComparisonConjunctions.Or:
                    evaluation += " || ";
                    break;
            }
            evaluation += fieldStatus[i].status;
        }

        logicStatus = new Function('return ' + evaluation)();

        if (logicSet.option === DynamicLogicTypes.SetFieldValue && !this.isFieldAnActiveControlledField(logicSet.fieldId) && logicSet.active) {
            this.model.fieldsWithLogicSets.set(logicSet.fieldId, logicStatus ? logicSet.id : "");
        }

        return {
            id: logicSet.id,
            fieldId: logicSet.fieldId,
            property: logicSet.property?.value,
            value: logicSet.value,
            status: logicStatus
        };
    }

    parseCalculateValueLogic(logicSet: DynamicLogicSet): EvaluatedLogicSet {
        if (!logicSet.fields[0].id) {
            return;
        }

        let value = this.getFieldValue(logicSet.fields[0].id);
        let valueType = this.model.fieldList.find((field) => field.id === logicSet.fields[0].id).FieldType.text;
        if (!valueType) {
            return;
        }
        if (value === undefined) {
            if (valueType === FieldTypes.DateSelect) {
                return;
            }
            value = 0;
        }

        for (let i = 0; i < logicSet.fields.length; i++) {
            let logicField = logicSet.fields[i];
            if (!logicField.operator) {
                continue;
            }

            let logicFieldValueType = null;
            switch (logicField.valueType) {
                case LogicFieldTargetTypes.Value:
                    logicFieldValueType = FieldTypes.Number;
                    break;
                case LogicFieldTargetTypes.Date:
                case LogicFieldTargetTypes.CurrentDay:
                    logicFieldValueType = FieldTypes.DateSelect;
                    break;
                case LogicFieldTargetTypes.Field:
                    if (logicSet.fields[i].targetId) {
                        logicFieldValueType =
                            logicFieldValueType = this.model.fieldList.find((field) => field.id === logicSet.fields[i].targetId).FieldType.text;
                    }
                    break;
                default:
                    return;
            }
            if (!logicFieldValueType) {
                continue;
            }

            let logicFieldValue = this.getLogicValueFromLogicField(logicField);
            if (logicFieldValue === undefined) {
                continue;
            }

            if (valueType === FieldTypes.DateSelect && logicFieldValueType === FieldTypes.Number) {
                if (logicField.operator.text === CalculationOperators.Addition) {
                    value = moment(value).add(logicFieldValue, 'days').format("YYYY-MM-DD");
                    continue;
                }
                value = moment(value).subtract(logicFieldValue, 'days').format("YYYY-MM-DD");
                continue;
            }
            if (valueType === FieldTypes.Number && logicFieldValueType === FieldTypes.DateSelect) {
                value = moment(logicFieldValue).add(value, 'days').format("YYYY-MM-DD");
                valueType = FieldTypes.DateSelect;
                continue;
            }
            if (valueType === FieldTypes.DateSelect && logicFieldValueType === FieldTypes.DateSelect) {
                value = moment(value).diff(logicFieldValue, 'days');
                valueType = FieldTypes.Number;
                continue;
            }

            switch (logicField.operator.text) {
                case CalculationOperators.Addition:
                    value += logicFieldValue;
                    break;
                case CalculationOperators.Subtraction:
                    value -= logicFieldValue;
                    break;
                case CalculationOperators.Multiplication:
                    value *= logicFieldValue;
                    break;
                case CalculationOperators.Division:
                    value /= logicFieldValue === 0 ? 1 : logicFieldValue;
            }
        }

        this.model.fieldsWithLogicSets.set(logicSet.fieldId, logicSet.id);

        return {
            id: logicSet.id,
            fieldId: logicSet.fieldId,
            value: value,
            status: true
        };
    }

    private getConditionalLogicStatus(field: LogicField): boolean {
        let initialFieldValue = this.getFieldValue(field.id);

        //No comparison for has a value
        if (field.operator.text === ComparisonOperators.HasValue) {
            return (initialFieldValue !== undefined && initialFieldValue !== '');
        }

        if (field.operator.text === ComparisonOperators.HasNoValue) {
            return (initialFieldValue === undefined || initialFieldValue === '');
        }

        //Get current field comparison value
        let compareValue = this.getLogicValueFromLogicField(field);

        if (compareValue === undefined || initialFieldValue === undefined || initialFieldValue === '') {
            return false;
        }

        if (moment.isMoment(compareValue) && moment(initialFieldValue, "YYYY-MM-DD", true).isValid()) {
            initialFieldValue = moment(initialFieldValue, "YYYY-MM-DD").utc().startOf('day');
            switch (field.operator.text) {
                case ComparisonOperators.Equals:
                    return (initialFieldValue.isSame(compareValue));
                case ComparisonOperators.NotEquals:
                    return (initialFieldValue.isSame(compareValue).not());
                case ComparisonOperators.LessThan:
                    return (initialFieldValue.isBefore(compareValue));
                case ComparisonOperators.LessThanEqualTo:
                    return (initialFieldValue.isSameOrBefore(compareValue));
                case ComparisonOperators.GreaterThan:
                    return (initialFieldValue.isAfter(compareValue));
                case ComparisonOperators.GreaterThanEqualTo:
                    return (initialFieldValue.isSameOrAfter(compareValue));
                default:
                    return false;
            }
        }

        //Check operation status of current field
        switch (field.operator.text) {
            case ComparisonOperators.Equals:
                return (initialFieldValue === compareValue);
            case ComparisonOperators.NotEquals:
                return (initialFieldValue !== compareValue);
            case ComparisonOperators.LessThan:
                return (initialFieldValue < compareValue);
            case ComparisonOperators.LessThanEqualTo:
                return (initialFieldValue <= compareValue);
            case ComparisonOperators.GreaterThan:
                return (initialFieldValue > compareValue);
            case ComparisonOperators.GreaterThanEqualTo:
                return (initialFieldValue >= compareValue);
            default:
                return false;
        }
    }

    private getLogicValueFromLogicField(field: LogicField) {
        if (field.valueType === "Value") {
            return field.value;
        }

        let value = undefined;
        switch (field.valueType) {
            case LogicFieldTargetTypes.Value:
                return field.value;
            case LogicFieldTargetTypes.Date:
                value = moment(field.date, "YYYY-MM-DD").utc().startOf('day');
                break;
            case LogicFieldTargetTypes.CurrentDay:
                value = moment().utc().startOf('day').format('YYYY-MM-DD');
                break;
            case LogicFieldTargetTypes.Field:
                value = this.getFieldValue(field.targetId);
                break;
            default:
                return undefined;
        }

        if (typeof value === "number" && field.addToValue) {
            return value += field.addToValue;
        }

        if (this.isLogicFieldADate(field)) {
            value = moment(value, "YYYY-MM-DD").utc().startOf('day');
            if (!field.addToValue) {
                return value;
            }
            if (field.addToValue > 0) {
                return value.add(field.addToValue, 'days');

            }
            return value.subtract(Math.abs(field.addToValue), 'days');
        }

        return value;
    }

    private getFieldValue(fieldId: string) {
        if (!this.model.siteData) {
            return undefined;
        }
        return this.model.siteData[fieldId];
    }

    isLogicFieldADate(logicField: LogicField): boolean {
        if (logicField.valueType === LogicFieldTargetTypes.Field) {
            const tempField = this.model.fieldList.find((field) => field.id === logicField.targetId);

            return (tempField?.FieldType?.text === FieldTypes.DateSelect);
        }

        return (logicField.valueType === LogicFieldTargetTypes.Date || logicField.valueType === LogicFieldTargetTypes.CurrentDay);
    }
}
