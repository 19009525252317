<h2 [ngStyle]="{ color: '#582c82' }" mat-dialog-title class="mx-3 px-1">Session timeout warning</h2>
<mat-dialog-content class="mat-typography">
  <div class=row>
    <div class=col-12>
      <p>Your session will expire in <countdown #cdTimer [config]="config"></countdown>! Would you like to stay logged in?</p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="row mx-2 px-1">
    <div class="col">
      <button mat-raised-button [ngStyle]="{ backgroundColor: '#353a40', color: 'white' }" (click)="extendSessionClick()" cdkFocusInitial>Stay Logged In</button>
    </div>
    <div class="col">
      <button mat-raised-button [ngStyle]="{ backgroundColor: '#fafafa', color: 'black' }" (click)="logoutClick()">Log Out</button>
    </div>
  </div>
</mat-dialog-actions>
