import { Field } from '../services/logic-services/models';

/* istanbul ignore file */
export class Role {
    description?: string;
    fields?: Field[];
    hasUsers?: boolean;
    id: string;
    name: string;
    projectId?: string;
    rules?: any;
    type?: string;
}
