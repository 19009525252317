import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';


@Injectable({
    providedIn: 'root',
})
export class LockedFieldsService {
    constructor() { }

    disableFieldIfSetFieldOrSmartsheetField(isFieldASetField: boolean, showPullArrow: boolean, form: FormControl): void {
        if (isFieldASetField || showPullArrow) {
            form.disable();
        }
    }

}
