import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { App, AppPropertiesAliasMap, AppPropertyAlias, LicenseTier, User } from '../../models';
import { AliasesDefaultValues, LicenseTiers } from '../../shared/enums';
import { AppFormInputs } from './enums';
import { RequestAppServiceModel } from './models';

@Injectable({
    providedIn: 'root'
})
export class RequestAppLogicService {
    model: RequestAppServiceModel;

    constructor(
        private _formBuilder: FormBuilder,
        private _snackBar: MatSnackBar
    ) { }

    initModel(): void {
        this.model = new RequestAppServiceModel();
        this.model.appForm = this._formBuilder.group({
            name: [null, [Validators.required]],
            url: [null, [Validators.required, Validators.pattern('^[a-z0-9-]*'), Validators.maxLength(this.model.characterCap)]],
            userId: [null, [Validators.required]],
            projectsAlias: [AliasesDefaultValues.PluralProject, [Validators.pattern('[a-zA-Z]*'), Validators.maxLength(this.model.characterCap)]],
            projectAlias: [AliasesDefaultValues.SingularProject, [Validators.pattern('[a-zA-Z]*'), Validators.maxLength(this.model.characterCap)]],
            sitesAlias: [AliasesDefaultValues.PluralSite, [Validators.pattern('[a-zA-Z]*'), Validators.maxLength(this.model.characterCap)]],
            siteAlias: [AliasesDefaultValues.SingularSite, [Validators.pattern('[a-zA-Z]*'), Validators.maxLength(this.model.characterCap)]],
            isEnterprise: [null, [Validators.required]],
            licenseTier: [null, [Validators.required]],
            chargeNumber: [null],
            otherNumberOfSites: [null],
            otherAnnualLicenseFee: [null],
        });
    }

    isAppFormValid(): boolean {
        if (this.model.appForm.value.userId == '' || this.model.appForm.value.name == '' || this.model.appForm.value.url == '' || this.model.appForm.invalid)
            return this.handleAppFormHasError();

        return true;
    }

    handleAppFormHasError(): boolean {
        this._snackBar.open(`Please fill in all the fields.`, "Close", { duration: 5000 });
        this.model.appForm.markAllAsTouched();
        return false;
    }

    setUsers(users: User[]): void {
        this.model.filteredUsers = users;
    }

    filterUsers(users: User[], value: string): void {
        this.model.filteredUsers = users.filter((user) => user['name'].toLowerCase().includes(value.toLowerCase()));
    }

    setUserId(userId: string): void {
        this.model.appForm.controls.userId.setValue(userId);
    }

    isValidCharacter(charCode: number): boolean {
        return (charCode >= 97 && charCode <= 122) || charCode === 45 || (charCode >= 48 && charCode <= 57);
    }

    doesAliasHavePatternError(aliasLocation: string): boolean {
        let field = this.model.appForm.get(aliasLocation);
        let errors = field.errors;

        if (errors?.pattern) return true;
        return false;
    }

    doesFieldHaveMaxLengthError(aliasLocation: string): boolean {
        let field = this.model.appForm.get(aliasLocation);
        let errors = field.errors;

        if (errors?.maxlength) return true;
        return false;
    }

    doesAliasHaveRequiredError(aliasLocation: string): boolean {
        let field = this.model.appForm.get(aliasLocation);
        let errors = field.errors;

        if (errors?.required) return true;
        return false;
    }

    getAppFromAppForm(appForm: FormGroup): App {
        let app = new App();
        this.poulateAppFromAppForm(app, appForm);
        return app;
    }

    getUpdatedValuesFromAppForm(appForm: FormGroup, app: App): App {
        this.poulateAppFromAppForm(app, appForm);
        return app;
    }

    poulateAppFromAppForm(app: App, appForm: FormGroup): void {
        app.name = appForm.value.name;
        app.url = appForm.value.url;
        app.userId = appForm.value.userId;
        app.chargeNumber = appForm.value.chargeNumber;
        app.activeLicenseTier = new LicenseTier();
        app.activeLicenseTier.id = appForm.value.licenseTier;
        if (appForm.value.licenseTier === LicenseTiers.Other)
            this.populateOtherLicenseTier(app);

        this.populateProjectAliasForApp(app, appForm);
        this.populateSiteAliasForApp(app, appForm);
    }

    populateOtherLicenseTier(app: App): void {
        app.activeLicenseTier.maxSitesAllowed = this.model.appForm.get(AppFormInputs.OtherNumberOfSites).value;
        app.activeLicenseTier.annualPrice = this.model.appForm.get(AppFormInputs.OtherAnnualLicenseFee).value;
    }

    populateProjectAliasForApp(app: App, appForm: FormGroup): void {
        if (!appForm.value.projectAlias || !appForm.value.projectsAlias)
            return;

        if (appForm.value.projectAlias?.length <= 0 || appForm.value.projectAlias?.length <= 0)
            return;

        if (app.aliases === undefined) app.aliases = new AppPropertiesAliasMap();
        app.aliases.project = new AppPropertyAlias();
        app.aliases.project.plural = appForm.value.projectsAlias;
        app.aliases.project.singular = appForm.value.projectAlias;
    }

    populateSiteAliasForApp(app: App, appForm: FormGroup): void {
        if (!appForm.value.sitesAlias || !appForm.value.siteAlias)
            return;

        if (appForm.value.sitesAlias?.length <= 0 || appForm.value.siteAlias?.length <= 0)
            return;

        app.aliases.site = new AppPropertyAlias();
        app.aliases.site.plural = appForm.value.sitesAlias;
        app.aliases.site.singular = appForm.value.siteAlias;
    }

    selectTier(value: number): void {
        if (this.model.appForm.get(AppFormInputs.LicenseTier).value !== LicenseTiers.Other && value === LicenseTiers.Other)
            this.otherFormValidators(true);

        this.model.appForm.get(AppFormInputs.LicenseTier).setValue(value);

        if (this.model.appForm.get(AppFormInputs.LicenseTier).value !== LicenseTiers.Other)
            this.otherFormValidators(false);
    }

    selectEnterpriseLicense(isEnterprise: boolean, appBeingEdited: App): void {
        this.enterpriseValidators(isEnterprise);

        if (isEnterprise && this.model.appForm.get(AppFormInputs.LicenseTier).value === LicenseTiers.PerSite)
            this.model.appForm.get(AppFormInputs.LicenseTier).reset();

        this.model.appForm.get(AppFormInputs.IsEnterprise).setValue(isEnterprise);

        if (!isEnterprise) {
            this.selectTier(LicenseTiers.PerSite);
            this.model.appForm.get(AppFormInputs.ChargeNumber).reset();
            this.model.appForm.get(AppFormInputs.ChargeNumber).clearValidators();
        }

        if (isEnterprise && appBeingEdited?.activeLicenseTier) {
            this.model.appForm.get(AppFormInputs.LicenseTier).setValue(appBeingEdited.activeLicenseTier.id === LicenseTiers.PerSite ? null : appBeingEdited?.activeLicenseTier.id);
            this.model.appForm.get(AppFormInputs.ChargeNumber).setValue(appBeingEdited?.chargeNumber ?? '');
            this.model.appForm.get(AppFormInputs.OtherNumberOfSites).setValue(appBeingEdited?.activeLicenseTier.id === LicenseTiers.Other ? appBeingEdited?.activeLicenseTier.maxSitesAllowed : null);
            this.model.appForm.get(AppFormInputs.OtherAnnualLicenseFee).setValue(appBeingEdited?.activeLicenseTier.id === LicenseTiers.Other ? appBeingEdited?.activeLicenseTier.annualPrice : null);
        }
    }

    otherFormValidators(isOtherLicense: boolean): void {
        if (isOtherLicense) {
            this.model.appForm.get(AppFormInputs.OtherNumberOfSites).setValidators([Validators.required]);
            this.model.appForm.get(AppFormInputs.OtherAnnualLicenseFee).setValidators([Validators.required]);
            return;
        }
        this.model.appForm.get(AppFormInputs.OtherNumberOfSites).clearValidators();
        this.model.appForm.get(AppFormInputs.OtherAnnualLicenseFee).clearValidators();
        this.model.appForm.get(AppFormInputs.OtherNumberOfSites).reset();
        this.model.appForm.get(AppFormInputs.OtherAnnualLicenseFee).reset();
    }

    enterpriseValidators(isEnterprise: boolean): void {
        if (isEnterprise) {
            this.model.appForm.get(AppFormInputs.ChargeNumber).setValidators([Validators.required, Validators.minLength(11)]);
            return;
        }

        this.model.appForm.get(AppFormInputs.ChargeNumber).clearValidators();
    }

    isLicenseSelected(): boolean {
        if (this.model.appForm.get(AppFormInputs.IsEnterprise)?.value === null &&
            this.model.appForm.get(AppFormInputs.LicenseTier)?.value === null &&
            this.model.appForm.get(AppFormInputs.IsEnterprise).touched)
            return false;

        return true;
    }

    loadExistingValues(appEditing: App): void {
        this.model.appForm.get(AppFormInputs.Name).setValue(appEditing.name);
        this.model.appForm.get(AppFormInputs.Url).setValue(appEditing.url);
        this.model.appForm.get(AppFormInputs.UserId).setValue(appEditing.userId);

        if (appEditing.aliases?.project) {
            this.model.appForm.get(AppFormInputs.ProjectsAlias).setValue(appEditing.aliases.project.plural);
            this.model.appForm.get(AppFormInputs.ProjectAlias).setValue(appEditing.aliases.project.singular);
        }
        if (appEditing.aliases?.site) {
            this.model.appForm.get(AppFormInputs.SitesAlias).setValue(appEditing.aliases.site.plural);
            this.model.appForm.get(AppFormInputs.SiteAlias).setValue(appEditing.aliases.site.singular);
        }

        this.model.appForm.get(AppFormInputs.IsEnterprise).setValue(appEditing.activeLicenseTier.id !== LicenseTiers.PerSite);

        this.enterpriseValidators(appEditing.activeLicenseTier.id !== LicenseTiers.PerSite);

        this.model.appForm.get(AppFormInputs.LicenseTier).setValue(appEditing.activeLicenseTier.id);
        this.model.appForm.get(AppFormInputs.ChargeNumber).setValue(appEditing.chargeNumber ?? '');
        this.model.appForm.get(AppFormInputs.OtherNumberOfSites).setValue(appEditing.activeLicenseTier.id === LicenseTiers.Other ? appEditing.activeLicenseTier.maxSitesAllowed : null);
        this.model.appForm.get(AppFormInputs.OtherAnnualLicenseFee).setValue(appEditing.activeLicenseTier.id === LicenseTiers.Other ? appEditing.activeLicenseTier.annualPrice : null);
        this.isCurrentAppInRenewalPeriod(appEditing);
    }

    disableLowerEnterpriseFields(appEditing: App, availableTiers: LicenseTier[]): void {
        const currentMaxSitesAllowed = appEditing.activeLicenseTier.maxSitesAllowed;
        const currentLicenseTier = appEditing.activeLicenseTier;

        availableTiers.forEach((tier) => {
            if (currentLicenseTier.id != tier.id && ((currentLicenseTier.id === LicenseTiers.PerSite && appEditing.activeSitesCount > tier.maxSitesAllowed)
                || (tier.maxSitesAllowed != 0 && tier.maxSitesAllowed < currentMaxSitesAllowed))) {
                this.model.disabledLicenseOptions.push(tier.id);
            }
        });
    }

    isLicenseOptionDisabled(licenseId: number): boolean {
        if (this.model.disabledLicenseOptions.includes(licenseId)) return true;

        return false;
    }

    isCurrentAppInRenewalPeriod(appEditing: App): void {
        let todaysDate: Date = new Date();
        let renewalDate: Date = new Date(appEditing.licenseExpirationDate);
        let timeInMilisec: number = renewalDate.getTime() - todaysDate.getTime();
        let daysBetweenDates: number = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));
        if (daysBetweenDates < 14) {
            this.model.isEditingAppInRenewalPeriod = true;
        }
        else {
            this.model.isEditingAppInRenewalPeriod = false;
        }
    }
}
