import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';
import { Tiers } from '../../models';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class LicenseTiersDataService extends HttpBaseService {

    private route = environment.api + 'license-tiers/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    getLicenseTiers(): Observable<Tiers> {
        const url = `${this.route}`;
        return this.httpGet(url);
    }

    licenseTierUpgradeRequest(upgradeRequest: FormData): Observable<void> {
        const url = `${this.route}license-tier-upgrade-request`;
        return this.httpPost(url, upgradeRequest);
    }
}
