import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

    logo: string = environment.url + 'assets/logo-kh-white.png';
    version: string = environment.version;
    year: string = new Date().getFullYear().toString();

    constructor() { }

}
