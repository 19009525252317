import { DropDownFilterSettings, VirtualizationSettings } from '@progress/kendo-angular-dropdowns';
import { Site } from '../../../models';

/* istanbul ignore file */
export class SitesComboboxModel {
    selectedSite: Site = null;
    virtualizationSettings: VirtualizationSettings = { itemHeight: 36, pageSize: 10 };
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: "contains",
    };
}
