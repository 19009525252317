import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';
import { App, DynamicLogicSet, Project } from '../../models';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class MapLogicDataService extends HttpBaseService {

    private route = environment.api + 'mapLogic/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    updateMapLogicWithFilter(app: App, mapLogic: DynamicLogicSet, filter: string[]): Observable<any> {
        const url = `${this.route}app/${app.id}/filter/${filter}`;
        return this.httpPost(url, mapLogic);
    }

    updateMapLogicWithoutFilter(app: App, mapLogic: DynamicLogicSet): Observable<any> {
        const url = `${this.route}app/${app.id}`;
        return this.httpPost(url, mapLogic);
    }

    getMapLogicByProjId(app: App, project: Project): Observable<any> {
        const url = `${this.route}app/${app.id}/proj/${project.id}`;
        return this.httpGet(url);
    }

    getAllRecordsByAppId(app: App): Observable<any> {
        const url = `${this.route}app/${app.id}`;
        return this.httpGet(url);
    }

    deleteItem(app: App, mapLogicId: any): Observable<any> {
        const url = `${this.route}app/${app.id}/record/${mapLogicId}`;
        return this.httpDelete(url);
    }

}
