import { Options } from '.';

/* istanbul ignore file */
export class FieldProperty {
    enum?: string[];
    grid?: boolean;
    id: string;
    menuDisplayText?: string;
    options?: Options;
    photos?: boolean;
    required?: boolean;
    text?: string;
    type?: string;
    upload?: boolean;
}
