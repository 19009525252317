import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { App, Project } from '../../models';
import { UsersImportDataService } from '../data-services/users-import-data.service';

@Injectable({
    providedIn: 'root'
})
export class UsersImportLogicService {

    constructor(
        private _userImportData: UsersImportDataService
    ) { }

    importUsers(app: App, project: Project, userImport: any): Observable<any> {
        const result$ = new Subject<any>();

        this._userImportData.importUsers(app, project, userImport)
            .subscribe({
                next: (response: any) => {
                    result$.next(response);
                    result$.complete();
                },
                error: (error: any) => {
                    result$.error(error);
                }
            });

        return result$.asObservable();
    }
}
