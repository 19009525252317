import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';
import { App, Notification, Project } from '../../models';
import { DifferencesObject, RequestInfo } from '../logic-services/models';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class NotificationDataService extends HttpBaseService {

    route = environment.api + 'notifications/';

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    getListOfActiveNotificationFieldIds(requestInfo: RequestInfo): Observable<string[]> {
        const url = `${this.route}app/${requestInfo.appId}/project/${requestInfo.projectId}/active-notification-field-ids`;
        return this.httpPost(url, requestInfo);
    }

    newAzureQueue(differences: DifferencesObject): Observable<any> {
        const url = `${this.route}app/${differences.appId}/project/${differences.projectId}/differences-object`;
        return this.httpPost(url, differences);
    }

    updateNotification(app: App, project: Project, notification: Notification): Observable<Notification> {
        const url = `${this.route}app/${app.id}/project/${project.id}`;
        return this.httpPost(url, notification);
    }

    getNotificationsByProjId(app: App, project: Project): Observable<Notification[]> {
        const url = `${this.route}app/${app.id}/project/${project.id}`;
        return this.httpGet(url);
    }

    deleteNotification(app: App, dataItem: Notification): Observable<any> {
        const url = `${this.route}app/${app.id}/${dataItem.id}/delete`;
        return this.httpGet(url);
    }

}
