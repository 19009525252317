import { Injectable } from '@angular/core';
import { Project } from '../../models';
import { Column, Field, Section } from './models';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbLogicService {

    private parent: string;
    private breadcrumbs: string[];

    constructor() { }

    createBreadcrumbs(project: Project): void {
        this.breadcrumbs = new Array<string>();

        project.JSONForms?.Sections?.forEach((section: Section) => {
            this.sectionObject(section);
        });
    }

    sectionsArray(sections: Section[]): void {
        sections.forEach((section: Section) => {
            this.parent = '';
            this.breadcrumbs.push(section.SectionTitle);

            if (section.Columns?.length)
                this.columnsArray(section.Columns);

            if (section.Sections?.length) {
                this.sectionsArray(section.Sections);
            }

            this.breadcrumbs.pop();
        });
    }

    sectionObject(section: Section): void {
        this.breadcrumbs.push(section.SectionTitle);

        if (section.Columns?.length)
            this.columnsArray(section.Columns);

        if (section.Sections?.length)
            this.sectionsArray(section.Sections);

        this.breadcrumbs.pop();
    }

    columnsArray(columns: Column[]): void {
        columns.forEach((column: Column, index: number) => {
            this.parent = (column.GroupTitle) ? column.GroupTitle : `Column ${index + 1}`;
            this.breadcrumbs.push(this.parent);
            this.columnObject(column);
            this.breadcrumbs.pop();
        });
    }

    columnObject(column: Column): void {
        if (column.Fields?.length)
            this.fieldsArray(column.Fields);
    }

    fieldsArray(fields: Field[]): void {
        fields.forEach((field: Field) => {
            this.fieldObject(field);
        });
    }

    fieldObject(field: Field): void {
        field.breadcrumb = this.buildBreadcrumbs();
        field.parent = `... > ${this.parent}`;
    }

    buildBreadcrumbs(): string {
        let crumbs = '';

        this.breadcrumbs.forEach((breadcrumb: string) => {
            crumbs += `${breadcrumb} > `;
        });

        return crumbs;
    }
}
