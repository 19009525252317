/* istanbul ignore file */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProjectInformationDataService extends HttpBaseService {

    route = environment.api + 'project-information';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    getChargeNumberValidities(chargeNumbers: string[]): Observable<{ [key: string]: boolean; }> {
        return this.httpPost(`${this.route}/validities`, chargeNumbers);
    }
}
