/* istanbul ignore file */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ShapefileDataService extends HttpBaseService {

    route = environment.api + 'shapefile';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    getSitesGeoJsonFile(formData: FormData): Observable<string> {
        return this.httpPost(`${this.route}/get-geojson-file`, formData);
    }
}
