import { Injectable } from '@angular/core';

import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicLogicSet, LogicField } from '../../models';
import { ComparisonOperators, LogicFieldTargetTypes } from './enums/dynamic-logic';
import { DynamicLogicTypes } from './enums/dynamic-logic/dynamic-logic-types.enum';
import { CalculationOperators } from './enums/dynamic-logic/logic-field-calculation-operators';
import { FieldTypes } from './enums/field-types';
import { FieldlistSubsets } from './enums/fieldlist-subsets.enum';
import { Field } from './models';
import { DynamicLogicFormModel } from './models/dynamic-logic-form-model';

@Injectable({
    providedIn: 'root',
})
export class DynamicLogicFormService {

    model: DynamicLogicFormModel;

    logicFieldValueTypes = [
        LogicFieldTargetTypes.Value,
        LogicFieldTargetTypes.Date,
        LogicFieldTargetTypes.CurrentDay,
        LogicFieldTargetTypes.Field
    ];
    logicFieldDateValueTypes = [
        LogicFieldTargetTypes.Date,
        LogicFieldTargetTypes.CurrentDay,
        LogicFieldTargetTypes.Field
    ];
    logicFieldStringAndNumberValueTypes = [
        LogicFieldTargetTypes.Value,
        LogicFieldTargetTypes.Field
    ];

    calculationLogicFieldOperators: Map<string, Array<{ text: string, value: string; }>> = new Map<string, Array<{ text: string, value: string; }>>();

    calculationLogicFieldNumericOperators: Array<{ text: string, value: string; }> = [
        {
            text: CalculationOperators.Addition,
            value: '+'
        },
        {
            text: CalculationOperators.Subtraction,
            value: '-'
        },
        {
            text: CalculationOperators.Multiplication,
            value: '*'
        },
        {
            text: CalculationOperators.Division,
            value: '/'
        }
    ];
    calculationLogicFieldDateOperators: Array<{ text: string, value: string; }> = [
        {
            text: CalculationOperators.Addition,
            value: '+'
        },
        {
            text: CalculationOperators.Subtraction,
            value: '-'
        },
    ];

    comparisonLogicFieldOperators: Map<string, Array<{ text: string, value: string; }>>;
    comparisonLogicFieldNumberAndDateOperators: Array<{ text: string, value: string; }> = [
        {
            text: ComparisonOperators.HasValue,
            value: '()'
        },
        {
            text: ComparisonOperators.HasNoValue,
            value: '!()'
        },
        {
            text: ComparisonOperators.Equals,
            value: '==='
        },
        {
            text: ComparisonOperators.NotEquals,
            value: '!=='
        },
        {
            text: ComparisonOperators.LessThan,
            value: '<'
        },
        {
            text: ComparisonOperators.LessThanEqualTo,
            value: '<='
        },
        {
            text: ComparisonOperators.GreaterThan,
            value: '>'
        },
        {
            text: ComparisonOperators.GreaterThanEqualTo,
            value: '>='
        }
    ];
    comparisonLogicFieldTextOperators: Array<{ text: string, value: string; }> = [
        {
            text: ComparisonOperators.HasValue,
            value: '()'
        },
        {
            text: ComparisonOperators.HasNoValue,
            value: '!()'
        }
    ];

    get logicFields(): FormArray {
        return this.model.logicSetForm.get('fields') as FormArray;
    }

    constructor(private fb: FormBuilder) { }

    initModel(): void {
        this.model = new DynamicLogicFormModel();
        this.model.logicSetLogicSetTargetFieldOptions = new Array<Field>();

        this.model.calculationOperatorsOptionsForEachLogicField = new Array<string>();
        this.model.calculationFieldsOptionsForEachLogicField = new Array<string>();

        this.calculationLogicFieldOperators = new Map<string, Array<any>>();
        this.calculationLogicFieldOperators.set(FieldTypes.DateSelect, this.calculationLogicFieldDateOperators);
        this.calculationLogicFieldOperators.set(FieldTypes.Number, this.calculationLogicFieldNumericOperators);

        this.comparisonLogicFieldOperators = new Map<string, Array<any>>();
        this.comparisonLogicFieldOperators.set(FieldTypes.DateSelect, this.comparisonLogicFieldNumberAndDateOperators);
        this.comparisonLogicFieldOperators.set(FieldTypes.Number, this.comparisonLogicFieldNumberAndDateOperators);
        this.comparisonLogicFieldOperators.set(FieldTypes.TextBox, this.comparisonLogicFieldTextOperators);

        this.model.fieldListSubsetOptions = new Map<string, Array<Field>>();
        this.setFieldLists(undefined);

        this.model.logicSetForm = this.fb.group({
            name: [null, [Validators.required]],
            option: [null, [Validators.required]],
            fields: this.fb.array([this.createLogicField()], Validators.required),
            fieldId: [null, [Validators.required]],
            property: [null],
            value: [null],
            active: [true]
        });
    }

    setFieldLists(fieldList: Array<Field>): void {
        this.model.fieldList = fieldList ? fieldList : new Array<Field>();
        this.setFieldListOnlyDates();
        this.setFieldListOnlyNumbers();
        this.setFieldListOnlyNumbersAndDates();
        this.setFieldListOnlyNumbersAndStrings();
    }


    loadLogicSet(logicSet: DynamicLogicSet): void {
        this.model.logicSetForm = this.fb.group({
            id: [logicSet.id],
            name: [logicSet.name, [Validators.required]],
            option: [logicSet.option, [Validators.required]],
            fields: this.fb.array(logicSet.fields.map(r => {
                return this.fb.group({
                    id: [r.id],
                    value: [r.value],
                    date: [new Date(r.date)],
                    addToValue: [r.addToValue],
                    valueType: [r.valueType],
                    operator: [r.operator, [Validators.required]],
                    targetId: [r.targetId],
                    conjunction: [r.conjunction]
                });
            }), Validators.required),
            fieldId: [logicSet.fieldId, [Validators.required]],
            property: [logicSet.property],
            value: [logicSet.value],
            active: [logicSet.active]
        });
        this.logicSetPatchForm();
    }

    logicSetPatchForm(): void {
        if (this.model.logicSetForm.value.option !== DynamicLogicTypes.CalculateFieldValue) {
            this.model.logicSetForm.value.fields.forEach((logicField, index) => {
                this.logicFieldsPatchConditionalForm(index);
            });
            return;
        }

        if (this.logicFields.value[0].id === null) {
            this.logicFieldsPatchCalculatedForm(0);
            return;
        }

        let validField = this.model.fieldList.find((field) => {
            return field.id === this.logicFields.value[0].id;
        });
        if (!validField) {
            this.logicFields.controls[0].patchValue({ id: null });
            this.logicSetOptionChange();
            return;
        }

        if (this.logicFields.value[0].id) {
            this.model.calculationOperatorsOptionsForEachLogicField[0] = this.getFieldTypeByFieldId(this.logicFields.value[0].id);
        }
        this.logicFieldsPatchCalculatedForm(0);
    }

    logicSetOptionChange(): void {
        this.logicFields.clear();
        this.logicFields.push(this.createLogicField());
        this.model.logicSetForm.patchValue({ fieldId: null, property: null, value: null });
        this.model.calculationOperatorsOptionsForEachLogicField = new Array<string>();
        this.model.calculationFieldsOptionsForEachLogicField = new Array<string>();
        this.setLogicSetTargetFieldOptions();
    }

    logicSetTargetFieldChange(): void {
        if (this.model.logicSetForm.value.option !== DynamicLogicTypes.StyleField) {
            this.model.logicSetForm.patchValue({ property: null, value: null });
        }
    }

    setLogicSetTargetFieldOptions(): void {
        let logicSetCalcType: number = this.model.logicSetForm.value.option;

        if (this.model.fieldList === undefined) {
            this.model.logicSetLogicSetTargetFieldOptions = [];
            return;
        }

        if (logicSetCalcType === DynamicLogicTypes.StyleField) {
            this.model.logicSetLogicSetTargetFieldOptions = this.model.fieldList;
            return;
        }

        if (logicSetCalcType === DynamicLogicTypes.SetFieldValue) {
            this.model.logicSetLogicSetTargetFieldOptions = this.model.fieldListSubsetOptions.get(FieldlistSubsets.NumbersAndStrings).filter((field) => {
                return this.fieldIsNotInSelectedFields(field.id);
            });
        }

        if (logicSetCalcType === DynamicLogicTypes.CalculateFieldValue) {
            if (this.model.calculationOperatorsOptionsForEachLogicField[this.model.calculationOperatorsOptionsForEachLogicField.length - 1] === FieldTypes.DateSelect) {
                this.model.logicSetLogicSetTargetFieldOptions = this.model.fieldListSubsetOptions.get(FieldlistSubsets.Dates).filter((field) => {
                    return this.fieldIsNotInSelectedFields(field.id);
                });
                return;
            };
            this.model.logicSetLogicSetTargetFieldOptions = this.model.fieldListSubsetOptions.get(FieldlistSubsets.Numbers).filter((field) => {
                return this.fieldIsNotInSelectedFields(field.id);
            });
            return;
        }
    }


    createLogicField(): FormGroup {
        return this.fb.group({
            id: [null],
            value: [null],
            date: [null],
            addToValue: [null],
            valueType: [null],
            operator: [null, [Validators.required]],
            targetId: [null],
            conjunction: [null]
        });
    }

    addLogicField(): void {
        this.logicFields.push(this.createLogicField());
    }

    removeLogicField(index): void {
        this.logicFields.removeAt(index);
        this.logicSetPatchForm();
    }


    logicFieldsPatchCalculatedForm(index: number): void {
        for (let i = index; i < this.logicFields.value.length; i++) {
            this.setNextLogicFieldCalculationOperator(index);
            this.setNextLogicFieldCalculationEvaluationFields(index);
            this.removeLogicFieldCalculationOperatorIfInvalid(index);
            this.removeLogicFieldCalculationValueTypeIfInvalid(index);
            this.removeLogicFieldCalculationEvaluationFieldIfInvalid(index);
        }
        this.setLogicSetTargetFieldOptions();
        this.removeTargetFieldIfContainedInLogicFields();
    }

    logicFieldsPatchConditionalForm(index: number): void {
        this.setNextLogicFieldConditionalOperator(index);
        this.setLogicSetTargetFieldOptions();
        if (this.model.logicSetForm.value.option === DynamicLogicTypes.SetFieldValue) {
            this.removeTargetFieldIfContainedInLogicFields();
        }

        if (this.logicFields.value[index].id === null) {
            return;
        }

        if (this.getLogicFieldConditionalEvaluationFieldValueTypes(index).includes(this.logicFields.value[index].valueType) === false) {
            this.logicFields.controls[index].patchValue({ valueType: null });
        }

        let validOperator = this.getConditionalLogicFieldOperators(index).find((operator) => {
            return operator.text === this.logicFields.value[index].operator?.text;
        });
        if (validOperator === undefined) {
            this.logicFields.controls[index].patchValue({ operator: null });
        }

        if (this.logicFields.value[index].id === null) {
            return;
        }

        let validField = this.model.fieldList.find((field) => {
            return field.id === this.logicFields.value[index].id;
        });
        if (!validField) {
            this.logicFields.controls[index].patchValue({ id: null });
        }

        if (this.logicFields.value[index].targetId === null) {
            return;
        }

        validField = this.getLogicFieldConditionalEvaluationFields(index).find((field) => {
            return field.id === this.logicFields.value[index].targetId;
        });
        if (!validField) {
            this.logicFields.controls[index].patchValue({ targetId: null });
        }
    }


    logicFieldInitialFieldChange(index: number): void {
        this.logicFields.value[index].value = null;
        this.model.logicSetForm.patchValue({ fields: this.model.logicSetForm.value.fields });
    }

    logicFieldCalculationInitialFieldChange(): void {
        this.logicFieldInitialFieldChange(0);
        this.model.calculationOperatorsOptionsForEachLogicField[0] = this.getFieldTypeByFieldId(this.logicFields.value[0].id);
        this.logicFieldsPatchCalculatedForm(0);
    }

    logicFieldConditionalInitialFieldChange(index: number): void {
        this.logicFieldInitialFieldChange(index);
        this.logicFieldsPatchConditionalForm(index);
    }

    logicFieldCalculationOperatorChange(index: number): void {
        this.logicFieldsPatchCalculatedForm(index);
    }

    logicFieldCalculationValueTypeChange(index: number): void {
        this.logicFields.value[index].value = null;
        this.logicFieldsPatchCalculatedForm(index);
    }

    logicFieldCalculationEvaluationFieldChange(index: number): void {
        this.logicFieldsPatchCalculatedForm(index);
    }

    logicFieldConditionalEvaluationFieldChange(): void {
        this.setLogicSetTargetFieldOptions();
    }


    getCalculationLogicFieldOperators(index: number): Array<{ text: string, value: string; }> {
        if (!this.model.calculationOperatorsOptionsForEachLogicField[index]) {
            return [];
        }
        return this.calculationLogicFieldOperators.get(this.model.calculationOperatorsOptionsForEachLogicField[index]);
    }

    getConditionalLogicFieldOperators(index: number): Array<{ text: string, value: string; }> {
        if (!this.logicFields.value[index]?.id) {
            return [];
        }

        let fieldType = this.getFieldTypeByFieldId(this.logicFields.value[index].id);
        if (!fieldType || this.comparisonLogicFieldOperators.has(fieldType) === false) {
            return [];
        }

        return this.comparisonLogicFieldOperators.get(fieldType);
    }

    getLogicFieldCalculationEvaluationFieldValueTypes(index: number): Array<string> {
        if (!this.model.calculationOperatorsOptionsForEachLogicField[index]) {
            return [];
        }
        if (this.model.calculationOperatorsOptionsForEachLogicField[index] === FieldTypes.DateSelect) {
            if (this.logicFields.value[index].operator?.text === CalculationOperators.Subtraction) {
                return this.logicFieldValueTypes;
            }
            return this.logicFieldStringAndNumberValueTypes;
        }
        if (this.logicFields.value[index].operator?.text === CalculationOperators.Addition) {
            return this.logicFieldValueTypes;
        }
        return this.logicFieldStringAndNumberValueTypes;
    }

    getLogicFieldConditionalEvaluationFieldValueTypes(index: number): Array<string> {
        if (this.logicFields.value[index].id === null) {
            return [];
        }
        if (this.getFieldTypeByFieldId(this.logicFields.value[index].id) === FieldTypes.DateSelect) {
            return this.logicFieldDateValueTypes;
        }
        return this.logicFieldStringAndNumberValueTypes;
    }

    getLogicFieldCalculationEvaluationFields(index: number): Field[] {
        if (!this.model.calculationFieldsOptionsForEachLogicField[index]) {
            return [];
        }
        return this.model.fieldListSubsetOptions.get(this.model.calculationFieldsOptionsForEachLogicField[index]);
    }

    getLogicFieldConditionalEvaluationFields(index: number): Field[] {
        if (!this.logicFields.value[index].id) {
            return [];
        }

        if (this.getFieldTypeByFieldId(this.logicFields.value[index].id) === FieldTypes.DateSelect) {
            return this.model.fieldListSubsetOptions.get(FieldlistSubsets.Dates);
        }

        return this.model.fieldListSubsetOptions.get(FieldlistSubsets.Numbers);
    }


    removeTargetFieldIfContainedInLogicFields(): void {
        if (this.fieldIsNotInSelectedFields(this.model.logicSetForm.value.fieldId)) {
            return;
        }
        this.model.logicSetForm.patchValue({ fieldId: null });
    }

    removeLogicFieldCalculationOperatorIfInvalid(index: number): void {
        if (this.logicFields.controls[index] === undefined) {
            return;
        }
        let validOperator = this.getCalculationLogicFieldOperators(index).find((operator) => {
            return operator.text === this.logicFields.value[index].operator?.text;
        });
        if (validOperator === undefined) {
            this.logicFields.controls[index].patchValue({ operator: null });
        }
    }

    removeLogicFieldCalculationValueTypeIfInvalid(index: number): void {
        if (this.logicFields.controls[index] === undefined) {
            return;
        }
        let validValueType = this.getLogicFieldCalculationEvaluationFieldValueTypes(index).find((valueType) => {
            return valueType === this.logicFields.value[index].valueType;
        });
        if (validValueType === undefined) {
            this.logicFields.controls[index].patchValue({ valueType: null });
        }
    }

    removeLogicFieldCalculationEvaluationFieldIfInvalid(index: number): void {
        if (this.logicFields.controls[index] === undefined) {
            return;
        }

        let validField = this.getLogicFieldCalculationEvaluationFields(index).find((field) => {
            return field.id === this.logicFields.value[index].targetId;
        });
        if (validField === undefined) {
            this.logicFields.controls[index].patchValue({ targetId: null });
        }
    }

    removeLogicFieldConditionalEvaluationFieldIfInvalid(index: number): void {
        if (this.logicFields.controls[index] === undefined) {
            return;
        }
        let validField = this.getLogicFieldConditionalEvaluationFields(index).find((field) => {
            return field.id === this.logicFields.value[index].targetId;
        });
        if (validField === undefined) {
            this.logicFields.controls[index].patchValue({ targetId: null });
        }
    }

    setNextLogicFieldCalculationEvaluationFields(index: number): void {
        let fieldtype = this.model.calculationOperatorsOptionsForEachLogicField[index];
        if (!fieldtype) {
            return;
        }

        let operator = this.logicFields.value[index].operator?.text;
        if (!operator) {
            return;
        }

        if (fieldtype === FieldTypes.DateSelect) {
            if (operator === CalculationOperators.Addition) {
                this.model.calculationFieldsOptionsForEachLogicField[index] = FieldlistSubsets.Numbers;
                return;
            }
            if (operator === CalculationOperators.Subtraction) {
                this.model.calculationFieldsOptionsForEachLogicField[index] = FieldlistSubsets.NumbersAndDates;
                return;
            }
            return;
        }

        if (fieldtype === FieldTypes.Number) {
            if (operator === CalculationOperators.Addition) {
                this.model.calculationFieldsOptionsForEachLogicField[index] = FieldlistSubsets.NumbersAndDates;
                return;
            }
            this.model.calculationFieldsOptionsForEachLogicField[index] = FieldlistSubsets.Numbers;
            return;
        }
    }

    setNextLogicFieldConditionalOperator(index: number): void {
        let logicField = this.logicFields.value[index];
        logicField.value = null;

        if (!logicField.id) {
            return;
        }

        let fieldType = this.getFieldTypeByFieldId(logicField.id);
        if (fieldType === FieldTypes.DateSelect) {
            this.model.calculationOperatorsOptionsForEachLogicField[index] = FieldTypes.Number;
            return;
        }
        this.model.calculationOperatorsOptionsForEachLogicField[index] = FieldTypes.DateSelect;
    }

    setNextLogicFieldCalculationOperator(index: number): void {
        let logicField = this.logicFields.value[index];
        logicField.value = null;

        if (logicField.valueType === LogicFieldTargetTypes.Field) {
            this.setNextLogicFieldCalculationOperatorAfterEvaluationFieldChange(index);
            return;
        }

        let previousOutput = this.model.calculationOperatorsOptionsForEachLogicField[index];
        if (logicField.valueType === LogicFieldTargetTypes.CurrentDay || logicField.valueType === LogicFieldTargetTypes.Date) {
            if (previousOutput === FieldTypes.DateSelect) {
                this.model.calculationOperatorsOptionsForEachLogicField[index + 1] = FieldTypes.Number;
                return;
            }
            this.model.calculationOperatorsOptionsForEachLogicField[index + 1] = FieldTypes.DateSelect;
            return;
        }

        if (logicField.valueType === LogicFieldTargetTypes.Value) {
            if (previousOutput === FieldTypes.DateSelect) {
                this.model.calculationOperatorsOptionsForEachLogicField[index + 1] = FieldTypes.DateSelect;
                return;
            }
            this.model.calculationOperatorsOptionsForEachLogicField[index + 1] = FieldTypes.Number;
            return;
        }
    }

    setNextLogicFieldCalculationOperatorAfterEvaluationFieldChange(index: number): void {
        if (this.logicFields.value[index].targetId === null) {
            return;
        }
        let fieldType = this.getFieldTypeByFieldId(this.logicFields.value[index].targetId);
        let previousOutput = this.model.calculationOperatorsOptionsForEachLogicField[index];
        if (previousOutput === FieldTypes.DateSelect) {
            if (fieldType === FieldTypes.DateSelect) {
                this.model.calculationOperatorsOptionsForEachLogicField[index + 1] = FieldTypes.Number;
                return;
            }
            this.model.calculationOperatorsOptionsForEachLogicField[index + 1] = FieldTypes.DateSelect;
            return;
        }

        if (previousOutput === FieldTypes.Number) {
            if (fieldType === FieldTypes.DateSelect) {
                this.model.calculationOperatorsOptionsForEachLogicField[index + 1] = FieldTypes.DateSelect;
                return;
            }
            this.model.calculationOperatorsOptionsForEachLogicField[index + 1] = FieldTypes.Number;
            return;
        }
    }

    isLogicSetConditional(): boolean {
        if (this.model.logicSetForm.value.option === DynamicLogicTypes.CalculateFieldValue) {
            return false;
        }
        return true;
    }

    fieldIsNotInSelectedFields(fieldId: string): boolean {
        for (let i = 0; i < this.logicFields.value.length; i++) {
            if (this.logicFields.value[i]?.id === fieldId || this.logicFields.value[i]?.targetId === fieldId) {
                return false;
            }
        }
        return true;
    }


    logicFieldConditionalShowAddToValue(index: number): boolean {
        let valueType = this.logicFields.controls[index].get('valueType').value;
        if (valueType === LogicFieldTargetTypes.Date || valueType === LogicFieldTargetTypes.CurrentDay) {
            return true;
        }
        let logicField: LogicField = this.logicFields.controls[0].value;
        if (valueType === LogicFieldTargetTypes.Field) {
            let initFieldType = logicField.id ? this.getFieldTypeByFieldId(logicField.id) : null;
            let targetFieldType = logicField.targetId ? this.getFieldTypeByFieldId(logicField.targetId) : null;
            return this.showAddToValueBasedOnFieldType(targetFieldType, initFieldType);
        }
        return false;
    }

    showAddToValueBasedOnFieldType(targetFieldType: string, initFieldType: string): boolean {
        return (
            (targetFieldType === FieldTypes.Number && initFieldType === FieldTypes.Number) ||
            (targetFieldType === FieldTypes.DateSelect && initFieldType === FieldTypes.DateSelect)
        );
    }

    getFieldTypeByFieldId(id: string): string {
        const field = this.model.fieldList.find(x => x.id == id);
        if (!field) {
            return undefined;
        }
        if (field.FieldType.text === FieldTypes.TextArea || field.FieldType.text === FieldTypes.DropDown) {
            return FieldTypes.TextBox;
        }
        return field.FieldType.text;
    }


    setFieldListOnlyDates(): void {
        this.model.fieldListSubsetOptions.set(FieldlistSubsets.Dates,
            this.model.fieldList.filter((field) => {
                return field.FieldType.text === FieldTypes.DateSelect;
            })
        );
    }

    setFieldListOnlyNumbers(): void {
        this.model.fieldListSubsetOptions.set(FieldlistSubsets.Numbers,
            this.model.fieldList.filter((field) => {
                return field.FieldType.text === FieldTypes.Number;
            })
        );
    }

    setFieldListOnlyNumbersAndDates(): void {
        this.model.fieldListSubsetOptions.set(FieldlistSubsets.NumbersAndDates,
            this.model.fieldList.filter((field) => {
                return field.FieldType.text === FieldTypes.Number || field.FieldType.text === FieldTypes.DateSelect;
            })
        );
    }

    setFieldListOnlyNumbersAndStrings(): void {
        this.model.fieldListSubsetOptions.set(FieldlistSubsets.NumbersAndStrings,
            this.model.fieldList.filter((field) => {
                return field.FieldType.text === FieldTypes.Number || field.FieldType.text === FieldTypes.TextBox || field.FieldType.text === FieldTypes.TextArea;
            })
        );
    }



}
