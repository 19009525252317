<div
    class="kh-form-header-text"
    mat-dialog-title
>
    Bulk Upload {{ siteAlias }}
</div>

<div
    mat-dialog-content
    class="kh-dialog-content"
    [style.--primary-app-color]="primaryColor"
>
    <div class="kh-text">
        <span class="kh-bold-text">{{ percentComplete }}%&nbsp;</span>
        <span>complete</span>
    </div>
    <mat-progress-bar mode="determinate" [value]="percentComplete"></mat-progress-bar>
</div>

<div
    mat-dialog-actions
    class="kh-dialog-controls-container"
>
    <div class="kh-dialog-controls">
        <div
            [style.--primary-app-color-fifty-opacity]="primaryColorFiftyOpacity"
            [style.--primary-app-color-seven-opacity]="primarySevenOpacity"
            [style.--primary-app-color]="primaryColor"
            class="kh-alert"
        >
            <div class="kh-text kh-bold-text">
                Please keep this window open while we upload your {{ sitesAliasLowerCase }}.
            </div>
            <div class="kh-text">
                This may take a few minutes.
            </div>
        </div>
    </div>
</div>
