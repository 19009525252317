/* istanbul ignore file */

import { GeoJsonFeature } from './geo-json-feature-model';
import { KHFileInfo } from './kh-file-info';
import { SiteColumnSettings } from './site-column-settings';

export class Site {
    active = false;
    activeIsLoading?: boolean;
    address = "";
    associated?: boolean;
    associationIsLoading?: boolean;
    chargeNumber: string | undefined = "";
    checked?: boolean;
    city = "";
    clientRequested?: boolean;
    cost?: string;
    columnSettings: SiteColumnSettings[];
    createdBy = "";
    pinColor?: string;
    pinId?: number;
    data?: { [key: string]: KHFileInfo[] | any | boolean | number | string; };
    dateCreated?: string;
    dropdownName?: string;
    id = "";
    isChargeNumberValid?: boolean;
    isNewRecord?: boolean;
    isSaving?: boolean;
    latitude: number;
    longitude: number;
    geoJson?: GeoJsonFeature | undefined;
    geoJsonFileName?: string | undefined;
    siteType?: number;
    projects?: string[];
    projId?: string;
    renewalProcessedOn?: string;
    renewCountDown?: number;
    renewDate?: string;
    renewSiteSubscription?: boolean;
    requestDetails?: object;
    saving?: boolean;
    showSite = true;
    showSiteIsLoading?: boolean;
    siteName = "";
    siteNo = "";
    state = "";
    type = "";
    zipCode = "";
}

export class AllSites {
    id = "All";
    siteName = "All";
}

