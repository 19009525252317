<div class="kh-footer row justify-content-between p-0 m-0">
    <div class="col-auto p-0">
        <img
            matTooltipClass="kh-mat-tooltip"
            matTooltipPosition="above"
            matTooltipHideDelay="1000"
            [src]="logo"
        />
    </div>
    <div class="col-auto p-0">
        <div class="kh-footer1">Copyright {{ year }}, All Rights Reserved.</div>
    </div>
</div>
