import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { App, Project } from '../models';
import { AvailableSmartsheet } from '../models/available-smartsheet';
import { AvailableSmartsheetColumn } from '../models/available-smartsheet-column';
import { Smartsheet } from '../models/smartsheet';
import { SmartsheetPushPullFields } from '../models/smartsheet-push-pull-fields';
import { SmartSheetDataService } from './data-services/smart-sheet-data.service';

@Injectable({
    providedIn: 'root'
})
export class SmartsheetsService {
    private _smartsheets: Smartsheet[] = [];
    private _availableSmartsheets: AvailableSmartsheet[] = [];
    private _availableSmartsheetColumns: AvailableSmartsheetColumn[] = [];
    private _smartsheetPushPullFields: SmartsheetPushPullFields = new SmartsheetPushPullFields();
    private allSmartsheetPushPullFields: SmartsheetPushPullFields[] = new Array<SmartsheetPushPullFields>();

    private _smartSheetsReady: Subject<boolean> = new Subject<boolean>();

    smartSheetsReady$ = this._smartSheetsReady.asObservable();

    smartsheets: BehaviorSubject<Smartsheet[]> = new BehaviorSubject<Smartsheet[]>(null);
    smartsheet: BehaviorSubject<Smartsheet> = new BehaviorSubject<Smartsheet>(null);
    newSmartsheet: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    smartsheetLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    availableSmartsheets: BehaviorSubject<AvailableSmartsheet[]> = new BehaviorSubject<AvailableSmartsheet[]>(null);
    availableSmartsheetsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    availableSmartsheetColumns: BehaviorSubject<AvailableSmartsheetColumn[]> = new BehaviorSubject<AvailableSmartsheetColumn[]>(null);
    availableSmartsheetColumnsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    currentSmartsheet: Smartsheet;
    currentAppSmartsheet: Smartsheet;

    smartsheetPushPullFields: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    constructor(
        private _snackBar: MatSnackBar,
        private _smartSheetDataService: SmartSheetDataService
    ) {
        if (sessionStorage.getItem("smartsheet")) {
            let smartsheet = JSON.parse(sessionStorage.getItem("smartsheet"));
            this.setSmartsheet(smartsheet);
        }
    }

    setSmartsheet(smartsheet: Smartsheet): void {
        this.setCurrentSmartsheet(smartsheet);
        sessionStorage.setItem("smartsheet", JSON.stringify(smartsheet));
    }

    private setCurrentSmartsheet(smartsheet: Smartsheet): void {
        this.smartsheet.next(smartsheet);
        this.currentSmartsheet = smartsheet;
    }

    resetSmartsheets(): void {
        this.smartsheets.next(this._smartsheets);
    }

    setSmartsheets(smartsheets: any): void {
        sessionStorage.setItem('smartsheets', JSON.stringify(smartsheets));
        this.smartsheets.next(smartsheets);
    }

    getPushArrowVisibilityForField(id: string): boolean {
        return this._smartsheetPushPullFields.pushFields.includes(id);
    }

    getPullArrowVisibilityForField(id: string): boolean {
        return this._smartsheetPushPullFields.pullFields.includes(id);
    }

    setSmartsheetFields(status: boolean): void {
        this.smartsheetPushPullFields.next(status);
    }

    updateSmartsheetConnection(app: App, project: Project, smartsheet: Smartsheet): Observable<any> {
        const result$ = new Subject<any>();

        this._smartSheetDataService.updateSmartsheetConnection(app, project, smartsheet)
            .subscribe({
                next: (response: any) => {
                    this.updateSmartsheetLocally(response);
                    result$.next(response);
                    result$.complete();
                }
            });

        return result$.asObservable();
    }

    removeSmartsheetFromProject(smartsheetId: string, projId: string, appId: string): Observable<any> {
        const result$ = new Subject<any>();

        const smartsheet = { smartsheetId: smartsheetId, projId: projId, appId: appId };

        this._smartSheetDataService.removeSmartsheetFromProject(smartsheet)
            .subscribe({
                next: (response: any) => {
                    result$.next(response);
                    result$.complete();
                }
            });

        return result$.asObservable();
    }

    getSmartsheetsByProjId(app: App, project: Project): void {
        this.smartsheetLoading.next(true);

        this._smartSheetDataService.getSmartsheetsByProjId(app, project)
            .subscribe({
                next: (response) => {
                    if (response) {
                        this._smartsheets = response;
                        this.setSmartsheets(response);
                    }
                },
                error: () => {
                    this._smartsheets = [];
                    this.disconnectSmartsheet();
                    this._snackBar.open(`Error getting smartsheets for the ${app.aliases.project.singular}`, '', {
                        duration: 2000,
                    });
                }
            }).add(() => {
                this.smartsheetLoading.next(false);
            });
    }

    getSmartsheetFieldsByProjId(app: App, project: Project): void {
        if (!app.showSmartsheets)
            return;

        const existingSmartsheetPushPullFields = this.allSmartsheetPushPullFields.filter((smartsheetPushPullFields: SmartsheetPushPullFields) => smartsheetPushPullFields.projectId === project);

        if (existingSmartsheetPushPullFields.length > 0) {
            this._smartsheetPushPullFields = existingSmartsheetPushPullFields[0];
            this.setSmartsheetFields(true);
            return;
        }

        this.smartsheetLoading.next(true);

        this._smartSheetDataService.getSmartSheetPushPullFields(app, project)
            .subscribe({
                next: ((smartsheetPushPullFields: SmartsheetPushPullFields) => {
                    smartsheetPushPullFields.projectId = project.id;
                    this.allSmartsheetPushPullFields.push(smartsheetPushPullFields);
                    this._smartsheetPushPullFields = smartsheetPushPullFields;
                    this.setSmartsheetFields(true);
                }),
                error: (() => {
                    this._smartsheets = [];
                    this.setSmartsheetFields(false);
                    this._snackBar.open(`Error getting smartsheets for the ${app.aliases.project.singular.toLocaleLowerCase()}`, `Close`, { duration: 2000, });
                }),
                complete: (() => { }),
            })
            .add(() => {
                this.smartsheetLoading.next(false);
            });
    }

    getSmartsheetsByAppId(app: App): void {
        this.smartsheetLoading.next(true);

        this._smartSheetDataService.getSmartsheetsByAppId(app)
            .subscribe({
                next: (response) => {
                    this._smartsheets = response;
                    this.setSmartsheets(response);
                },
                error: () => {
                    this._smartsheets = [];
                    this.disconnectSmartsheet();
                    this._snackBar.open('Error getting smartsheets for the app', '', {
                        duration: 2000,
                    });
                }
            }).add(() => {
                this.smartsheetLoading.next(false);
            });
    }

    updateSmartsheetLocally(smartsheet: Smartsheet): void {
        if (this._smartsheets.find(u => u.id === smartsheet.id)) {
            let index = this._smartsheets.findIndex(u => u.id === smartsheet.id);
            this._smartsheets[index] = smartsheet;
        }
        else {
            this._smartsheets.push(smartsheet);
        }
        this.resetSmartsheets();
    }

    disconnectSmartsheet(): void {
        this.setCurrentSmartsheet(null);
        sessionStorage.removeItem("smartsheet");
    }

    getAvailableSmartsheets(accessToken: string): Observable<any> {
        this.availableSmartsheetsLoading.next(true);

        const result$ = new Subject<any>();

        this._smartSheetDataService.getAvailableSmartsheets(accessToken)
            .subscribe({
                next: (response) => {
                    this._availableSmartsheets = response;
                    this.availableSmartsheets.next(response);
                    result$.next(response);
                    result$.complete();
                },
                error: (error: any) => {
                    this._availableSmartsheets = [];
                    this._snackBar.open('Error getting available smartsheets', '', {
                        duration: 2000,
                    });
                    result$.error(error);
                }
            })
            .add(() => {
                this.availableSmartsheetsLoading.next(false);
            });

        return result$.asObservable();
    }

    getAvailableSmartsheetColumns(sheetId: string, accessToken: string): void {
        this.availableSmartsheetColumnsLoading.next(true);

        this._smartSheetDataService.getAvailableSmartsheetColumns(sheetId, accessToken)
            .subscribe({
                next: (response) => {
                    response.sort((a, b) => {
                        const nameA = a.column_name.toUpperCase();
                        const nameB = b.column_name.toUpperCase();

                        if (nameA < nameB)
                            return -1;

                        if (nameA > nameB)
                            return 1;

                        return 0;
                    });

                    this._availableSmartsheetColumns = response;
                    this.availableSmartsheetColumns.next(response);
                },
                error: () => {
                    this._availableSmartsheetColumns = [];
                    this._snackBar.open('Error getting smartsheet columns', '', {
                        duration: 2000,
                    });
                }
            })
            .add(() => {
                this.availableSmartsheetColumnsLoading.next(false);
            });
    }

    pullSmartsheetData(app: App, project: Project, smartsheet: Smartsheet): Observable<any> {
        const result$ = new Subject<any>();

        this._smartSheetDataService.pullSmartsheetData(app, project, smartsheet)
            .subscribe({
                next: (response: any) => {
                    result$.next(response);
                    result$.complete();
                }
            });

        return result$.asObservable();
    }

    pullAllSmartsheetsData(app: App, project: Project, smartsheetList: Smartsheet[]): Observable<Smartsheet[]> {
        return this._smartSheetDataService.pullAllSmartsheetsData(app, project, smartsheetList);
    }

    pushSmartsheetData(app: App, project: Project, smartsheet: Smartsheet): Observable<any> {
        const result$ = new Subject<any>();

        this._smartSheetDataService.pushSmartsheetData(app, project, smartsheet)
            .subscribe({
                next: (response: any) => {
                    result$.next(response);
                    result$.complete();
                }
            });

        return result$.asObservable();
    }

    pushAllSmartsheetsData(app: App, project: Project, smartsheeList: Smartsheet[]): Observable<Smartsheet[]> {
        return this._smartSheetDataService.pushAllSmartsheetsData(app, project, smartsheeList);
    }

    updateAllSmartsheetsData(app: App, project: Project, smartsheeList: Smartsheet[]): Observable<Smartsheet[]> {
        return this._smartSheetDataService.updateAllSmartsheetsData(app, project, smartsheeList);
    }

    deleteSmartSheet(app: App, smartsheetId: string): Observable<any> {
        const result$ = new Subject<any>();

        this._smartSheetDataService.deleteSmartSheet(app, smartsheetId)
            .subscribe({
                next: (response: any) => {
                    result$.next(response);
                    result$.complete();
                },
                error: (error: any) => {
                    result$.error(error);
                }
            });

        return result$.asObservable();
    }

}
