import { Injectable } from '@angular/core';

import { Project, RTSForm } from '../../models';
import { FormElement, ObjectProperties, Properties, Schema, Section } from '../logic-services/models';

@Injectable({
    providedIn: 'root'
})
export class SkhemaUtilityService {

    private _rtsSectionIds: Set<string>;
    private _virtualUiSchema: Map<Project, FormElement>;
    private _virtualSchema: Map<Project, Schema>;
    private _virtualUiSchemaStateRtsForm = '';
    private _virtualUiSchemaStateProject = '';
    private _virtualSchemaStateRtsForm = '';
    private _virtualSchemaStateProject = '';

    constructor() { }

    init(): void {
        this._rtsSectionIds = new Set<string>();
        this._virtualUiSchema = new Map<Project, FormElement>();
        this._virtualSchema = new Map<Project, Schema>();
        this._virtualUiSchemaStateRtsForm = '';
        this._virtualUiSchemaStateProject = '';
        this._virtualSchemaStateRtsForm = '';
        this._virtualSchemaStateProject = '';
    }

    setRtsSectionIds(virtualSections: Section[]): void {
        virtualSections.forEach((section: Section) => {
            if (section?.enableDuplication && section?.parentId)
                this._rtsSectionIds.add(section.id);
        });
    }

    isRtsSection(uiSchema: FormElement): boolean {
        return this._rtsSectionIds?.has(uiSchema.id) ?? false;
    }

    virtualSectionsRts(project: Project, rtsForm: RTSForm): Section[] {
        const virtualSections = new Array<Section>();

        project.JSONForms?.Sections?.forEach((jsonFormSection: Section) => {
            if (jsonFormSection.enableDuplication) {
                const tempSeciton = rtsForm.RTSForms?.Sections?.find((rtsSection: Section) => rtsSection.parentId === jsonFormSection.id);
                virtualSections.push(tempSeciton);
            }

            if (!jsonFormSection.enableDuplication) {
                virtualSections.push(jsonFormSection);
            }
        });

        return virtualSections;
    }

    virtualSections(project: Project): Section[] {
        const virtualSections = new Array<Section>();

        project.JSONForms?.Sections?.forEach((jsonFormSection: Section) => {
            if (jsonFormSection.enableDuplication) {
                const tempSeciton = project.RTSForms?.Sections?.find((rtsSection: Section) => rtsSection.parentId === jsonFormSection.id);
                virtualSections.push(tempSeciton);
            }

            if (!jsonFormSection.enableDuplication) {
                virtualSections.push(jsonFormSection);
            }
        });

        return virtualSections;
    }

    virtualUiSchemaRts(virtualSections: Section[], project: Project, rtsForm: RTSForm): FormElement {
        if ((this._virtualUiSchemaStateRtsForm !== JSON.stringify(rtsForm)) || (this._virtualUiSchemaStateProject !== JSON.stringify(project)))
            this._virtualUiSchema.delete(project);

        if (this._virtualUiSchema.has(project))
            return this._virtualUiSchema.get(project);

        this._virtualUiSchemaStateRtsForm = JSON.stringify(rtsForm);
        this._virtualUiSchemaStateProject = JSON.stringify(project);

        const virtualUiSchema = new FormElement();
        virtualUiSchema.type = ObjectProperties.VerticalLayout;
        virtualUiSchema.elements = new Array<FormElement>();

        virtualSections.forEach((virtualSection: Section) => {
            if (virtualSection?.enableDuplication) {
                const tempUiSchema = rtsForm.RTSForms.UISchema.elements?.find((schema: FormElement) => schema.id === virtualSection?.id);
                virtualUiSchema.elements.push(tempUiSchema);
            }

            if (!virtualSection?.enableDuplication) {
                const tempUiSchema = project.JSONForms.UISchema.elements?.find((schema: FormElement) => schema.id === virtualSection?.id);
                virtualUiSchema.elements.push(tempUiSchema);
            }
        });

        this._virtualUiSchema.set(project, virtualUiSchema);

        return virtualUiSchema;
    }

    virtualUiSchema(virtualSections: Section[], project: Project): FormElement {
        if (this._virtualUiSchemaStateProject !== JSON.stringify(project))
            this._virtualUiSchema.delete(project);

        if (this._virtualUiSchema.has(project))
            return this._virtualUiSchema.get(project);

        this._virtualUiSchemaStateProject = JSON.stringify(project);

        const virtualUiSchema = new FormElement();
        virtualUiSchema.type = ObjectProperties.VerticalLayout;
        virtualUiSchema.elements = new Array<FormElement>();

        virtualSections.forEach((virtualSection: Section) => {
            if (virtualSection?.enableDuplication) {
                const tempUiSchema = project.RTSForms.UISchema.elements?.find((schema: FormElement) => schema.id === virtualSection?.id);
                virtualUiSchema.elements.push(tempUiSchema);
            }

            if (!virtualSection?.enableDuplication) {
                const tempUiSchema = project.JSONForms.UISchema.elements?.find((schema: FormElement) => schema.id === virtualSection?.id);
                virtualUiSchema.elements.push(tempUiSchema);
            }
        });

        this._virtualUiSchema.set(project, virtualUiSchema);

        return virtualUiSchema;
    }

    virtualSchemaRts(project: Project, rtsForm: RTSForm): Schema {
        if ((this._virtualSchemaStateRtsForm !== JSON.stringify(rtsForm)) || (this._virtualSchemaStateProject !== JSON.stringify(project)))
            this._virtualSchema.delete(project);

        if (this._virtualSchema.has(project))
            return this._virtualSchema.get(project);

        this._virtualSchemaStateRtsForm = JSON.stringify(rtsForm);
        this._virtualSchemaStateProject = JSON.stringify(project);

        const virtualSchema = new Schema();
        virtualSchema.properties = new Properties();
        virtualSchema.type = ObjectProperties.Object;

        for (const key in rtsForm?.RTSForms?.Schema?.properties)
            virtualSchema.properties[key] = rtsForm.RTSForms.Schema.properties[key];

        for (const key in project?.JSONForms?.Schema?.properties)
            virtualSchema.properties[key] = project.JSONForms.Schema.properties[key];

        this._virtualSchema.set(project, virtualSchema);

        return virtualSchema;
    }

    virtualSchema(project: Project): Schema {
        if (this._virtualSchemaStateProject !== JSON.stringify(project))
            this._virtualSchema.delete(project);

        if (this._virtualSchema.has(project))
            return this._virtualSchema.get(project);

        this._virtualSchemaStateProject = JSON.stringify(project);

        const virtualSchema = new Schema();
        virtualSchema.properties = new Properties();
        virtualSchema.type = ObjectProperties.Object;

        for (const key in project.RTSForms?.Schema?.properties)
            virtualSchema.properties[key] = project.RTSForms.Schema.properties[key];

        for (const key in project.JSONForms.Schema.properties)
            virtualSchema.properties[key] = project.JSONForms.Schema.properties[key];

        this._virtualSchema.set(project, virtualSchema);

        return virtualSchema;
    }

}
