<div
    class="kh-topbar"
    [style.background]="this.themeService.primaryColor"></div>
<mat-toolbar
    *ngIf="this.router.url !== '/home' && this.router.url !== '/login'; else homeLogin"
    class="p-0">
    <div class="row align-items-center justify-content-between m-0 px-3 w-100">
        <div class="align-content-start align-items-center d-flex flex-nowrap flex-row justify-content-between col p-0">
            <div
                [routerLink]="route"
                class="kh-toolbar-brand">
                <img
                    *ngIf="this.themeService.primaryLogo !== null; else appBrand"
                    [src]="this.themeService.primaryLogo"
                    class="kh-smarts-logo"
                    alt="App logo" />

                <ng-template #appBrand>
                    {{ this.themeService.name }}
                </ng-template>
            </div>
            <div class="row justify-content-end m-0">
                <div class="col-auto p-0">
                    <kh-primary-menu></kh-primary-menu>
                </div>
            </div>
        </div>
    </div>
</mat-toolbar>
<ng-template #homeLogin>
    <div class="row align-items-center justify-content-end m-0 w-100">
        <div class="col-auto p-0">
            <kh-primary-menu></kh-primary-menu>
        </div>
    </div>
</ng-template>
<kh-feedback-form *ngIf="this.model.showForm"></kh-feedback-form>
