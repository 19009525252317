import { Injectable } from '@angular/core';

import { RTSForm } from '../../models';
import { Column, Field, FormElement, ObjectProperties, Section, SmartsForm } from '../logic-services/models';

@Injectable({
    providedIn: 'root'
})
export class UISchemaBuilderUtility {

    constructor() { }

    buildRtsForm(rtsForm: RTSForm): void {
        this.build(rtsForm.RTSForms);
    }

    build(smartsForm: SmartsForm): void {
        const formElement = new FormElement();
        formElement.type = ObjectProperties.VerticalLayout;
        formElement.elements = new Array<FormElement>();

        smartsForm.Sections?.forEach((section: Section) => {
            formElement.elements?.push(this.sectionToFormElement(section));
        });

        smartsForm.UISchema = formElement;
    }

    sectionArrayToFormElementArray(sections: Section[]): FormElement[] {
        const formElements = new Array<FormElement>();

        sections.forEach((section: Section) => {
            if (section.Columns?.length)
                this.columnArrayToFormElement(section.Columns);

            formElements.push(this.sectionToFormElement(section));
        });

        return formElements;
    }

    sectionToFormElement(section: Section): FormElement {
        const formElement = new FormElement();

        formElement.type = ObjectProperties.Group;
        formElement.label = section.SectionTitle;
        formElement.id = section.id;
        formElement.elements = new Array<FormElement>();

        const innerElement = new FormElement();
        innerElement.elements = new Array<FormElement>();

        if (section.Columns?.length) {
            innerElement.type = ObjectProperties.HorizontalLayout;
            innerElement.elements = this.columnArrayToFormElement(section.Columns);
        }

        if (section.Sections?.length) {
            innerElement.type = ObjectProperties.VerticalLayout;
            innerElement.elements = this.sectionArrayToFormElementArray(section.Sections);
        }

        formElement.elements.push(innerElement);

        return formElement;
    }

    columnArrayToFormElement(columns: Column[]): FormElement[] {
        const formElements = new Array<FormElement>();

        columns.forEach((column: Column) => {
            formElements.push(this.columnToFormElement(column));
        });

        return formElements;
    }

    columnToFormElement(column: Column): FormElement {
        const formElement = new FormElement();

        if (column.type === ObjectProperties.GroupType) {
            formElement.type = ObjectProperties.Group;
            formElement.label = column.GroupTitle;
            formElement.elements = new Array<FormElement>();

            const nestedColumn = new Column();
            nestedColumn.type = ObjectProperties.ColumnType;
            nestedColumn.Fields = column.Fields;

            formElement.elements.push(this.columnToFormElement(nestedColumn));
        }
        else {
            formElement.type = ObjectProperties.VerticalLayout;
            if (column.Fields?.length)
                formElement.elements = this.fieldArrayToFormElementArray(column.Fields);
        }

        return formElement;
    }

    fieldArrayToFormElementArray(fields: Field[]): FormElement[] {
        const formElements = new Array<FormElement>();

        fields.forEach((field: Field) => {
            formElements.push(this.fieldToFormElement(field));
        });

        return formElements;
    }

    fieldToFormElement(field: Field): FormElement {
        const formElement = new FormElement();

        formElement.type = ObjectProperties.Control;
        formElement.scope = ObjectProperties.ScopeProperties + field.id;
        formElement.id = field.id;
        formElement.label = field.Name;

        if (field.FieldType?.options)
            formElement.options = field.FieldType?.options;

        return formElement;
    }

}
