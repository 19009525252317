import { Injectable } from '@angular/core';

import { AppsDataService } from './data-services';

@Injectable({
    providedIn: 'root'
})
export class RefreshTokenService {

    constructor(
        private _appsDataService: AppsDataService,
    ) { }

    getRefreshToken(): void {
        this._appsDataService.getRefreshToken()
            .subscribe(() => { });
    }

}
