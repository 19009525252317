/* istanbul ignore file */

import { SafeUrl } from '@angular/platform-browser';

import { FileInfo, FileState } from '@progress/kendo-angular-upload';
import { Subscription } from 'rxjs';

export class KHFileInfo implements FileInfo {
    extension?: string;
    name: string;
    size?: number;
    state?: FileState;
    uid?: string;
    rawFile?: File;
    validationErrors?: string[];
    httpSubscription?: Subscription;

    uniqueFileName?: string;
    uri?: string;
    url?: string;
    blob?: Blob;
    safeUrl?: SafeUrl;
    loading?: boolean;

    constructor(data?: Partial<KHFileInfo>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}

export class RequestedFiles {
    [uriKey: string]: KHFileInfo;
}
