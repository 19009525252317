import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelpFeedbackLogicService } from './../../services/logic-services/help-feedback-logic.service';

import { Subscription } from 'rxjs';

import { AppsService, KHThemeService } from '../../services';
import { HelpFeedbackServiceModel } from '../../services/help-feedback-service-model';

@Component({
    selector: 'kh-app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {

    private _appsSubscription: Subscription = new Subscription();

    route = '/home';

    get model(): HelpFeedbackServiceModel {
        return this._helpFeedbackService.model;
    }

    constructor(
        private _appsService: AppsService,
        public _helpFeedbackService: HelpFeedbackLogicService,
        public router: Router,
        public themeService: KHThemeService,
    ) { }

    ngOnInit(): void {
        this._appsSubscription = this._appsService.app.subscribe(app => {
            if (app) {
                this.route = `/app/${app.url}/home`;
            }
            else {
                this.route = 'platform/apps';
            }
        });

        this._helpFeedbackService.initModel();
    }

    ngOnDestroy(): void {
        this._appsSubscription.unsubscribe();
    }
}
