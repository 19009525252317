/* istanbul ignore file */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RequestAppAccessDataService extends HttpBaseService {

    private route = environment.api + 'request/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    requestNewAppAccess(name: string, appRequestForm: object): Observable<any> {
        const url = `${this.route}appRequest`;

        return this.httpPost(url, appRequestForm);
    }

}
