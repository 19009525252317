/* istanbul ignore file */

export enum KendoFileIcons {
    File = 'k-i-file',
    Word = 'k-i-file-word',
    Pdf = 'k-i-file-pdf',
    Excel = 'k-i-file-excel',
    Image = 'k-i-image',
    Zip = 'k-i-file-zip',
    Audio = 'k-i-file-audio',
    Video = 'k-i-file-video',
    Html = 'k-i-file-html',
    Css = 'k-i-file-css',
    Js = 'k-i-file-js',
}
