import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { App, Project, Smartsheet } from '../../models';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class SmartSheetDataService extends HttpBaseService {
    private route = environment.api + 'smartsheets/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    getSmartSheetPushPullFields(app: App, project: Project): Observable<any> {
        const url = `${this.route}smartsheet-fields/app/${app.id}/proj/${project.id}`;
        return this.httpGet(url);
    }

    pushAllSmartsheetsData(app: App, project: Project, smartsheeList: Smartsheet[]): Observable<Smartsheet[]> {
        const url = `${this.route}smartsheet-fields/app/push-all/${app.id}/proj/${project.id}`;
        return this.httpPost(url, smartsheeList);
    }

    pullAllSmartsheetsData(app: App, project: Project, smartsheeList: Smartsheet[]): Observable<Smartsheet[]> {
        const url = `${this.route}smartsheet-fields/app/pull-all/${app.id}/proj/${project.id}`;
        return this.httpPost(url, smartsheeList);
    }

    updateAllSmartsheetsData(app: App, project: Project, smartsheeList: Smartsheet[]): Observable<Smartsheet[]> {
        const url = `${this.route}smartsheet-fields/app/update-all/${app.id}/proj/${project.id}`;
        return this.httpPost(url, smartsheeList);
    }

    pushSmartsheetData(app: App, project: Project, smartsheet: Smartsheet): Observable<any> {
        const url = `${this.route}smartsheet-push/app/${app.id}/proj/${project.id}`;
        return this.httpPost(url, smartsheet);
    }

    pullSmartsheetData(app: App, project: Project, smartsheet: Smartsheet): Observable<any> {
        const url = `${this.route}smartsheet-pull/app/${app.id}/proj/${project.id}`;
        return this.httpPost(url, smartsheet);
    }

    getAvailableSmartsheetColumns(sheetId: string, accessToken: string): Observable<any> {
        const url = `${this.route}available-smartsheet-columns/smartsheet/${sheetId}/accessToken/${accessToken}`;
        return this.httpGet(url);
    }

    getAvailableSmartsheets(accessToken: string): Observable<any> {
        const url = `${this.route}available-smartsheets/accessToken/${accessToken}`;
        return this.httpGet(url);
    }

    getSmartsheetsByAppId(app: App): Observable<any> {
        const url = `${this.route}app/${app.id}`;
        return this.httpGet(url);
    }

    getSmartsheetsByProjId(app: App, project: Project): Observable<any> {
        const url = `${this.route}app/${app.id}/proj/${project.id}`;
        return this.httpGet(url);
    }

    removeSmartsheetFromProject(smartsheet: any): Observable<any> {
        const url = `${this.route}removeSmartsheetFromProject`;
        return this.httpPost(url, smartsheet);
    }

    updateSmartsheetConnection(app: App, project: Project, smartsheet: Smartsheet): Observable<any> {
        const url = `${this.route}smartsheet-connection/app/${app.id}/proj/${project.id}`;
        return this.httpPost(url, smartsheet);
    }

    deleteSmartSheet(app: App, smartsheetId: string): Observable<any> {
        const url = `${this.route}${app.id}/${smartsheetId}/delete`;
        return this.httpGet(url);
    }

}
