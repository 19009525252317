/* istanbul ignore file */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { App, Project } from '../../models';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class UsersImportDataService extends HttpBaseService {

    private route = environment.api + 'users/import/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    importUsers(app: App, project: Project, userImport: any): Observable<any> {
        const url = `${this.route}app/${app.id}/project/${project.id}`;
        return this.httpPost(url, userImport);
    }

}
