/* istanbul ignore file */
export class DocTemplate {
    active: boolean;
    createdBy: string;
    dateCreated?: string;
    docTemplateDescription: string;
    docTemplateFile?: any;
    docTemplateName: string;
    filePath: string;
    fileName: string;
    id: string;
    projectId: string;
}
