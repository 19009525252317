/* istanbul ignore file */
export enum SiteTypes {
    Control = 0,
    Point = 1,
    LineArea = 2,
}

export enum SiteTypeNames {
    Point = "Point",
    LineArea = "Line/Area",
}
