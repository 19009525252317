import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BehaviorSubject, Subject } from 'rxjs';

import { KHThemeService } from '.';
import { App, User } from '../models';
import { AppsDataService } from './data-services';

@Injectable({
    providedIn: 'root'
})
export class AppsService {
    private loadAppAdmins = new Subject<boolean>();
    private platformAppsLoaded = new Subject<boolean>();

    apps: App[] = [];
    currentApp: App;
    app: BehaviorSubject<App> = new BehaviorSubject<App>(null);
    appsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    newApp: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    appAdmins: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null);
    appAdminLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    loadingData = false;
    isSaving = false;
    loadAppAdmins$ = this.loadAppAdmins.asObservable();
    platformAppsLoaded$ = this.platformAppsLoaded.asObservable();

    constructor(
        private _snackBar: MatSnackBar,
        private _appsDataService: AppsDataService,
        private _themeService: KHThemeService,
    ) {
        const app = JSON.parse(sessionStorage.getItem('app'));

        if (app)
            this.setApp(app);
    }

    updateAppInformation(app: App): void {
        this.appsLoading.next(true);

        this._appsDataService.updateAppInformation(app)
            .subscribe({
                next: (app: App) => {
                    this.addUpdateApps(app);
                    this._snackBar.open(`App Updated`, `Close`, { duration: 5000 });
                },
                error: () => {
                    this._snackBar.open(`Unable to submit app request.`, `Close`, { duration: 5000 });
                }
            })
            .add(() => {
                this.appsLoading.next(false);
            });
    }

    createApp(app: App): void {
        this.appsLoading.next(true);

        this._appsDataService.createApp(app)
            .subscribe({
                next: (app: App) => {
                    this.addUpdateApps(app);
                    this._snackBar.open(`App Created`, `Close`, { duration: 5000 });
                },
                error: () => {
                    this._snackBar.open(`Unable to submit app request.`);
                }
            })
            .add(() => {
                this.appsLoading.next(false);
            });
    }

    addUpdateApps(app: App): void {
        if (this.apps.find((object: App) => object.id === app.id)) {
            const index = this.apps.findIndex((object: App) => object.id === app.id);
            this.apps[index] = app;
        }
        else {
            this.apps.push(app);
        }

        this.setApps(this.apps);
    }

    setApp(app: App): void {
        sessionStorage.setItem('app', JSON.stringify(app));
        this.currentApp = app;
        this.app.next(app);
    }

    getApp(): void {
        this.app.next(JSON.parse(sessionStorage.getItem('app')));
    }

    setApps(apps: App[]): void {
        this.apps = apps;
        this.appsLoading.next(false);
        this.platformAppsLoaded.next(true);
    }

    disconnectApp(): void {
        sessionStorage.removeItem('app');
        this.app.next(null);
    }

    getAppAdminsByAppId(app: App): void {
        this.appAdminLoading.next(true);

        this._appsDataService.getAppAdminsByAppId(app)
            .subscribe({
                next: (response: User[]) => {
                    this.appAdmins.next(response);
                },
                error: () => {
                    this._snackBar.open(`Error loading app admins`, `Close`, { duration: 5000 });
                }
            })
            .add(() => {
                this.appAdminLoading.next(false);
            });
    }

    loadPlatformApps(): void {
        this._appsDataService.loadPlatformApps()
            .subscribe({
                next: (response: App[]) => {
                    this.setApps(response);
                }
            });
    }

    loadApp(appUrl: string): void {
        this.loadingData = true;

        this._appsDataService.loadApp(appUrl)
            .subscribe({
                next: (result: App) => {
                    this.setApp(result);
                }
            })
            .add(() => {
                this.loadingData = false;
            });
    }

    updateApp(app: App, formData: FormData): void {
        this.loadingData = true;

        this._appsDataService.updateApp(app, formData)
            .subscribe((response: App) => {
                this._snackBar.open(`${response.name} has been saved`, `Close`, { duration: 3000 });
                this.setApp(response);
                this._themeService.setTheme(response);
            })
            .add(() => {
                this.loadingData = false;
            });
    }

    changeActive(app: App): void {
        this._appsDataService.changeActive(app)
            .subscribe((result: App) => {
                const status = (result.isActive) ? `activated` : `deactivated`;
                this._snackBar.open(`The app has been ${status}.`, `Close`, { duration: 5000 });
            });
    }

    removeHandler(app: App, user: User): void {
        this._appsDataService.removeHandler(app, user)
            .subscribe({
                next: () => {
                    this._snackBar.open(`User removed.`, `Close`, { duration: 5000 });
                    this.loadAppAdmins.next(true);
                }
            });
    }

    addAppAdmin(app: App, user: User): void {
        this._appsDataService.addAppAdmin(app, user)
            .subscribe({
                next: () => {
                    this._snackBar.open(`User added.`, "Close", { duration: 5000 });
                    this.loadAppAdmins.next(true);
                    this.isSaving = false;
                }
            });
    }

}
