import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Observable, Subject } from 'rxjs';

import { App, ExtensionGroups, KHFileInfo, RequestedFiles } from '../../models';
import { FileTypes, KendoFileIcons, PreviewableFileTypes } from '../../shared/enums';
import { FileDataService } from '../data-services';

@Injectable({
    providedIn: 'root'
})
export class FileLogicService {

    private _fileDownloaded = new Subject<KHFileInfo>();

    fileDownloaded$ = this._fileDownloaded.asObservable();

    requestedFiles: RequestedFiles = new RequestedFiles();

    constructor(
        private _domSanitizer: DomSanitizer,
        private _fileDataService: FileDataService
    ) { }

    getSrcOfFileId(fileId: string): SafeUrl | string {
        const file = this.requestedFiles[fileId];
        if (!file?.safeUrl)
            return '';

        const extensionGroup = this.getExtensionGroupOfFile(file);

        if (extensionGroup === ExtensionGroups.PDF)
            return file.safeUrl['changingThisBreaksApplicationSecurity'] ?? '';

        if (extensionGroup === ExtensionGroups.IMG)
            return file.safeUrl ?? '';

        return '';
    }

    getExtensionGroupOfFile(file: KHFileInfo): string {
        const extensionToCheck = this.getExtensionOfFileNameLowercaseWithoutDot(file.name);
        if (['jpg', 'jpeg', 'png', 'avif', 'gif', 'webp'].includes(extensionToCheck))
            return ExtensionGroups.IMG;
        if (['pdf'].includes(extensionToCheck))
            return ExtensionGroups.PDF;
        return ExtensionGroups.NOTVIEWABLE;
    }

    setFileProperties(data: KHFileInfo[]): void {
        data.forEach((datum: KHFileInfo) => {
            this.setProperties(datum);
        });
    }

    setProperties(data: KHFileInfo): void {
        // we want to avoid depending on uniqueFileName, and this should eventually be removed.
        // Ben wants to keep this functions references for now in case the back end is depending on file.uniqueFileName
        const uriArray = data.uri.split('/');
        data.uniqueFileName = uriArray[uriArray.length - 1];
    }

    getUniqueFileNameFromUri(uri: string): string {
        const uriArray = uri.split('/');
        return uriArray[uriArray.length - 1];
    }

    setPropertiesOfFileBasedOnBlob(file: KHFileInfo, blob: Blob): void {
        const dataType = blob.type;
        const binaryData = [];
        binaryData.push(blob);

        file.blob = new Blob(binaryData, { type: dataType });
        const url = window.URL.createObjectURL(file.blob);
        const securityBypassedUrl = this._domSanitizer.bypassSecurityTrustUrl(url);
        file.safeUrl = securityBypassedUrl;
        file.loading = false;
    }

    requestFile(app: App, oldFile: KHFileInfo): void {
        const requestFile = new KHFileInfo(oldFile);
        requestFile.loading = true;
        this.requestedFiles[requestFile.uri] = requestFile;

        this._fileDataService.downloadFileBlob(app, this.getUniqueFileNameFromUri(oldFile.uri))
            .subscribe((blob: Blob) => {
                this.setPropertiesOfFileBasedOnBlob(requestFile, blob);
                this._fileDownloaded.next(requestFile);
            });
    }

    getRequestedFile(uri: string): KHFileInfo {
        return this.requestedFiles[uri];
    }

    prepareFileForViewing(app: App, file: KHFileInfo): void {
        if (file.uri in this.requestedFiles)
            return;
        this.requestFile(app, file);
    }

    userDownloadFile(app: App, file: KHFileInfo): void {
        this.setProperties(file);

        if (file.uri in this.requestedFiles) {
            if (!file.loading)
                this.downloadLoadedFile(file.uri);

            return;
        }

        this.requestFile(app, file);

        this.fileDownloaded$
            .subscribe((requestedFile: KHFileInfo) => {
                if (file.uri === requestedFile.uri)
                    this.downloadLoadedFile(file.uri);
            });
    }

    downloadLoadedFile(uri: string): void {
        const fileToDownload = this.requestedFiles[uri];
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(fileToDownload.blob);
        downloadLink.download = fileToDownload.name;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
    }

    getFile(appId: App, data: KHFileInfo): Observable<Blob> {
        const fileBlob = new Subject<Blob>();

        this._fileDataService.downloadFileBlob(appId, this.getUniqueFileNameFromUri(data.uri))
            .subscribe((blob: Blob) => {
                fileBlob.next(blob);
            });

        return fileBlob.asObservable();
    }

    formatBytes(fileBytes: number): string {
        if (fileBytes === 0) return "File Corrupted";
        let decimalPoint = 2;
        let byteConversion = Math.floor(Math.log(fileBytes) / Math.log(1024));
        let byteType = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][byteConversion];
        let fileSize: number = parseFloat((fileBytes / Math.pow(1024, byteConversion)).toFixed(decimalPoint));
        return `${fileSize.toString() + ' ' + byteType}`;
    }

    niceFormatBytes(fileBytes: number): string {
        const byteTypes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let byteTypeIndex = 0;
        while (fileBytes >= 1024 && ++byteTypeIndex) {
            fileBytes = fileBytes / 1024;
        }
        return (fileBytes.toFixed(fileBytes < 10 && byteTypeIndex > 0 ? 1 : 0) + ' ' + byteTypes[byteTypeIndex]);
    }

    getExtensionOfFileNameLowercaseWithoutDot(fileName: string): string {
        const extension = fileName.substring(fileName.lastIndexOf('.'));
        return extension.toLowerCase().replace('.', '');
    }

    getFileIconFromFileInfo(file: KHFileInfo): string {
        return this.getFileIconFromExtension(this.getExtensionOfFileNameLowercaseWithoutDot(file.name));
    }

    getFileIconFromExtension(extension: string): string {
        if (!extension) return KendoFileIcons.File;
        extension = extension.toLowerCase().replace(".", "");

        switch (extension) {
            case FileTypes.Doc:
            case FileTypes.Docx:
                return KendoFileIcons.Word;
            case FileTypes.Pdf:
                return KendoFileIcons.Pdf;
            case FileTypes.Xls:
            case FileTypes.Xlsx:
                return KendoFileIcons.Excel;
            case FileTypes.Png:
            case FileTypes.Jpg:
            case FileTypes.Jpeg:
            case FileTypes.Gif:
            case FileTypes.Avif:
            case FileTypes.Webp:

                return KendoFileIcons.Image;
            case FileTypes.Zip:
            case FileTypes.Rar:
                return KendoFileIcons.Zip;
            case FileTypes.Mp3:
                return KendoFileIcons.Audio;
            case FileTypes.Mp4:
                return KendoFileIcons.Video;
            case FileTypes.Html:
                return KendoFileIcons.Html;
            case FileTypes.Css:
                return KendoFileIcons.Css;
            case FileTypes.Js:
                return KendoFileIcons.Js;
            default:
                return KendoFileIcons.File;
        }
    }

    isViewButtonAvailableForFile(file: KHFileInfo): boolean {
        return this.getExtensionGroupOfFile(file) !== ExtensionGroups.NOTVIEWABLE;
    }

    getFormattedFileCount(dataLength: number): string {
        switch (dataLength) {
            case 1:
                return '(1) file';
            default:
                return `(${dataLength}) files`;
        }
    }

    isFileTypePreviewable(file: KHFileInfo): boolean {
        if (!file?.name) return false;
        const extension = this.getExtensionOfFileNameLowercaseWithoutDot(file.name);
        return Object.values(PreviewableFileTypes).includes(extension as PreviewableFileTypes);
    }

    showMenuTogglesForFile(file: KHFileInfo, isEditable, isDownloadable, isPreviewable): boolean {
        let menuCount = 0;
        menuCount += isEditable ? 1 : 0;
        menuCount += isDownloadable ? 1 : 0;
        menuCount += isPreviewable && this.isFileTypePreviewable(file) ? 1 : 0;

        return menuCount > 1;
    }
}
