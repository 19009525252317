import { WizardStep } from '../../../models';

/* istanbul ignore file */
export class StepperModel {
    wizardSteps: WizardStep[] = [];
    currentWizardStep = 0;
    defaultNoIconLeftPadding = '0px';
    wizardIconsLeftPaddingMap = new Map<string, string>(
        [
            ['upload_2', '40px'],
        ]
    );
}
