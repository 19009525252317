import { Options } from '.';

/* istanbul ignore file */
export class FormElement {
    elements?: FormElement[];
    id?: string;
    label?: string;
    options?: Options;
    scope?: string;
    type?: string;
}
