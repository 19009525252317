import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { User } from '../../models';
import { TermsOfServiceDataService } from '../data-services/';

@Injectable({
    providedIn: 'root'
})
export class TermsOfServiceLogicService {

    termsOfServiceUpdated$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private _termsOfServiceDataService: TermsOfServiceDataService
    ) {
    }

    updateTermsOfService(userId: string, updatedUser: User): void {
        this._termsOfServiceDataService.updateTermsOfService(userId, updatedUser)
            .subscribe({
                next: () => {
                    this.termsOfServiceUpdated$.next(true);
                },
                error(error: Error) {
                    throw new Error(error.message);
                }
            });
    }
}
