import { Injectable } from '@angular/core';

import { difference, differenceBy, filter, find, xor } from 'lodash';

import { Field } from './models';

@Injectable({
    providedIn: 'root'
})
export class ReportDefinitionGridsLogicService {

    constructor() { }

    formContainsMultipleGrids(fields: Field[]): boolean {
        const gridDataIds = new Set<string>();

        fields.forEach((field: Field) => {
            if (field.dataId) {
                gridDataIds.add(field.dataId);
            }
        });

        return gridDataIds.size > 1;
    }

    removeGridFieldsFromAvailableFields(selectedFields: Field[], availableFields: Field[]): Field[] {
        selectedFields.forEach((field: Field) => {
            if (field.dataId) {
                const dataId = field.dataId;
                const tempAvailableFields = new Array<Field>();
                availableFields.forEach((field: Field) => {
                    if (!field.dataId || field.dataId === dataId)
                        tempAvailableFields.push(field);

                    availableFields = tempAvailableFields;
                });
            }
        });

        return availableFields;
    }

    returnGridFieldsToAvailableFields(selectedFields: Field[], allFields: Field[]): Field[] {
        if (selectedFields.length === 0)
            return allFields;

        const differenceFields: Field[] = difference(allFields, selectedFields, 'id');

        let selectedFieldsDataId: string;
        selectedFields.forEach((field: Field) => {
            if (field.dataId)
                selectedFieldsDataId = field.dataId;
        });

        if (selectedFieldsDataId === undefined)
            return differenceFields;

        const availableFields = new Array<Field>();
        differenceFields.forEach((field: Field) => {
            if (field.dataId === selectedFieldsDataId || field.dataId === undefined)
                availableFields.push(field);
        });

        return availableFields;
    }

    xored(selectedFields: Field[], allFields: Field[]): Field[] {
        if (selectedFields.length === 0)
            return allFields;

        const xored: Field[] = xor(allFields, selectedFields, 'Name');

        const gridField: Field = find(selectedFields, function (o: Field) {
            return o.dataId;
        });

        if (gridField !== undefined) {
            const hasSameDataIdOrIsDefaultField: Field[] = filter(xored, function (field: Field) {
                return (field.dataId === gridField.dataId || field.dataId === undefined) && (field.id !== gridField.id);
            });
            return hasSameDataIdOrIsDefaultField;
        }

        return xored;
    }

    removeDuplicateFields(selectedFields: Field[], availableFields: Field[]): Field[] {
        let fields: Field[] = differenceBy(availableFields, selectedFields, 'id');
        return fields;
    }
}
