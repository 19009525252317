/* istanbul ignore file */

import { Component, } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { GridDataResult } from '@progress/kendo-angular-grid';

import { Site } from '../../../../../models';
import { AppAliasService, BulkSiteUploadShapefileLogicService } from '../../../../../services/logic-services';

@Component({
    selector: 'kh-bulk-site-upload-shapefile-duplicate-sites-dialog',
    templateUrl: './bulk-site-upload-shapefile-duplicate-sites-dialog.component.html',
    styleUrls: ['./bulk-site-upload-shapefile-duplicate-sites-dialog.component.scss']
})
export class BulkSitesUploadShapefileDuplicateSitesDialogComponent {

    get sitesAliasLowerCase(): string { return this._appAliasService.sitesAliasLowerCase; }
    get siteAliasLowerCase(): string { return this._appAliasService.siteAliasLowerCase; }
    get siteAlias(): string { return this._appAliasService.siteAlias; }

    get theseVsThis(): string { return this.overriddenSites.length > 1 ? 'these' : 'this'; }
    get siteVsSites(): string { return this.overriddenSites.length > 1 ? this.sitesAliasLowerCase : this.siteAliasLowerCase; }

    get siteNumberTitle(): string { return `${this.siteAlias} number`; }

    get overriddenSites(): Site[] { return this._bulkSiteUploadShapefileLogicService.model.overrideSitesStep.overriddenSites; }
    get dataSource(): GridDataResult { return this._bulkSiteUploadShapefileLogicService.model.overrideSitesStep.dataSource; }

    constructor(
        private _dialogRef: MatDialogRef<BulkSitesUploadShapefileDuplicateSitesDialogComponent>,
        private _appAliasService: AppAliasService,
        private _bulkSiteUploadShapefileLogicService: BulkSiteUploadShapefileLogicService,
    ) { }

    closeDialog(): void {
        this._dialogRef.close(false);
    }

    onConfirm(): void {
        this._dialogRef.close(true);
    }
}
