import { DynamicLogicSet, Role } from '.';
import { SmartsForm } from '../services/logic-services/models';

/* istanbul ignore file */
export class Project {
    active?: boolean;
    conditionalLogic?: DynamicLogicSet[];
    createdBy?: string;
    dateCreated?: string;
    defaultRole?: string;
    description?: string;
    id?: string;
    isProjectAdmin?: boolean;
    JSONForms?: SmartsForm;
    layoutDraft?: SmartsForm;
    RTSForms?: SmartsForm;
    name?: string;
    projectId?: string;
    roleId?: Role | string;
    roles?: Role[];
    isSaving?: boolean;
}
