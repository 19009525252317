import { Injectable } from '@angular/core';

import { WizardStep } from '../../models';
import { StepperModel } from './models';

@Injectable({
    providedIn: 'root'
})
export class StepperLogicService {

    model: StepperModel;

    constructor() { }

    initModel(wizardSteps: WizardStep[]): void {
        this.model = new StepperModel();
        this.model.wizardSteps = [...wizardSteps];
    }

    setWizardStep(step: number): void {
        if (step < 0 || step >= this.model.wizardSteps.length)
            return;
        this.model.currentWizardStep = step;
    }

    nextStep(): void {
        this.setWizardStep(this.model.currentWizardStep + 1);
    }

    previousStep(): void {
        this.setWizardStep(this.model.currentWizardStep - 1);
    }

    getWizardIconLeftPadding(step: WizardStep): string {
        if (!this.model.wizardIconsLeftPaddingMap.has(step.icon))
            return this.model.defaultNoIconLeftPadding;

        return this.model.wizardIconsLeftPaddingMap.get(step.icon);
    }
}
