import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBaseService } from '../../../core';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class LatLongMapDataService extends HttpBaseService {

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    getCoordinatesFromAddress(address: string): Observable<any> {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(`${address}`)}&key=AIzaSyAwJWdBYQ_KHDMF1C_hmyVR5MBLbMRQMs4`;
        return this.httpGet(url);
    }

    getAddressFromCoordinates(lat: number, lng: number): Observable<any> {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAwJWdBYQ_KHDMF1C_hmyVR5MBLbMRQMs4`;
        return this.httpGet(url);
    }

}
