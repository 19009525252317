import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AppLoginGuard implements CanActivate {

    constructor(private _router: Router, private _authService: AuthService) { }

    canActivate(
        route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> | Promise<UrlTree | boolean> | UrlTree | boolean {
        this._authService.setRoutedApp(route.url[1].path);
        this._router.navigate(['/home']);
        return true;
    }

}
