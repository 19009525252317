import { Injectable } from '@angular/core';

import { App, AppPropertiesAliasMap, AppPropertyAlias } from '../../models';
import { AliasesDefaultValues } from '../../shared/enums';

@Injectable({
    providedIn: 'root'
})

export class AppAliasService {

    aliases: AppPropertiesAliasMap;

    get projectAlias(): string {
        return this.aliases?.project.singular;
    }

    get projectsAlias(): string {
        return this.aliases?.project.plural;
    }

    get siteAlias(): string {
        return this.aliases?.site.singular;
    }

    get sitesAlias(): string {
        return this.aliases?.site.plural;
    }

    get projectNoteAlias(): string {
        return `${this.aliases?.project.singular} Note`;
    }

    get projectNotesAlias(): string {
        return `${this.aliases?.project.singular} Notes`;
    }

    get siteRequestAlias(): string {
        return `${this.aliases?.site.singular} Request`;
    }

    get projectAliasLowerCase(): string {
        return this.aliases?.project.singular.toLowerCase();
    }

    get projectsAliasLowerCase(): string {
        return this.aliases?.project.plural.toLowerCase();
    }

    get siteAliasLowerCase(): string {
        return this.aliases?.site.singular.toLowerCase();
    }

    get sitesAliasLowerCase(): string {
        return this.aliases?.site.plural.toLowerCase();
    }

    get siteAliasUpperCase(): string {
        return this.aliases?.site.singular.toUpperCase();
    }

    constructor() { }

    setAliases(app: App): void {
        this.initModel();
        this.setProjectAliases(app);
        this.setSiteAliases(app);
    }

    initModel(): void {
        this.aliases = new AppPropertiesAliasMap();
        this.aliases.project = new AppPropertyAlias();
        this.aliases.site = new AppPropertyAlias();
    }

    setProjectAliases(app: App): void {
        if (app?.aliases?.project) {
            this.aliases.project.singular = app.aliases.project.singular;
            this.aliases.project.plural = app.aliases.project.plural;
            return;
        }
        this.aliases.project.singular = AliasesDefaultValues.SingularProject;
        this.aliases.project.plural = AliasesDefaultValues.PluralProject;
        return;
    }

    setSiteAliases(app: App): void {
        if (app?.aliases?.site) {
            this.aliases.site.singular = app.aliases.site.singular;
            this.aliases.site.plural = app.aliases.site.plural;
            return;
        }
        this.aliases.site.singular = AliasesDefaultValues.SingularSite;
        this.aliases.site.plural = AliasesDefaultValues.PluralSite;
        return;
    }
}
