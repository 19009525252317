import { Subject } from 'rxjs';

/* istanbul ignore file */

export class ProjectNumberLogicServiceModel {
    isValidChargeNo = true;
    chargeNumberDecimalIndex = 9;
    chargeNumberRequiredLength = 11;
    validChargeNo$: Subject<boolean> = new Subject<boolean>();
}
