import { DynamicLogicSet } from '../../../../models';
import { Report } from './report';
import { ReportDefinitionHeader } from './report-definition-header';
import { ReportFormattingData } from './report-formatting-data';

export class ReportsServiceModel {
    applicableConditionalLogic?: DynamicLogicSet[];
    report: Report;
    reportLoading: boolean;
    reportsMap: Map<string, Report>;
    reportDefinitionsHeaders: ReportDefinitionHeader[];
    reportFormatData: ReportFormattingData;
    reportFormatDatasMap: Map<string, ReportFormattingData>;
}
