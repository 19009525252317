import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BulkSiteUploadTypeSelectionModel } from './models';

@Injectable({
    providedIn: 'root'
})
export class BulkSiteUploadTypeSelectionLogicService {

    model: BulkSiteUploadTypeSelectionModel;

    constructor() { }

    initModel(): void {
        this.model = new BulkSiteUploadTypeSelectionModel();
        this.createTypeSelectionForm();
    }

    createTypeSelectionForm(): void {
        this.model.typeSelectionForm = new FormGroup({
            bulkSiteUploadType: new FormControl(null, [Validators.required]),
        });
    }

}
