/* istanbul ignore file */

import { FormGroup } from '@angular/forms';

import { VirtualizationSettings } from '@progress/kendo-angular-dropdowns';

import { User } from '../../../models';

export class RequestAppServiceModel {
    characterCap = 20;
    appForm: FormGroup;
    filteredUsers: User[] = [];
    virtualSettings: VirtualizationSettings = {
        itemHeight: 36, //defualt, height of an item
        pageSize: 10 //number of items to load at once
    };
    disabledLicenseOptions: number[] = [];
    isEditingAppInRenewalPeriod = false;
}
