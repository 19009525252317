import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AppLoginGuard } from 'src/auth/guards/app-login.guard';
import { ErrorPageComponent } from 'src/auth/pages/404/error-page.component';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('../auth/modules/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'app/:url/login',
        loadChildren: () => import('../auth/modules/login/login.module').then(m => m.LoginModule),
        canActivate: [AppLoginGuard]
    },
    {
        path: 'home',
        loadChildren: () => import('../auth/modules/home/home.module').then(m => m.HomeModule)
    },
    {
        path: '',
        component: AppComponent,
        loadChildren: () => import('../app/app.module').then(m => m.AppModule)
    },
    {
        path: '**',
        pathMatch: 'full',
        component: ErrorPageComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        {
            useHash: false
        }
    )
    ],
    exports: [RouterModule]
})
export class MainRoutingModule { }
