<button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    aria-label="Menu"
    *ngIf="user !== null"
>
    <mat-icon class="kh-text-color">apps</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <div class="kh-primary-menu-user-profile pt-2 pb-1">
        <div class="kh-primary-menu-user-profile-picture pe-1">
            <img
                *ngIf="user && user.avatar; else noImageTemplate"
                [src]="user.avatar"
                alt="User Profile Image"
            />
            <ng-template #noImageTemplate>
                <mat-icon
                    class="account-circle"
                    [ngStyle]="{ color: '#77787B' }"
                >
                    account_circle
                </mat-icon>
            </ng-template>
        </div>
        <div class="pe-4">
            <div class="kh-primary-menu-user-profile-name">{{ user?.firstName }} {{ user?.lastName }}</div>
            <div class="kh-primary-menu-user-profile-access">{{ accessText }}</div>
        </div>
    </div>
    <div
        class="ps-4 kh-menu-item"
        mat-menu-item
        (click)="openRoute('platform/apps')"
    >
        <mat-icon class="ps-4">autorenew</mat-icon>
        <span class="ps-3"> Switch Apps</span>
    </div>
    <div
        class="ps-4 kh-menu-item"
        mat-menu-item
        (click)="openRoute('account')"
    >
        <mat-icon class="ps-4">settings</mat-icon>
        <span class="ps-3"> User Account Settings</span>
    </div>
    <div
        class="ps-4 kh-menu-item"
        mat-menu-item
        [matMenuTriggerFor]="helpMenu"
    >
        <mat-icon class="ps-4">info</mat-icon>
        <span class="ps-3"> Help</span>
    </div>
    <div
        class="ps-4 border-top kh-menu-item"
        mat-menu-item
        (click)="this.logout()"
    >
        <mat-icon class="ps-4">logout</mat-icon>
        <span class="ps-3">Sign Out</span>
    </div>
</mat-menu>

<mat-menu
    class="sideHelpMenu"
    #helpMenu="matMenu"
>
    <div
        class="me-1 kh-menu-item"
        mat-menu-item
        (click)="openRoute('help')"
    >
        <mat-icon>my_library_books</mat-icon>
        <span>Get Started</span>
    </div>
    <div
        class="me-1 kh-menu-item"
        mat-menu-item
        (click)="openRoute('release-notes')"
    >
        <mat-icon>star</mat-icon>
        <span>What's New</span>
    </div>
    <div
        class="me-1 kh-menu-item"
        mat-menu-item
        (click)="openFeedbackForm()"
    >
        <mat-icon>send</mat-icon>
        <span>Send Feedback</span>
    </div>
</mat-menu>
