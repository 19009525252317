/* istanbul ignore file */
export enum FileTypes {
    Jpg = 'jpg',
    Png = 'png',
    Jpeg = 'jpeg',
    Gif = 'gif',
    Pdf = 'pdf',
    Doc = 'doc',
    Docx = 'docx',
    Xls = 'xls',
    Xlsx = 'xlsx',
    Ppt = 'ppt',
    Pptx = 'pptx',
    Txt = 'txt',
    Csv = 'csv',
    Zip = 'zip',
    Tiff = 'tiff',
    Raw = 'raw',
    Powerpoint = 'powerpoint',
    Excel = 'excel',
    Word = 'word',
    Image = 'image',
    Json = 'json',
    GeoJson = 'geojson',
    Shapefile = 'shapefile',
    Rar = 'rar',
    Mp3 = 'mp3',
    Mp4 = 'mp4',
    Html = 'html',
    Css = 'css',
    Js = 'js',
    Avif = 'avif',
    Webp = 'webp'
}

export enum PreviewableFileTypes {
    Jpg = 'jpg',
    Png = 'png',
    Jpeg = 'jpeg',
    Pdf = 'pdf',
    Avif = 'avif',
    Gif = 'gif',
    Webp = 'webp'
}
