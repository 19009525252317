/* istanbul ignore file */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';
import { User } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class AuthDataService extends HttpBaseService {

    private route = environment.api;

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    sendUserCode(phoneNumberObject: unknown): Observable<any> {
        const url = `${this.route}SMS/external/sms`;
        return this.httpPost(url, phoneNumberObject);
    }

    loginUserCode(cellPhoneObject: unknown): Observable<User> {
        const url = `${this.route}SMS/external/code`;
        return this.httpPost(url, cellPhoneObject);
    }

    loginUserAPI(adToken: string): Observable<User> {
        const url = `${this.route}authenticate`;
        this._httpHeaders = new HttpHeaders({ Authorization: `Bearer ${adToken}` });
        return this.httpGet(url);
    }

}
