import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class InvoiceDataService extends HttpBaseService {

    private route = environment.api + 'invoices/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    getInvoicesByAppId(appId: string, year: string, month: string): Observable<any> {
        const url = `${this.route}app/${appId}/year/${year}/month/${month}`;

        return this.httpGet(url);
    }

}
