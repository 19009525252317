import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogoutTimerService } from 'src/app/services/logout-timer.service';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-timeout-dialog',
  templateUrl: './timeout-dialog.component.html',
  styleUrls: ['./timeout-dialog.component.scss'],
})
export class TimeoutDialogComponent implements OnInit, OnDestroy, AfterViewInit {


  constructor(
    private _router: Router,
    public dialogRef: MatDialogRef<TimeoutDialogComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { time: number },
    public timeoutservice: LogoutTimerService,
  ) {
  }

  @ViewChild('cdTimer', { static: false }) private countdown: CountdownComponent;

  public config: CountdownConfig = {
    demand: false,
    leftTime: this.data.time,
    format: 'mm:ss',
  };

  private _routerSubscription: Subscription = new Subscription()

  ngOnInit() {
    this._routerSubscription = this._router.events.subscribe(() => {
      if(this.dialogRef){
        this.dialogRef.close();
      }
    });
  }

  ngOnDestroy() {
    this._routerSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.countdown.begin()
  }

  extendSessionClick(){
    this.dialogRef.close(true);
  }

  logoutClick(){
    this.dialogRef.close(false);
  }
}

