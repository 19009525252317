import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { App } from '../../models';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class FileDataService extends HttpBaseService {

    private route = environment.api + 'file/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    downloadFileBlob(app: App, uniqueFileName: string): Observable<Blob> {
        const url = `${this.route}${app.id}/${uniqueFileName}`;
        return this.httpDownload(url);
    }
}
