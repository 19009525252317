import { Injectable } from '@angular/core';

import { RTSForm } from '../../models';
import { Column, Field, FieldProperty, ObjectProperties, Properties, Schema, Section, SmartsForm } from '../logic-services/models';

@Injectable({
    providedIn: 'root'
})
export class SchemaBuilderUtility {

    private tempSchema: Schema = new Schema();

    constructor() { }

    buildRtsForm(rtsForm: RTSForm): void {
        this.build(rtsForm.RTSForms);
    }

    build(smartsForm: SmartsForm): void {
        if (smartsForm.Schema === undefined)
            smartsForm.Schema = new Schema();

        this.tempSchema = new Schema();
        this.tempSchema.type = ObjectProperties.Object;
        this.tempSchema.properties = new Properties();
        this.tempSchema.required = new Array<string>();

        smartsForm.Sections?.forEach((section: Section) => {
            this.sectionObject(section);
        });

        smartsForm.Schema = this.tempSchema;
    }

    sectionArray(sections: Section[]): void {
        sections.forEach((section: Section) => {
            if (section.Columns?.length)
                this.columnsArray(section.Columns);

            if (section.Sections?.length)
                this.sectionArray(section.Sections);
        });
    }

    sectionObject(section: Section): void {
        if (section.Columns?.length)
            this.columnsArray(section.Columns);

        if (section.Sections?.length)
            this.sectionArray(section.Sections);
    }

    columnsArray(columns: Column[]): void {
        columns.forEach((column: Column) => {
            this.columnObject(column);
        });
    }

    columnObject(column: Column): void {
        if (column.Fields?.length)
            this.fieldsArray(column.Fields);
    }

    fieldsArray(fields: Field[]): void {
        fields.forEach((field: Field) => {
            this.fieldObject(field);
        });
    }

    fieldObject(field: Field): void {
        if (field.FieldType.required)
            this.tempSchema.required.push(field.id);

        const fieldProperty = new FieldProperty();

        fieldProperty.id = field.FieldType.id;
        fieldProperty.text = field.FieldType.text.replace(/\s/g, '');
        fieldProperty.type = field.FieldType.type;

        if (field.FieldType.enum?.length) {
            fieldProperty.enum = new Array<string>();
            fieldProperty.enum = field.FieldType.enum;
        }

        if (field.FieldType.hasOwnProperty(ObjectProperties.Photos))
            fieldProperty.photos = field.FieldType.photos;

        if (field.FieldType.hasOwnProperty(ObjectProperties.Upload))
            fieldProperty.upload = field.FieldType.upload;

        if (field.FieldType.hasOwnProperty(ObjectProperties.Grid))
            fieldProperty.grid = field.FieldType.grid;

        this.tempSchema.properties[field.id] = fieldProperty;
    }
}
