/* istanbul ignore file */
export const environment = {
    version: require('../../package.json').version + '-tst',
    production: false,
    api: 'https://smarts-tst.kimley-horn.com/api/',
    url: 'https://smarts-tst.kimley-horn.com/',
    blobStorage: 'https://sasmartsplatformtst.blob.core.windows.net/',
    name: 'SMARTS Platform',
    colors: {
        primaryColor: '#592c82',
        primaryVariantColor: '#835ea0',
        primaryTextColor: '#ffffff',
        primaryBlackColor: "#424553",
        secondaryColor: "#e6e6e6",
        secondaryVariantColor: "#bdbdbd",
        secondaryTextColor: '#353a40',
        backgroundColor: '#ffffff',
        surfaceColor: '#ffffff',
        errorColor: '#a20c33',
    },
    logos: {
        primaryLogo: 'assets/logo-color-primary.png',
        secondaryLogo: 'assets/logo-color-primary.png',
    },
    azure: {
        scopes: 'api://d66a8456-6c33-4f3c-90dd-6344b0abcc0d/access_as_user',
        clientId: 'd66a8456-6c33-4f3c-90dd-6344b0abcc0d',
        redirectUri: 'https://smarts-tst.kimley-horn.com/'
    },
    outerHeight: 149,
    developement: false,
    serviceRequestApi: 'https://app-khtscore-servicerequest-tst-southcentralus.azurewebsites.net/ServiceRequest',
    showRoadThreatProgram: false,
    appInsights: {
        instrumentationKey: '470a7ebd-ce01-45c2-b41c-b6ac8cdf3309'
    }
};
