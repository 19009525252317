/* istanbul ignore file */
export class AppPropertiesAliasMap {
    project: AppPropertyAlias;
    site: AppPropertyAlias;
}

export class AppPropertyAlias {
    plural = "";
    singular = "";
}
