import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Subject } from 'rxjs';

import { ChargeNumbers } from '../../shared/enums';
import { ProjectNumberDataService } from '../data-services/project-number-data-service.service';
import { ProjectNumberLogicServiceModel } from './models';

@Injectable({
    providedIn: 'root'
})
export class ProjectNumberLogicService {

    model: ProjectNumberLogicServiceModel = new ProjectNumberLogicServiceModel();

    constructor(
        private _projectNumberDataService: ProjectNumberDataService
    ) {
    }

    triggerUpdate(subject: Subject<boolean>): void {
        subject.next(true);
    }

    validateChargeNo(chargeNo: string): void {
        this._projectNumberDataService.validateChargeNo(chargeNo).subscribe({
            next: (isValidChargeNo) => {
                this.setValidChargeNo(isValidChargeNo['exists']);
            },
            error: (error) => {
                throw new Error(error);
            }
        });
    }

    setValidChargeNo(value: boolean): void {
        this.model.isValidChargeNo = value;
        this.triggerUpdate(this.model.validChargeNo$);
    }

    chargeCodeValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value === "") {
                if (this.model.isValidChargeNo)
                    this.setValidChargeNo(false);
                return { required: true };
            }
            if (!this.model.isValidChargeNo) {
                return { invalid: true };
            }
            return null;
        };
    }

    chargeCodeDemoValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value === ChargeNumbers.Demo)
                return { cannotUseDemo: true };
            return null;
        };
    }

    filterInvalidChargeNoValues(chargeNumbers: string[]): string[] {
        return chargeNumbers.filter(chargeNumber => {
            return this.isChargeNumberValid(chargeNumber);
        });
    }

    isChargeNumberValid(chargeNumber: string): boolean {
        if (!chargeNumber ||
            chargeNumber.length !== this.model.chargeNumberRequiredLength ||
            chargeNumber[this.model.chargeNumberDecimalIndex] !== '.' ||
            chargeNumber.includes(' ')
        ) return false;

        return true;
    }
}
