<div class="container h-100">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-auto align-content-center align-items-center">
      <div
        class="kh-error-page-logo-container"
      >
        <img
          class="kh-error-page-logo"
          alt="KH Smarts Logo"
          src="assets/logo-color-primary.png"
        />
      </div>
      <h1 class="text-center">Error 404: Page not found</h1>
    </div>
  </div>
</div>
