import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class HelpFeedbackDataService extends HttpBaseService {

    private route = environment.serviceRequestApi;

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    postFormData(data: any) {
        const url = `${this.route}`;
        return this.httpPost(url, data);
    }

}
