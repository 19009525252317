import { Component } from '@angular/core';

import { KHThemeService } from '../../../../../services';
import { AppAliasService, BulkSiteUploadShapefileLogicService } from '../../../../../services/logic-services';

@Component({
    selector: 'kh-bulk-site-upload-shapefile-save-sites-dialog',
    templateUrl: './bulk-site-upload-shapefile-save-sites-dialog.component.html',
    styleUrls: ['./bulk-site-upload-shapefile-save-sites-dialog.component.scss']
})
export class BulkSiteUploadShapefileSaveSitesDialogComponent {

    get percentComplete(): number { return this._bulkSiteUploadShapefileLogicService.model.saveSitesStep.percentComplete; }

    get sitesAliasLowerCase(): string { return this._appAliasService.sitesAliasLowerCase; }
    get siteAliasLowerCase(): string { return this._appAliasService.siteAliasLowerCase; }
    get siteAlias(): string { return this._appAliasService.siteAlias; }

    get primaryColorFiftyOpacity(): string { return this._themeService.primaryFiftyOpacity(); }
    get primarySevenOpacity(): string { return this._themeService.primarySevenOpacity(); }
    get primaryColor(): string { return this._themeService.primaryColor; }

    constructor(
        private _appAliasService: AppAliasService,
        private _themeService: KHThemeService,
        private _bulkSiteUploadShapefileLogicService: BulkSiteUploadShapefileLogicService
    ) { }
}
