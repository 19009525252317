/* istanbul ignore file */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorHandler } from './error-handler';
import { ParamConstructor } from './param-constructor';

@Injectable({
    providedIn: 'root'
})
export abstract class HttpBaseService {

    protected _httpHeaders: HttpHeaders;
    protected _params: ParamConstructor;

    constructor(
        private _http: HttpClient,
    ) {
        this._params = new ParamConstructor();
    }

    protected httpGet(url: string): Observable<any> {
        return this._http.get(url, { headers: this._httpHeaders })
            .pipe(catchError(ErrorHandler.handle));
    }

    protected httpDownload(url: string): Observable<any> {
        return this._http.get(url, { headers: this._httpHeaders, responseType: 'blob' })
            .pipe(catchError(ErrorHandler.handle));
    }

    protected httpPost(url: string, object: any): Observable<any> {
        return this._http.post(url, object, { headers: this._httpHeaders })
            .pipe(catchError(ErrorHandler.handle));
    }

    protected httpPut(url: string, object: any): Observable<any> {
        return this._http.put(url, object, { headers: this._httpHeaders })
            .pipe(catchError(ErrorHandler.handle));
    }

    protected httpPatch(url: string, object: any): Observable<any> {
        return this._http.patch(url, object, { headers: this._httpHeaders })
            .pipe(catchError(ErrorHandler.handle));
    }

    protected httpDelete(url: string): Observable<any> {
        return this._http.delete(url, { headers: this._httpHeaders })
            .pipe(catchError(ErrorHandler.handle));
    }

    protected httpDeleteWithBody(url: string, object: any): Observable<any> {
        return this._http.request('delete', url, { body: object, headers: this._httpHeaders })
            .pipe(catchError(ErrorHandler.handle));
    }
}
