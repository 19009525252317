/* istanbul ignore file */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBaseService } from '../../../core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProjectNumberDataService extends HttpBaseService {

    route = environment.api + 'projectnumber';

    constructor(private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    validateChargeNo(chargeNo: string): Observable<any> {
        const url = `${this.route}/${chargeNo}`;
        return this.httpGet(url);
    }
}
