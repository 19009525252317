/* istanbul ignore file */

import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

import { GridColumnProperties } from '../grid-column-properties';

export class GridRendererFormattingData {
    columnSettings: GridColumnProperties[] = [];
    filters: (CompositeFilterDescriptor | FilterDescriptor)[] = [];
    numLockedColumns = 0;
}
