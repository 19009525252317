import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { App, Project } from '../../models';
import { ReportDefinitionHeader } from '../logic-services/models/report';
import { Report } from '../logic-services/models/report/report';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
/* istanbul ignore file */
export class ReportsDataService extends HttpBaseService {
    route = environment.api + 'reports/app/';

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    getReportDefinitionsHeaders(app: App, project: Project): Observable<ReportDefinitionHeader[]> {
        const url = `${this.route}${app.id}/project/${project.id}/report-definitions-headers`;
        return this.httpGet(url);
    }

    getReportByReportDefinitionId(app: App, project: Project, reportDefinitionId: string): Observable<Report> {
        const url = `${this.route}${app.id}/project/${project.id}/report-definition/${reportDefinitionId}/report`;
        return this.httpGet(url);
    }
}
