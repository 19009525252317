import { Injectable } from '@angular/core';

import { Color } from '@angular-material-components/color-picker';
import { FormBuilder, Validators } from '@angular/forms';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { Observable } from 'rxjs';
import { App } from '../../models';
import { MapIcon, } from '../../models/map-pin-model';
import { MapSettingsPreviewModel } from '../../models/map-settings-preview-model';
import { FileTypes, MapPinStyles } from '../../shared/enums';
import { PinSize } from '../../shared/enums/pin-size.enum';
import { AppsDataService } from '../data-services';
import { MapSettingsDataService } from '../data-services/map-settings-data.service';
import { MapSettingsFormLogicServiceModel } from './models/map';

@Injectable({
    providedIn: 'root',
})
export class MapSettingsFormLogicService {
    model: MapSettingsFormLogicServiceModel;

    defaultPinColor = 'rgba(234,67,53,255)';
    uploadRestrictions: FileRestrictions = {
        allowedExtensions: [FileTypes.Jpg, FileTypes.Jpeg, FileTypes.Png],
    };

    constructor(
        private _appsDataService: AppsDataService,
        private _formbuilder: FormBuilder,
        private _mapSettingsDataService: MapSettingsDataService
    ) { }

    initModel(): void {
        this.model = new MapSettingsFormLogicServiceModel();
        this.model.rawFile = null;
        this.model.mapPin = null;
        this.model.mapPins = [];
        this.model.mapPinPreviewSettings = new MapSettingsPreviewModel();
        this.model.mapSettingsForm = this._formbuilder.group({
            mapPinWidth: [PinSize.defaultPinSize, Validators.required],
            mapPinHeight: [PinSize.defaultPinSize, Validators.required],
            mapPinStyle: [MapPinStyles.Default, Validators.required],
            mapPinColor: [this.rgbaToColor(this.defaultPinColor), Validators.required],
            mapPin: [[]]
        });

        this._mapSettingsDataService.loadSVG()
            .then(svgDocument => {
                this.model.mapPinPreviewSettings.svgDocument = svgDocument;
                this.model.mapPinPreviewSettings.svg = this.colorizeSVG();
                this.placeMarkerOnMap();
            });
    }

    loadMapSettings(app: App): void {
        if (!app) {
            return;
        }

        if (app.mapPin) {
            this.model.mapPins.push(app.mapPin);
            this.model.mapPin = { ...app.mapPin };
        }

        this.model.mapSettingsForm = this._formbuilder.group({
            mapPinWidth: [app.mapPinWidth ?? PinSize.defaultPinSize, Validators.required],
            mapPinHeight: [app.mapPinHeight ?? PinSize.defaultPinSize, Validators.required],
            mapPinStyle: [app.mapPinStyle ?? MapPinStyles.Default, Validators.required],
            mapPinColor: [this.rgbaToColor(app.mapPinColor ? app.mapPinColor.rgba : this.defaultPinColor), Validators.required],
            mapPin: [app.mapPin ?? []]
        });

        this.model.mapPinPreviewSettings.svg = this.colorizeSVG();
        this.model.mapPinPreviewSettings.scaledSize = new google.maps.Size(this.model.mapSettingsForm.value.mapPinWidth, this.model.mapSettingsForm.value.mapPinHeight);

        if (app.mapPin?.uri) {
            this.model.mapPinPreviewSettings.mapIconDataUrl = app.mapPin?.uri;
        }

        this.placeMarkerOnMap();
    }

    getPinColor(): string {
        if (this.model.mapSettingsForm.value.mapPinColor === null) {
            return this.defaultPinColor;
        }
        let hexColor: string = this.model.mapSettingsForm.value.mapPinColor.hex;
        return `#${hexColor}`;
    }

    pinSizeChange(): void {
        if (this.model.mapSettingsForm.value.mapPinWidth === null || this.model.mapSettingsForm.value.mapPinWidth === undefined) {
            this.model.mapSettingsForm.patchValue({ mapPinWidth: PinSize.defaultPinSize });
        }
        if (this.model.mapSettingsForm.value.mapPinWidth < PinSize.minPinSize) {
            this.model.mapSettingsForm.patchValue({ mapPinWidth: PinSize.minPinSize });
        }
        if (this.model.mapSettingsForm.value.mapPinWidth > PinSize.maxPinSize) {
            this.model.mapSettingsForm.patchValue({ mapPinWidth: PinSize.maxPinSize });
        }

        if (this.model.mapSettingsForm.value.mapPinHeight === null || this.model.mapSettingsForm.value.mapPinHeight === undefined) {
            this.model.mapSettingsForm.patchValue({ mapPinHeight: PinSize.defaultPinSize });
        }
        if (this.model.mapSettingsForm.value.mapPinHeight < PinSize.minPinSize) {
            this.model.mapSettingsForm.patchValue({ mapPinHeight: PinSize.minPinSize });
        }
        if (this.model.mapSettingsForm.value.mapPinHeight > PinSize.maxPinSize) {
            this.model.mapSettingsForm.patchValue({ mapPinHeight: PinSize.maxPinSize });
        }

        this.model.mapPinPreviewSettings.scaledSize = new google.maps.Size(this.model.mapSettingsForm.value.mapPinWidth, this.model.mapSettingsForm.value.mapPinHeight);
        this.placeMarkerOnMap();
    }

    pinColorChange(): void {
        this.model.mapPinPreviewSettings.svg = this.colorizeSVG();
        this.model.mapPinPreviewSettings.mapIcon = new MapIcon(this.model.mapPinPreviewSettings.svg, this.model.mapPinPreviewSettings.scaledSize);
        this.placeMarkerOnMap();
    }

    clearPinColor(): void {
        this.model.mapSettingsForm.patchValue({ mapPinColor: this.rgbaToColor(this.defaultPinColor) });
        this.pinColorChange();
    }

    rgbaToColor(rgbaString: string): Color {
        const rgbaValues = rgbaString.substring(5, rgbaString.length - 1).split(',').map(value => parseFloat(value.trim()));
        const [red, green, blue] = rgbaValues;

        const color = new Color(red, green, blue, 1);
        return color;
    }

    pinStyleChange(): void {
        this.placeMarkerOnMap();
    }

    pinFileSelected(event: SelectEvent): void {
        if (!this.model.mapPins) {
            this.model.mapPins = [];
        }
        if (event.files.length === 0) {
            this.model.rawFile = null;
        }
        if (event.files[0].validationErrors && event.files[0].validationErrors.length > 0) {
            this.model.rawFile = null;
        }
        if (event.files.length >= 0) {
            this.model.rawFile = event.files[0].rawFile;
            this.model.mapPins[0] = event.files[0];
            this.model.mapPin = { ...event.files[0] };
            const reader = new FileReader();
            reader.onload = this.handleReaderLoad.bind(this);
            reader.readAsDataURL(this.model.rawFile);
        }
        else {
            this.model.rawFile = null;
        }
    }

    handleReaderLoad(e: ProgressEvent<FileReader>): void {
        if (e.target?.result) {
            this.model.mapPinPreviewSettings.mapIconDataUrl = e.target.result as string;
            this.model.mapPinPreviewSettings.mapIcon = new MapIcon(this.model.mapPinPreviewSettings.mapIconDataUrl, this.model.mapPinPreviewSettings.scaledSize);
            this.placeMarkerOnMap();
        }
    }

    pinFileRemoved(): void {
        this.model.rawFile = null;
        this.model.mapPin = null;
        this.model.mapPins = [];
        this.model.mapPinPreviewSettings.mapIconDataUrl = null;
        this.model.mapSettingsForm.patchValue({ mapPin: [] });
        this.placeMarkerOnMap();
    }

    isMapPinCustom(): boolean {
        return this.model.mapSettingsForm.value.mapPinStyle === MapPinStyles.Custom;
    }

    createGoogleLatLong(latitude: number, longitude: number): google.maps.LatLng {
        return new google.maps.LatLng(latitude, longitude);
    }

    setCurrentCoordinates(coordinates: google.maps.LatLng): void {
        this.model.mapPinPreviewSettings.currentCoordinates = coordinates;
    }

    createMap(mapElement: HTMLElement): void {
        this.model.mapPinPreviewSettings.map = new google.maps.Map(mapElement, this.model.mapPinPreviewSettings.mapProperties);
        this.model.mapPinPreviewSettings.svg = this.colorizeSVG();
        this.placeMarkerOnMap();
        this.setMapLoading(false);
    }

    setMapLoading(isLoading: boolean): void {
        this.model.mapPinPreviewSettings.mapLoading = isLoading;
    }

    placeMarkerOnMap(): void {
        this.clearMarker();
        this.createMarker();
    }

    createMarker(): void {
        if (!this.model.mapPinPreviewSettings.map) {
            return;
        }

        if (this.model.mapSettingsForm.value.mapPinStyle === MapPinStyles.Custom && this.model.mapPinPreviewSettings.mapIconDataUrl) {
            this.model.mapPinPreviewSettings.mapIcon = new MapIcon(this.model.mapPinPreviewSettings.mapIconDataUrl, this.model.mapPinPreviewSettings.scaledSize);
        }
        else {
            this.model.mapPinPreviewSettings.mapIcon = new MapIcon(this.model.mapPinPreviewSettings.svg, this.model.mapPinPreviewSettings.scaledSize);
        }

        this.model.mapPinPreviewSettings.marker = new google.maps.Marker({
            position: this.model.mapPinPreviewSettings.currentCoordinates,
            map: this.model.mapPinPreviewSettings.map,
            draggable: false,
            icon: this.model.mapPinPreviewSettings.mapIcon?.url !== '' ? this.model.mapPinPreviewSettings.mapIcon : null,
        });
    }

    clearMarker(): void {
        if (this.model.mapPinPreviewSettings.marker === undefined) {
            return;
        }
        this.model.mapPinPreviewSettings.marker.setMap(null);
        this.model.mapPinPreviewSettings.marker = undefined;
    }

    colorizeSVG(): string {
        if (!this.model.mapPinPreviewSettings.svgDocument) {
            return this.model.mapPinPreviewSettings.svg;
        }

        const color: string = this.model.mapSettingsForm.value.mapPinColor.rgba;
        const styleElement = this.model.mapPinPreviewSettings.svgDocument.querySelector('style');

        if (styleElement) {
            styleElement.textContent = `.icon-fill { fill: ${color}; }`;
        }

        const svgString = new XMLSerializer().serializeToString(this.model.mapPinPreviewSettings.svgDocument.documentElement);
        return `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`;
    }

    updateApp(app: App): Observable<App> {
        app.mapPinColor = this.model.mapSettingsForm.value.mapPinColor;
        app.mapPinWidth = this.model.mapSettingsForm.value.mapPinWidth;
        app.mapPinHeight = this.model.mapSettingsForm.value.mapPinHeight;
        app.mapPinStyle = this.model.mapSettingsForm.value.mapPinStyle;
        app.mapPin = this.model.mapPin;

        let formData = new FormData();
        formData.append('app', JSON.stringify(app));
        formData.append('mapPin', this.model.rawFile);
        this.model.loadingData = true;

        const observable = this._appsDataService.updateApp(app, formData);

        return observable;
    }

}

