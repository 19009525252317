/* istanbul ignore file */
export enum LicenseTiers {
    PerSite = 0,
    Bronze = 1,
    Silver = 2,
    Gold = 3,
    Platinum = 4,
    Diamond = 5,
    Titanium = 6,
    Other = 7,
}
