import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { App, Project } from '../models';
import { User } from '../models/user';
import { ClientErrorStatusCodes, HttpErrorLocationMessages } from '../shared/enums/http-status-codes-enum';
import { UsersDataService } from './data-services';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    private _currentUsers: User[] = [];
    private _platformUsers: User[] = [];
    private loadUsersByApp: Subject<boolean> = new Subject<boolean>();
    private currentUserReady: Subject<boolean> = new Subject<boolean>();
    private _usersLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    loadUsersByApp$ = this.loadUsersByApp.asObservable();
    currentUserReady$ = this.currentUserReady.asObservable();
    usersLoading$ = this._usersLoading.asObservable();

    users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null);
    user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    appUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    allUsers: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null);
    appUsers: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null);

    currentUser: User;
    currentAppUser: User;
    isSaving = false;

    get currentUsers(): User[] {
        return this._currentUsers;
    }

    get platformUsers(): User[] {
        return this._platformUsers;
    }

    constructor(
        private _usersDataService: UsersDataService,
        private _snackBar: MatSnackBar,
    ) { }

    init(): void {
        if (sessionStorage.getItem("user")) {
            const user = JSON.parse(sessionStorage.getItem("user"));
            this.setUser(user);
        }

        if (sessionStorage.getItem("appUser")) {
            const appUser = JSON.parse(sessionStorage.getItem("appUser"));
            this.setAppUser(appUser);
        }
    }

    reloadUser(): void {
        if (this.currentUser) {
            this.getUser(this.currentUser.id);
        }
    }

    setUser(user: User): void {
        this.setCurrentUser(user);
        sessionStorage.setItem("user", JSON.stringify(user));
    }

    setAppUser(user: User): void {
        this.setCurrentAppUser(user);
        sessionStorage.setItem("appUser", JSON.stringify(user));
    }

    setUsers(users: any): void {
        sessionStorage.setItem('users', JSON.stringify(users));
        this.users.next(users);
    }

    disconnectUser(): void {
        this.setCurrentUser(null);
        this.setCurrentAppUser(null);
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("appUser");
    }

    setCurrentAppUser(user: User): void {
        this.appUser.next(user);
        this.currentAppUser = user;
    }

    setCurrentUser(user: User): void {
        this.user.next(user);
        this.currentUser = user;
        this.currentUserReady.next(true);
    }

    setTermsAccepted(value: boolean): void {
        this.currentUser.acceptedTOS = value;
    }

    addUpdateUser(user: User): void {
        if (this._currentUsers.find(u => u.id === user.id)) {
            let index = this._currentUsers.findIndex(u => u.id === user.id);
            this._currentUsers[index] = user;
        }
        else {
            this._currentUsers.push(user);
        }
        this.allUsers.next(this._currentUsers);
    }

    appsAndProjectsByUserId(userId: string): Observable<any> {
        const appsAndProjectsByUserId = new Subject<any>();

        let user: User = { id: userId };

        this._usersDataService.appsAndProjectsByUserId(user)
            .subscribe((response: User) => {
                this.addUpdateUser(response);
                appsAndProjectsByUserId.next(response);
            });

        return appsAndProjectsByUserId.asObservable();
    }

    addUserToApp(app: App, project: Project, user: User, projectAlias: string): void {
        this.isSaving = true;
        this._usersDataService.addUserToApp(user)
            .subscribe({
                next: (response: User) => {
                    this._snackBar.open(`User added to the ${app.name} application and the ${project.name} ${projectAlias}.`, `Close`, { duration: 10000 });
                    this.addUpdateUser(response);
                },
                error: (error: HttpErrorResponse) => {
                    if (error.status === ClientErrorStatusCodes.Conflict) {
                        this._snackBar.open(`This user has already been added to the ${app.name} application.`, `Close`, { duration: 10000 });
                        return;
                    }
                    if (error.status === ClientErrorStatusCodes.NotFound) {
                        this._snackBar.open(`This user no longer exists in the application. Please create new user.`, `Close`, { duration: 10000 });
                        return;
                    }

                    this._snackBar.open(`Error adding user to ${app.name}.`, `Close`, { duration: 5000 });
                }
            })
            .add(() => {
                this.loadUsersByApp.next(true);
                this.isSaving = false;
            });
    }

    createPlatformUserAndAddToApp(app: App, user: User, project: Project, projectAlias: string): void {
        this._usersDataService.createPlatformUserAndAddToApp(user)
            .subscribe({
                next: (user: User) => {
                    this.addUpdateUser(user);
                    this._snackBar.open(`User created and added user to the ${app.name} application and the ${project.name} ${projectAlias}.`, `Close`, { duration: 5000 });
                    this.isSaving = false;
                },
                error: (error: HttpErrorResponse) => {
                    if (error.status !== ClientErrorStatusCodes.Conflict) {
                        this._snackBar.open(`Error creating user.`, `Close`, { duration: 5000 });
                        return;
                    }
                    if (error.error === HttpErrorLocationMessages.Phone) {
                        this._snackBar.open(`A user with this phone number already exists. Please add the existing user.`, `Close`, { duration: 10000 });
                        return;
                    }
                    this._snackBar.open(`A user with this email already exists. Please add the existing user.`, `Close`, { duration: 10000 });
                    return;
                }
            })
            .add(() => {
                this.loadUsersByApp.next(true);
                this.isSaving = false;
            });
    }

    createPlatformUser(user: User): Observable<any> {
        const createPlatformUser = new Subject<any>();

        this._usersDataService.createPlatformUser(user)
            .subscribe((response) => { createPlatformUser.next(response); });

        return createPlatformUser.asObservable();
    }

    getPlatformUsers(): void {
        this._usersLoading.next(true);

        this._usersDataService.getPlatformUsers()
            .subscribe((users) => {
                this._currentUsers = users;
                this._platformUsers = users;
                this.allUsers.next(users);
            })
            .add(() => { this._usersLoading.next(false); });
    }

    getUser(id: string): void {
        this._usersDataService.getUser(id)
            .subscribe((response: User) => {
                this.setCurrentUser(response);
                sessionStorage.setItem("user", JSON.stringify(response));
            });
    }

    getUsersByApp(app: App): void {
        this._usersDataService.getUsersByApp(app)
            .subscribe({
                next: (response: User[]) => {
                    this._currentUsers = response;
                    this.setUsers(response);
                },
                error: () => {
                    this._currentUsers = null;
                    this.setUsers(null);
                }
            })
            .add(() => { this._usersLoading.next(false); });
    }

    loadUsersByApplicationAndProject(app: App, project: Project): void {
        this._usersDataService.loadUsersByApplicationAndProject(app, project)
            .subscribe({
                next: (response: User[]) => {
                    this._currentUsers = response;
                    this.setUsers(response);
                },
                error: () => {
                    this._currentUsers = null;
                    this.setUsers(null);
                }
            })
            .add(() => { this._usersLoading.next(false); });
    }

    getAppUser(app: App, user: User): void {
        this._usersDataService.getAppUser(app, user)
            .subscribe((response: User) => {
                this.setCurrentAppUser(response);
                sessionStorage.setItem("appUser", JSON.stringify(response));
            });
    }

    removeUserFromApp(user: User): void {
        this._usersDataService.removeUserFromApp(user)
            .subscribe({
                next: () => {
                    this._snackBar.open(`User removed.`, "Close", { duration: 5000 });
                },
                error: () => {
                    this._snackBar.open(`Error removing user from app`, `Close`, { duration: 5000 });
                }
            })
            .add(() => {
                user.isSaving = false;
                this.loadUsersByApp.next(true);
            });
    }

    updateUserSitesAccess(user: User, app: App, siteAlias: string, sitesAlias: string): void {
        this._usersDataService.updateUserSitesAccess(user, app)
            .subscribe({
                next: () => {
                    this._snackBar.open(`User's ${sitesAlias.toLocaleLowerCase()} access saved.`, "Close", { duration: 5000 });
                },
                error: () => {
                    this._snackBar.open(`Error saving user's ${siteAlias.toLocaleLowerCase()} access`, `Close`, { duration: 5000 });
                }
            })
            .add(() => {
                user.isSaving = false;
                this.loadUsersByApp.next(true);
            });
    }

    requestAccess(user: User): Observable<any> {
        const requestAccess = new Subject<any>();

        this._usersDataService.requestAccess(user)
            .subscribe((response) => { requestAccess.next(response); });

        return requestAccess.asObservable();
    }

    setAsProjectAdmin(app: App, project: Project, user: User, projectAlias: string): void {
        this._usersDataService.setAsProjectAdmin(app, project, user)
            .subscribe({
                next: (response: User) => {
                    this._snackBar.open(`${projectAlias} Admin role ${user.isProjectAdmin ? 'added' : 'removed'} for '${project.name}''.`, 'Close', { duration: 5000 });
                    this.addUpdateUser(response);
                },
                error: () => {
                    this._snackBar.open(`Error setting ${projectAlias.toLocaleLowerCase()} admin`, `Close`, { duration: 5000 });
                    user.isProjectAdmin = !user.isProjectAdmin;
                }
            })
            .add(() => {
                user.setProjectAdminIsLoading = false;
                this.loadUsersByApp.next(true);
            });
    }

    setAsAppAdmin(app: App, user: User, project: Project): void {
        this._usersDataService.setAsAppAdmin(app, user)
            .subscribe({
                next: (response: User) => {
                    this.addUpdateUser(response);
                    this._snackBar.open(`App Admin role ${user.isAppAdmin ? 'added' : 'removed'} for '${project.name}''.`, 'Close', { duration: 5000 });
                },
                error: () => {
                    user.isAppAdmin = !user.isAppAdmin;
                    this._snackBar.open(`Error setting application admin`, `Close`, { duration: 5000 });
                }
            })
            .add(() => {
                user.setAppAdminIsLoading = false;
                this.loadUsersByApp.next(true);
            });
    }

    updatePlatformUser(user: User): Observable<any> {
        const updatePlatformUser = new Subject<any>();

        this._usersDataService.updatePlatformUser(user)
            .subscribe((response: User) => {
                updatePlatformUser.next(response);
            });

        return updatePlatformUser.asObservable();
    }

    isAccountApprover(user: User): Observable<any> {
        const isAccountApprover = new Subject<any>();

        this._usersDataService.isAccountApprover(user)
            .subscribe((response: User) => {
                isAccountApprover.next(response);
            });

        return isAccountApprover.asObservable();
    }

    changeActive(user: User): Observable<any> {
        const changeActive = new Subject<any>();

        this._usersDataService.changeActive(user)
            .subscribe((response) => { changeActive.next(response); });

        return changeActive.asObservable();
    }

    openEditUser(user: User): Observable<any> {
        const openEditUser = new Subject<any>();

        this._usersDataService.openEditUser(user)
            .subscribe((response) => { openEditUser.next(response); });

        return openEditUser.asObservable();
    }

    changeUserRole(app: App, user: User): Observable<any> {
        const changeUserRole = new Subject<any>();

        this._usersDataService.changeUserRole(app, user)
            .subscribe((response: User) => {
                user.isLoading = false;
                this.addUpdateUser(response);
                this.loadUsersByApp.next(true);
                this._snackBar.open(`User role updated.`, `Close`, { duration: 2000 });
            });

        return changeUserRole.asObservable();
    }

    loadApps(user: User): Observable<App[]> {
        const loadApps = new Subject<App[]>();

        this._usersDataService.loadApps(user)
            .subscribe((response: App[]) => {
                loadApps.next(response);
            });

        return loadApps.asObservable();
    }

    updateUserAccountSettings(id: string, item: any): Observable<User> {
        const updateUserAccountSettings = new Subject<User>();

        this._usersDataService.updateUserAccountSettings(id, item)
            .subscribe((response: User) => { updateUserAccountSettings.next(response); });

        return updateUserAccountSettings.asObservable();
    }

    updateUserProjectRole(user: User, project: Project, roleId: string): void {
        const userProject = user.projects?.find((proj: Project) => proj.projectId === project.id);

        if (userProject)
            userProject.roleId = roleId;
    }

    addProjectToUser(user: User, project: Project): void {
        const userProject = user.projects?.find((proj: Project) => proj.projectId === project.id);

        if (!userProject) {
            const projectToAdd = new Project();
            projectToAdd.projectId = project.id;
            projectToAdd.isProjectAdmin = false;
            user.projects.push(projectToAdd);
        }
    }

    setProjectUser(user: User, project: Project, app: App): void {
        this.isSaving = true;

        user.projId = project.id;
        user.appId = app.id;

        this._usersDataService.setProjectUserStatus(user)
            .subscribe({
                next: (response: User) => {
                    this._snackBar.open(`User ${user.isProjectUser ? 'added to ' : 'removed from '} ${project.name}.`, 'Close', { duration: 5000 });
                    this.addUpdateUser(response);
                    this.loadUsersByApp.next(true);
                },
                error: () => {
                    user.isProjectUser = !user.isProjectUser;
                }
            })
            .add(() => {
                this.isSaving = false;
                user.setProjectUserIsLoading = false;
                this.loadUsersByApp.next(true);
            });
    }

    doesUserHaveAccessToAllSites(user: User): boolean {
        return (
            user.isAppAdmin ||
            !user.sites ||
            user.sites.length === 0
        );
    }

}
