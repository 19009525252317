/* eslint-disable @typescript-eslint/no-inferrable-types */
import MarkerClusterer from "@google/markerclustererplus";
import { MapPin } from "src/app/models/map-pin-model";

/* istanbul ignore file */
export class LatLongMapLogServiceModel {
    mapLoading = true;
    map: google.maps.Map;
    mapProperties: google.maps.MapOptions = {
        center: { lat: 39.550053, lng: -98.5795 },
        zoom: 4,
        mapTypeId: 'roadmap',
        mapTypeControl: false,
        zoomControl: true,
        fullscreenControl: false,
        streetViewControl: false,
    };
    latitudeLongitudeBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    markerCluster: MarkerClusterer;
    markerClusterImagePath = "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m";
    mapPins: MapPin[] = [];
    activeMapPins: MapPin[] = [];
    markers: google.maps.Marker[] = [];
    infoWindow: google.maps.InfoWindow;
    marker: google.maps.Marker;
    mapPin: MapPin;
    currentCoordinates: google.maps.LatLng;
    suggestedAddress: SuggestedAddress = new SuggestedAddress();
}

export class SuggestedAddress {
    address: string = "";
    city: string = "";
    state: string = "";
    zipCode: string = "";
    country: string = "";
}

export class GoogleAddressResponse {
    results: GoogleAddressResult[];
    status: string;
}

export class GoogleAddressResult {
    address_components: AddressComponents[];
    formatted_address: string;
    geometry: GoogleAddressGeometry;
    place_id: string;
    types: string[];
}

export class AddressComponents {
    long_name: string;
    short_name: string;
    types: string[];
}

export class GoogleAddressGeometry {
    bounds: GoogleAddressBounds;
    location: GoogleAddressLocation;
    location_type: string;
    viewport: GoogleAddressBounds;
}

export class GoogleAddressBounds {
    northeast: GoogleAddressLocation;
    southwest: GoogleAddressLocation;
}

export class GoogleAddressLocation {
    lat: number;
    lng: number;
}

export enum AddressComponentTypes {
    COUNTRY = 'country',
    STATE = 'administrative_area_level_1',
    CITY = 'locality',
    ZIP_CODE = 'postal_code',
}

export enum AddressComponentValues {
    UNITED_STATES = 'United States',
}
