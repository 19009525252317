import { FormGroup } from '@angular/forms';
import { App, Project } from '../../../../models';
import { MapFilterMenuOptionsModel } from '../../../../models/map-filter-menu-options-model';
import { Field } from '../field';

export class MapFilterSelectionModel {
    mapFiltersForm: FormGroup;
    id?: string;
    projectId: string;
    mapFilterMenuOptions: MapFilterMenuOptionsModel;
    availableFields: Field[];
    previousApp: App;
    previousProject: Project;
}
