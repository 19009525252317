import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { file, Project, ProjectNote } from '../../models';
import { App } from '../../models/app';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class ProjectNotesDataService extends HttpBaseService {

    private route = environment.api + 'projectNote/app/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    createProjectNote(app: App, project: Project, projectNoteName: string, projectNote: any): Observable<ProjectNote> {
        const url = `${this.route}${app.id}/projectId/${project.id}/projectNote/${projectNoteName}`;
        return this.httpPost(url, projectNote);
    }

    updateProjectNote(app: App, projectNote: ProjectNote, updatedProjectNote: any): Observable<ProjectNote> {
        const url = `${this.route}${app.id}/projNoteId/${projectNote.id}`;
        return this.httpPost(url, updatedProjectNote);
    }

    deleteProjectNote(app: App, projectNote: ProjectNote): Observable<null> {
        const url = `${this.route}${app.id}/projectNoteId/${projectNote.id}/delete`;
        return this.httpDelete(url);
    }

    getProjectNotesByProjectId(app: App, project: Project): Observable<ProjectNote[]> {
        const url = `${this.route}${app.id}/projectId/${project.id}`;
        return this.httpGet(url);
    }

    uploadFile(app: App, projectNote: ProjectNote, file: FormData): Observable<ProjectNote> {
        const url = `${this.route}${app.id}/projNoteId/${projectNote.id}/container/${app['fileContainers'].private.name}/files`;
        return this.httpPost(url, file);
    }

    deleteFile(app: App, projectNote: ProjectNote, fileName: string, fileId: string): Observable<ProjectNote> {
        const url = `${this.route}${app.id}/projNoteId/${projectNote.id}/fileName/${fileName}/fileId/${fileId}/container/${app['fileContainers'].private.name}/delete`;
        return this.httpDelete(url);
    }

    updateFileDescription(app: App, projectNote: ProjectNote, fileId: string, updatedFile: file): Observable<ProjectNote> {
        const url = `${this.route}${app.id}/projNoteId/${projectNote.id}/fileId/${fileId}`;
        return this.httpPost(url, updatedFile);
    }

}
