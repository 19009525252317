/* istanbul ignore file */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { App, Project, Site } from '../../models';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AssociateSitesDataService extends HttpBaseService {

    private route = environment.api + 'sites/associate/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    associateAllSites(app: App, project: Project): Observable<any> {
        const url = `${this.route}app/${app.id}/proj/${project.id}`;
        return this.httpPost(url, null);
    }

    associateSite(app: App, project: Project, site: Site, associate: boolean): Observable<any> {
        const url = `${this.route}app/${app.id}/proj/${project.id}/site/${site.id}/${associate.toString()}`;
        return this.httpPost(url, null);
    }

}
