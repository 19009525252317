/* istanbul ignore file */
export class Tiers {
    id: string;
    type: string;
    licenseTiers: LicenseTier[];
}

export class LicenseTier {
    id: number;
    name: string;
    maxSitesAllowed: number;
    pricePerSite: number;
    annualPrice: number;
}
