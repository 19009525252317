import { Injectable } from '@angular/core';

import { UUID } from 'angular2-uuid';

import { Project, RTSForm } from '../../models';
import { ControlTypes } from '../logic-services';
import { Column, Field, FieldProperty, Section, SmartsForm } from '../logic-services/models';

@Injectable({
    providedIn: 'root'
})
export class RtsFormUtility {

    private _project: Project;
    private _rtsForm: RTSForm;

    constructor() { }

    buildProject(project: Project): void {
        this._project = project;

        if (this._project.RTSForms === undefined)
            this._project.RTSForms = new SmartsForm();

        this.buildForms(this._project.JSONForms, this._project.RTSForms);
    }

    buildRTSForm(project: Project, rtsForm: RTSForm): void {
        this._project = project;
        this._rtsForm = rtsForm;

        this.buildForms(this._project.JSONForms, this._rtsForm.RTSForms);
    }

    buildForms(jsonForms: SmartsForm, rtsForms: SmartsForm): void {
        if (jsonForms === undefined)
            jsonForms = new SmartsForm();
        if (rtsForms?.Sections === undefined) {
            rtsForms.Sections = new Array<Section>();
        }

        this.buildContainers(jsonForms.Sections, rtsForms.Sections);
    }

    buildContainers(container: Section[], rtsContainer: Section[]): void {
        container?.forEach((contents: Section) => {
            let rtsContents = rtsContainer.find((targetContents: Section) => targetContents.parentId === contents.id);

            if (rtsContents?.parentId === contents.id) {
                for (const key in contents)
                    if (key !== 'id' && key !== ControlTypes.Columns && key !== ControlTypes.Sections)
                        rtsContents[key] = contents[key];
            }

            if (rtsContents === undefined) {
                rtsContents = new Section();

                for (const key in contents)
                    if (key !== 'id' && key !== ControlTypes.Columns && key !== ControlTypes.Sections)
                        rtsContents[key] = contents[key];

                rtsContents.id = UUID.UUID();
                rtsContents.parentId = contents.id;

                rtsContents.Sections = new Array<Section>();
                rtsContents.Columns = new Array<Column>();

                rtsContainer.push(rtsContents);
            }

            this.buildContents(contents.Sections, rtsContents.Sections);
        });
    }

    buildContents(contents: Section[], rtsContents: Section[]): void {
        contents?.forEach((content: Section) => {
            let rtsContent = rtsContents.find((targetContent: Section) => targetContent.parentId === content.id);

            if (rtsContent === undefined) {
                rtsContent = new Section();

                for (const key in content)
                    if (key !== 'id' && key !== ControlTypes.Columns && key !== ControlTypes.Sections)
                        rtsContent[key] = content[key];

                rtsContent.id = UUID.UUID();
                rtsContent.parentId = content.id;

                rtsContent.Sections = new Array<Section>();
                rtsContent.Columns = new Array<Column>();

                rtsContents.push(rtsContent);
                this.sectionObject(content, rtsContent);
            }

            let multipleContents = rtsContents.filter((targetContent: Section) => targetContent.parentId === content.id);

            multipleContents.forEach((multipleContent: Section) => {
                this.contents(content, multipleContent);
            });
        });
    }

    contents(section: Section, rtsSection: Section): void {
        if (rtsSection.parentId === undefined) {
            for (const key in section)
                rtsSection[key] = section[key];

            rtsSection.Columns = new Array<Column>();
            rtsSection.Sections = new Array<Section>();

            rtsSection.parentId = section.id;
            rtsSection.id = UUID.UUID();
        }

        if (rtsSection.sectionSubtitle === undefined)
            rtsSection.sectionSubtitle = 'Enter Name';

        if (rtsSection.parentId === section.id) {
            for (const key in section)
                if (key !== 'id' && key !== ControlTypes.Columns && key !== ControlTypes.Sections)
                    rtsSection[key] = section[key];
        }

        this.sectionArray(section.Sections, rtsSection.Sections);
        this.columnArray(section.Columns, rtsSection.Columns);
    }

    sectionArray(sections: Section[], rtsSections: Section[]): void {
        const sectionIds = new Array<string>();

        sections.forEach((section: Section) => {
            sectionIds.push(section.id);

            let rtsSection = rtsSections.find((target: Section) => target.parentId === section.id);

            if (!rtsSection) {
                rtsSection = new Section();
                rtsSections.push(rtsSection);
            }

            this.sectionObject(section, rtsSection);
        });

        rtsSections = rtsSections.filter((section: Section) => sectionIds.includes(section.parentId));
    }

    sectionObject(section: Section, rtsSection: Section): void {
        if (rtsSection.parentId === section.id) {
            for (const key in section)
                if (key !== 'id' && key !== ControlTypes.Columns && key !== ControlTypes.Sections)
                    rtsSection[key] = section[key];
        }

        if (rtsSection.parentId === undefined) {
            for (const key in section)
                rtsSection[key] = section[key];

            rtsSection.Columns = new Array<Column>();
            rtsSection.Sections = new Array<Section>();

            rtsSection.parentId = section.id;
            rtsSection.id = UUID.UUID();
        }

        if (section.Columns?.length)
            this.columnArray(section.Columns, rtsSection.Columns);

        if (section.Sections?.length)
            this.sectionArray(section.Sections, rtsSection.Sections);
    }

    columnArray(columns: Column[], rtsColumns: Column[]): void {
        columns.forEach((column: Column) => {
            let rtsColumn = rtsColumns.find((target: Column) => target.parentId === column.id);

            if (rtsColumn === undefined) {
                rtsColumn = new Column();
                rtsColumns.push(rtsColumn);
            }

            this.columnObject(column, rtsColumn);
        });
    }

    columnObject(column: Column, rtsColumn: Column): void {
        if (rtsColumn?.parentId === column.id) {
            for (const key in column)
                if (key !== 'id' && key !== ControlTypes.Fields)
                    rtsColumn[key] = column[key];
        }

        if (rtsColumn.parentId === undefined) {
            for (const key in column)
                rtsColumn[key] = column[key];

            rtsColumn.Fields = new Array<Field>();

            rtsColumn.parentId = column.id;
            rtsColumn.id = UUID.UUID();
        }

        this.fieldArray(column.Fields, rtsColumn.Fields);
    }

    fieldArray(fields: Field[], rtsFields: Field[]): void {
        fields.forEach((field: Field) => {
            let rtsField = rtsFields.find((target: Field) => target.parentId === field.id);

            if (!rtsField) {
                rtsField = new Field();
                rtsFields.push(rtsField);
            }

            this.fieldObject(field, rtsField);
        });
    }

    fieldObject(field: Field, rtsField: Field): void {
        if (rtsField.parentId === field.id) {
            for (const key in field)
                if (key !== 'id')
                    rtsField[key] = field[key];
        }

        if (rtsField.parentId === undefined) {
            for (const key in field)
                rtsField[key] = field[key];

            rtsField.parentId = field.id;
            rtsField.id = UUID.UUID();
        }

        rtsField.FieldType = new FieldProperty();
        this.fieldProperty(field.FieldType, rtsField.FieldType);
    }

    fieldProperty(fieldProperty: FieldProperty, rtsFieldProperty: FieldProperty): void {
        for (const key in fieldProperty)
            rtsFieldProperty[key] = fieldProperty[key];
    }
}
