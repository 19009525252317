import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KHThemeService } from 'src/app/services/tools/theme.service';

@Component({
    selector: 'app-button',
    templateUrl: './app-button.component.html',
    styleUrls: ['./app-button.component.scss']
})
export class AppButtonComponent implements OnInit {
    @Output() onClick: EventEmitter<any> = new EventEmitter();

    @Input() disabled: boolean = false;
    //To allow the button to appear disabled, but still allow the click event to happen in order to show error messages
    @Input() visuallyDisabled: boolean = this.disabled;
    @Input() width: string = 'auto';

    constructor(
        public themeService: KHThemeService
    ) { }

    ngOnInit(): void {

    }

    click(event: any): void {
        if (this.disabled) {
            return;
        }
        this.onClick.emit(event);
    }
}
