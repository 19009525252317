<div class="card position-absolute top-0 end-0">
    <close-dialog-button (click)="closeDialog()"></close-dialog-button>
    <div class="card-body overflow-y-auto">
        <ng-template [ngIf]="this.model.responseLoading"
            [ngIfElse]="showForm">
            <mat-spinner class="mt-5"
                [diameter]="150"
                style="margin: 0 auto"></mat-spinner>
        </ng-template>
        <ng-template #showForm>
            <div *ngIf="this.formStep === 'inProgress'"
                class="viewForm">
                <div class="mb-2 d-flex justify-content-start">
                    <h1 class="ms-1 mb-3">Send feedback</h1>
                </div>
                <p class="indicateTip mb-4">*Indicates required fields</p>
                <div class="feedBackFormWrapper">
                    <form [formGroup]="feedBackForm">
                        <div class="row">
                            <div class="col">
                                <p class="fieldHeader ms-1">Describe your issue or suggestion*</p>
                                <textarea placeholder="Tell us how we can improve our product"
                                    formControlName="description"
                                    class="feedBackTextBox w-100"
                                    required></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="feedBackUploader container pt-4 mb-4">
                                <div class="fieldHeader mb-2">Upload image</div>
                                <div class="fileSelectorWrapper">
                                    <kendo-fileselect class="fileUploader"
                                        [multiple]="false"
                                        (select)="selectFile($event)"
                                        (remove)="removeFile($event)"
                                        [showFileList]="true">
                                        <kendo-fileselect-messages select="Browse"
                                            dropFilesHere="Drop file here">
                                        </kendo-fileselect-messages>
                                    </kendo-fileselect>
                                </div>
                            </div>
                        </div>
                        <p class="fieldHeader">Preferred contact method*</p>
                        <select class="contactDropdown mb-4"
                            [(ngModel)]="contactMethod"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="setContactMethod($event)">
                            <option class="text-secondary"
                                value=""
                                disabled
                                selected>
                                Select preferred contact method...
                            </option>
                            <option *ngFor="let option of contactOptions"
                                value="{{ option.name }}">
                                {{ option.name }}
                            </option>
                        </select>

                        <div *ngIf="this.contactMethod === 'Phone'"
                            class="row">
                            <p class="fieldHeader">Phone number*</p>
                            <div class="col">
                                <input formControlName="phone"
                                    type="tel"
                                    value="this.feedBackForm.value.phone"
                                    placeholder="xxx-xxx-xxxx"
                                    class="phoneInput w-100" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div *ngIf="this.formStep === 'completed'"
                class="viewConfirmation">
                <div class="mb-4 d-flex">
                    <close-dialog-button (click)="closeDialog()"></close-dialog-button>
                </div>
                <div class="feedBackImage mb-5">
                    <img src="/../../assets/illustrations_send_feedback.svg"
                        alt="" />
                </div>
                <ng-template [ngIf]="this.confirmationNumber"
                    [ngIfElse]="showError">
                    <div class="successConfirm">
                        <p class="thankYou fieldHeader mb-3">Thank you for your feedback!</p>
                        <p>
                            We use feedback like yours to help fix issues and improve the SMARTS user experience.
                            Someone from our team will be in touch using the contact method you specified.
                        </p>
                        <p>
                            Please feel free to reference confirmation number
                            <span class="confirmColor">{{ this.confirmationNumber }}</span>, if needed.
                        </p>
                        <app-button class="feedBackSubmit"
                            (onClick)="closeDialog()">
                            <div class="px-3">Close</div>
                        </app-button>
                    </div>
                </ng-template>
                <ng-template #showError>
                    <div class="errorConfirm">
                        <p class="text-center">
                            There has been an error processing your feedback. Please allow some time before trying
                            again.
                        </p>
                    </div>
                </ng-template>
            </div>
        </ng-template>
        <p *ngIf="this.formStep === 'inProgress'"
            class="disclaimer">
            Some account and system information may be sent to Kimley-Horn Technology Solutions. We will use it to fix
            problems and improve our services, subject to our
            <a target="_blank"
                href="https://www.kimley-horn.com/khts-privacy-policy">Privacy-Policy</a>
            and
            <a target="_blank"
                href="https://www.kimley-horn.com/khts-terms-of-use">Terms of Use</a>. We may contact you for more
            information or updates using your preferred contact method.
        </p>
    </div>
    <div *ngIf="this.formStep === 'inProgress'"
        class="card-footer">
        <app-button class="feedBackSubmit"
            (onClick)="submitForm()">
            <div class="buttonContent">
                <span class="me-1">Send</span>
                <mat-icon class=""
                    [ngStyle]="{ color: 'white' }">send</mat-icon>
            </div>
        </app-button>
    </div>
</div>
